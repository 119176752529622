import Plotly from 'plotly.js';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/de';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotParams } from 'react-plotly.js';
import { useTheme } from '@mui/material';
import '@fontsource/source-sans-pro';

Plotly.register(localeDictionary);
// @ts-ignore
Plotly.setPlotConfig({
  locale: 'de',
  scrollZoom: false,
  displaylogo: false,
  modeBarButtonsToRemove: [
    'autoScale2d',
    'lasso2d',
    'zoom2d',
    'zoomIn2d',
    'zoomOut2d',
    'pan2d',
    'select2d',
    'resetScale2d',
  ],
} as Partial<Plotly.Config>);

const PlotlyPlot = createPlotlyComponent(Plotly);

export const Plot = ({ ...props }: PlotParams) => {
  const theme = useTheme();

  return (
    <PlotlyPlot
      {...props}
      layout={{
        paper_bgcolor: theme.palette.tertiary.light,
        plot_bgcolor: theme.palette.tertiary.light,
        // Source Sans 3 (static or variable) fails on snapshot download, so we use Source Sans Pro ¯\_(ツ)_/¯
        font: { family: "'Source Sans Pro', sans-serif", size: 14 },
        autosize: true,
        ...props.layout,
      }}
    />
  );
};
