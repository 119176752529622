/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParkingFeeType {
    NO = 'NO',
    YES = 'YES',
    PARTLY = 'PARTLY',
    PAY_AS_YOU_USE = 'PAY_AS_YOU_USE',
    FLAT_RATE = 'FLAT_RATE',
}
