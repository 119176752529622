import { Typography } from '@mui/material';

import { ContentLayoutPage } from '@/components/layouts/ContentLayoutPage';

export const HelpPage = () => (
  <ContentLayoutPage>
    <Typography variant="h1">Hilfe</Typography>
    <Typography>
      <br />
      Hier folgen in kürze Erklärungen zu diversen Funktionen
    </Typography>
  </ContentLayoutPage>
);
