import { forwardRef } from 'react';

import { FormSection } from '@/components/form-elements/FormSection';
import { Location, Network, NetworkCategory } from '@/services/api';
import { NetworkAutocomplete } from '@/components/form-elements/NetworkAutocomplete';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';

export type FormDataNetworksForm = {
  networks?: Record<NetworkCategory, Omit<Network, 'locations'>>;
};

type NetworksFormProps = {
  isLoading: boolean;
  location?: Location;
};

export const NetworksForm = forwardRef<HTMLElement, NetworksFormProps>(
  ({ location, isLoading }: NetworksFormProps, ref) => {
    const isFieldDisabled = useIsFieldDisabled(location);

    return (
      <FormSection ref={ref} title="Kategorien">
        {Object.values(NetworkCategory).map((networkCategory) => (
          <NetworkAutocomplete
            key={networkCategory}
            isLoading={isLoading}
            disabled={isFieldDisabled('networkIds')}
            category={networkCategory}
          />
        ))}
      </FormSection>
    );
  },
);
