import { Box, css, FormLabel, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SurveyImportance } from '@/services/api';

const StyledLabelWrapper = styled(Box)<{ items: number }>(
  ({ theme, items }) => css`
    width: ${theme.spacing(items * 5.625)};
    display: flex;
    justify-content: space-around;
    @media print {
      margin-right: 0;
    }
  `,
);

const StyledLabel = styled(FormLabel)(
  ({ theme }) => css`
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    vertical-align: middle;
    color: ${theme.palette.text.primary};
  `,
) as typeof FormLabel;

export const SurveyRadioButtonLabels = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      className="form-section-item"
      justifyContent="flex-end"
      sx={{ marginTop: -7.5, position: 'sticky', top: 0, zIndex: 100 }}
    >
      <Grid item xs={4} sx={{ backgroundColor: 'white', paddingTop: 1, paddingBottom: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledLabelWrapper items={4}>
            {Object.values(SurveyImportance)
              .slice(0, -1)
              .map((value) => (
                <StyledLabel key={value} component="legend">
                  {t(`SurveyImportance.${value}`)}
                </StyledLabel>
              ))}
          </StyledLabelWrapper>
          <StyledLabelWrapper items={1} sx={{ marginLeft: 4 }}>
            <StyledLabel component="legend">{t(`SurveyImportance.${SurveyImportance.NOT_SPECIFIED}`)}</StyledLabel>
          </StyledLabelWrapper>
        </Box>
      </Grid>
    </Grid>
  );
};
