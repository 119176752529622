import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Action, LocationsService, UpdateLocationDto, Location } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { DataTransformer } from '@/services/DataTransformer';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useAutoSave } from '@/hooks/useAutoSave';
import { FormDataLocationMonitoring, MonitoringConfigForm } from '@/monitoring/configuration/MonitoringConfigForm';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { LastSaved } from '@/components/form-elements/LastSaved';

const navigationItems = ['Einstellungen'];

export const MonitoringConfigurationPage = () => {
  const { locationId } = useParams();
  const ability = useAppAbility();

  const queryClient = useQueryClient();
  const { data: location } = useQuery(
    QueryKeys.locations.id(locationId as string),
    () => LocationsService.findOne({ id: locationId as string }),
    { enabled: !!locationId },
  );

  const methods = useForm<FormDataLocationMonitoring>();
  const [lastSaved, setLastSaved] = useState<Dayjs>();

  useInitialFormValues<FormDataLocationMonitoring>({
    entity: location,
    useFormReturn: methods,
    fields: [
      'monitoringStyle',
      'monitoringTitle',
      'monitoringSubTitle',
      'monitoringInfoText',
      'monitoringCredits',
      'monitoringCreditsLink',
      'monitoringCommuteTitle',
      'monitoringCommuteSubTitle',
      'monitoringDistanceActive',
      'monitoringDistanceTitle',
      'monitoringDistanceSubTitle',
      'monitoringRatingActive',
      'monitoringRatingTitle',
      'monitoringRatingSubTitle',
      'monitoringCommuneActive',
      'monitoringCommuneTitle',
      'monitoringCommuneSubTitle',
      'monitoringActive',
      'monitoringCommuteTypes',
      'monitoringScaleMax',
    ],
  });

  const { mutate } = useMutation(
    (formData: UpdateLocationDto) => LocationsService.update({ id: locationId as string, requestBody: formData }),
    {
      onMutate: () => setLastSaved(undefined),
      onSuccess: async () => {
        setLastSaved(dayjs());
        await queryClient.invalidateQueries(QueryKeys.locations.id(locationId as string));
      },
    },
  );

  const handleSubmit = methods.handleSubmit(
    (formData) => {
      const transformedFormData: UpdateLocationDto = DataTransformer.toApi(
        {
          ...formData,
        },
        (field) => ability.can(Action.UPDATE, location as Location, field),
      );

      mutate(transformedFormData);
    },
    () => setLastSaved(undefined),
  );
  useAutoSave(handleSubmit, methods.watch);

  const disabled = !location || ability.cannot(Action.UPDATE, location);

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems}
      buttonLabel={disabled ? undefined : 'Speichern'}
      actionArea={<LastSaved lastSaved={lastSaved} />}
    >
      {(tab: number) =>
        tab === 0 && (
          <FormProvider {...methods}>
            <form id="main-form" onSubmit={handleSubmit}>
              <MonitoringConfigForm location={location} isLoading={!location} />
            </form>
          </FormProvider>
        )
      }
    </SidebarLayout>
  );
};
