/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParkingPermitAllocationType {
    HIERARCHY = 'HIERARCHY',
    NONE = 'NONE',
    DISTANCE = 'DISTANCE',
    SPECIAL_NEED = 'SPECIAL_NEED',
    OTHER = 'OTHER',
}
