import { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Fade, Typography } from '@mui/material';

import { useThrottle } from '@/hooks/useThrottle';

type LastSavedProps = { lastSaved?: Dayjs };

export const LastSaved = ({ lastSaved }: LastSavedProps) => {
  const [display, setDisplay] = useState<string>();
  const throttledLastSaved = useThrottle(lastSaved, 750);

  useEffect(() => {
    if (lastSaved) {
      setDisplay(lastSaved.fromNow());
    }
  }, [lastSaved]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastSaved) {
        setDisplay(lastSaved.fromNow());
      }
    }, 60000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Fade in={!!throttledLastSaved} timeout={150}>
      <Typography align="right" marginTop={1} fontSize="0.8rem" maxWidth={120}>
        Gespeichert {display}
      </Typography>
    </Fade>
  );
};
