import { ReactNode } from 'react';
import { css, styled } from '@mui/material';

const StyledLink = styled('a')(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  `,
);

type NavigationLinkProps = { children: ReactNode; href: string };

export const NavigationExternalLink = ({ children, href }: NavigationLinkProps) => (
  <StyledLink href={href} target="_blank" rel="noopener">
    {children}
  </StyledLink>
);
