import { Box, FormControlLabel, Input, Radio } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RadioButtonsGroup } from '@/components/form-elements/RadioButtonsGroup';

type RadioButtonsEnumProps<T extends string, U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  otherName?: FieldPath<U>;
  i18nKey: string;
  options: T[];
  isLoading: boolean;
  disabled?: boolean;
};

export const RadioButtonsEnum = <T extends string, U extends FieldValues>({
  label,
  name,
  otherName,
  i18nKey,
  options,
  isLoading,
  disabled,
}: RadioButtonsEnumProps<T, U>) => {
  const { t } = useTranslation();
  const { register } = useFormContext<U>();

  return (
    <RadioButtonsGroup label={label} name={name} isLoading={isLoading}>
      {options.map((value) => (
        <FormControlLabel
          sx={{ ...(otherName && value === 'OTHER' && { flexGrow: 1, marginRight: 0 }) }}
          disableTypography
          key={value}
          value={value as T}
          control={<Radio />}
          disabled={disabled}
          label={
            otherName && value === 'OTHER' ? (
              <Box sx={{ flexGrow: 1, display: 'inline-flex', alignItems: 'center' }}>
                {`${t(`${i18nKey}.${value}`)}: `}
                <Box sx={{ display: 'inline-flex', flexGrow: 1 }}>
                  <Input sx={{ marginLeft: 1, width: '100%' }} disabled={disabled} {...register(otherName)} />
                </Box>
              </Box>
            ) : (
              <>{t(`${i18nKey}.${value}`)}</>
            )
          }
        />
      ))}
    </RadioButtonsGroup>
  );
};
