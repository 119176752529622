/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PublicTransportFundingType {
    JOB_TICKET = 'JOB_TICKET',
    SUBSIDY_50 = 'SUBSIDY_50',
    SUBSIDY_20_TO_50 = 'SUBSIDY_20_TO_50',
    OTHER = 'OTHER',
}
