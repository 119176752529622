import { ReactNode, useState } from 'react';
import { Button } from '@mui/material';

import { ConfirmDialog } from '@/components/form-elements/ConfirmDialog';

type DeleteDialogProps = {
  onDelete: () => void;
  entityLabel: string;
  entityName: string;
  children: ReactNode;
  can: boolean;
};

export const DeleteDialog = ({ onDelete, entityLabel, entityName, children, can }: DeleteDialogProps) => {
  const [open, setOpen] = useState(false);

  return can ? (
    <>
      <Button onClick={() => setOpen(true)}>{entityLabel} löschen</Button>
      <ConfirmDialog
        title={`${entityLabel} "${entityName}" löschen?`}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
      >
        {children}
      </ConfirmDialog>
    </>
  ) : null;
};
