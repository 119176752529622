import { css, LinearProgress, styled } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';

import { useThrottle } from '@/hooks/useThrottle';

const StyledLinearProgress = styled(LinearProgress)(
  css`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  `,
);

export const Loading = () => {
  const isFetching = useIsFetching();
  const throttledIsFetching = useThrottle(isFetching, 750);

  return throttledIsFetching !== 0 ? <StyledLinearProgress /> : null;
};
