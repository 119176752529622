import { useFormContext } from 'react-hook-form';

import { ConsultingStatusChip } from '@/components/form-elements/ConsultingStatusChip';
import { Organization, UpdateOrganizationDto } from '@/services/api';

export type FormPartOrganizationStatus = Pick<UpdateOrganizationDto, 'status'>;

type ConsultingStatusRegisterProps = {
  organization?: Organization;
};

export const ConsultingStatusRegister = ({ organization }: ConsultingStatusRegisterProps) => {
  const { register } = useFormContext<FormPartOrganizationStatus>();

  return <ConsultingStatusChip organization={organization} register={register} />;
};
