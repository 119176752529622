import { ReactNode } from 'react';
import { Box, css, styled } from '@mui/material';

import { transientOptions } from '@/utils/transientOptions';

const StyledBox = styled(
  Box,
  transientOptions,
)<{ $navigations: number }>(
  ({ theme, $navigations }) => css`
    min-height: calc(100vh - ${theme.spacing(7 * (1 + $navigations))});
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(8)};
  `,
);

type FullHeightLayoutProps = { className?: string; navigations?: number; children: ReactNode };

export const FullHeightLayout = ({ className, navigations = 2, children }: FullHeightLayoutProps) => (
  <StyledBox className={className} $navigations={navigations}>
    {children}
  </StyledBox>
);
