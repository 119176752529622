import React from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import '@/setup/sentry';
import '@/setup/api';
import '@/setup/i18n';
import '@/setup/dayjs';
import { App } from '@/App';
import { theme } from '@/setup/theme';
import { queryClient } from '@/setup/query-client';
import { AbilityProvider } from '@/components/casl/AbilityProvider';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AbilityProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <CssBaseline />
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </AbilityProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
