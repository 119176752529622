import { useTranslation } from 'react-i18next';

import { CommuteType, MinimumMonitoringDto } from '@/services/api';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { Plot } from '@/monitoring/plots/Plot';

type ModalSplitProgressPlotProps = {
  data: MinimumMonitoringDto[];
  showSubtitle: boolean;
};

function groupByDate(list: MinimumMonitoringDto[]) {
  const result: Array<{ date: string; entries: MinimumMonitoringDto[] }> = [];
  list.forEach((obj) => {
    if (result.find((entry) => entry.date === obj.createdAt)) {
      result.find((entry) => entry.date === obj.createdAt)?.entries.push(obj);
    } else {
      result.push({ date: obj.createdAt, entries: [obj] });
    }
  });

  return result;
}

export const ModalSplitProgressPlot = ({ data, showSubtitle }: ModalSplitProgressPlotProps) => {
  const { t } = useTranslation();
  const filterdData = data.map((obj) => ({ ...obj, createdAt: obj.createdAt.split('T')[0] }));

  const plotData = groupByDate(filterdData);

  return (
    <Plot
      data={Object.values(CommuteType).map((obj) => ({
        name: t(`CommuteType.${obj}`) as string,
        x: plotData.map((day) => day.date),
        y: plotData.map((day) => day.entries.filter((rec) => rec.commuteType === obj).length),
        type: 'bar',
        marker: { color: CommuteUtils[obj].color },
      }))}
      layout={{
        barmode: 'stack',
        title: `Entwicklung der Verkehrsmittelanteile${
          showSubtitle ? '<br><sub>Anzahl der Fahrten pro Verkehrsmittel im zeitlichen Verlauf</sub>' : ''
        }`,
        yaxis: { fixedrange: true },
        xaxis: {
          tickformat: '%_d\n%B',
          tickmode: plotData.length > 5 ? 'auto' : 'array',
          tickvals: plotData.map((day) => day.date),
        },
      }}
      config={{
        modeBarButtonsToRemove: ['lasso2d', 'zoom2d', 'pan2d', 'select2d', 'resetScale2d'],
      }}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
