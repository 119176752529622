import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

type PrintFormSectionProps = { title: string; formSection: ReactNode };

export const PrintFormSection = ({ title, formSection }: PrintFormSectionProps) => {
  const methods = useForm<FormData>();

  return (
    <FormProvider {...methods}>
      <Typography variant="h2" mt={2} mb={2}>
        {title}
      </Typography>
      {formSection}
    </FormProvider>
  );
};
