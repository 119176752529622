import { Typography } from '@mui/material';

import { ContentLayoutPage } from '@/components/layouts/ContentLayoutPage';

export const ImprintPage = () => (
  <ContentLayoutPage>
    <Typography variant="h1">Impressum</Typography>
    <Typography variant="h2">Angaben gemäß § 5 TMG</Typography>
    <Typography>
      <br />
      <b>Herausgeberin:</b>
      <br /> ivm GmbH (Integriertes Verkehrs- und Mobilitätsmanagement Region Frankfurt RheinMain)
      <br /> Bessie-Coleman-Straße 7<br /> 60549 Frankfurt a. M.
      <br /> Aufsichtsratsvorsitzender: Landrat Ulrich Krebs
      <br /> Geschäftsführung: Dipl.-Ing. Heike Mühlhans
      <br /> Handelsregister: HRB 75042 Amtsgericht Frankfurt a.M.
      <br /> Steuernummer 045 236 48274
      <br />
      <br />
      <b>Kontakt:</b>
      <br /> Frau Heike Mühlhans
      <br /> ivm GmbH (www.ivm-rheinmain.de/)
      <br /> Bessie-Coleman-Straße 7<br /> 60549 Frankfurt a. M.
      <br /> Telefon: +49 (0) 69 / 660759 0<br /> Telefax: +49 (0) 69 / 660759 90
      <br /> E-Mail: info@ivm-rheinmain.de
      <br />
      <br />
      <b>Datenschutzbeauftragter:</b>
      <br /> Datenschutzbeauftragter der ivm GmbH
      <br /> Bessie-Coleman-Straße 7<br /> 60549 Frankfurt am Main
      <br /> E-Mail: datenschutzbeauftragter@ivm-rheinmain.de
      <br />
      <br />
      <b>Technische Umsetzung:</b>
      <br /> Trafficon – Trafficon Consultants GmbH
      <br /> Strubergasse 26
      <br /> A-5020 Salzburg
      <br /> E-Mail: info@trafficon.eu
      <br />
      <br />
    </Typography>
    <Typography variant="h2">Haftungsausschluss (Disclaimer)</Typography>
    <Typography>
      <br />
      <b>Haftung für Inhalte</b>
      <br />
      Die Inhalte unseres Internetportals wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
      Aktualität übernehmen wir jedoch keine Gewähr. Für in das Portal eingebundene Leistungen Dritter (z. B.
      Kartografie) liegt die Verantwortung ausschließlich bei den Dritten.
      <br /> Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
      oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
      allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
      Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
      wir diese Inhalte umgehend entfernen.
      <br />
      <b>Haftung für Links</b>
      <br /> Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
      Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
      ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
      Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
      Verlinkung nicht erkennbar.
      <br /> Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
      entfernen.
      <br />
      <b>Urheberrecht</b>
      <br /> Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Marken- und Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
      Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
      und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      <br /> Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
      beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
      <br /> Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
      Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      <br />
      <br />
    </Typography>

    <Typography>
      <b>Quellenangabe</b>
      <br />
      Dieses Impressum wurde erstellt mit dem kostenfreien Impressumgenerator unter www.e-recht24.de nebst Ergänzungen
      und Änderungen durch den Datenschutzbeauftragten der ivm.
    </Typography>
  </ContentLayoutPage>
);
