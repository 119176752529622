/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DistanceType {
    LESS_THAN_250 = 'LESS_THAN_250',
    BETWEEN_250_AND_500 = 'BETWEEN_250_AND_500',
    BETWEEN_501_AND_750 = 'BETWEEN_501_AND_750',
    BETWEEN_751_AND_1000 = 'BETWEEN_751_AND_1000',
    BETWEEN_1001_AND_1500 = 'BETWEEN_1001_AND_1500',
    MORE_THAN_1500 = 'MORE_THAN_1500',
}
