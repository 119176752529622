import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { IndicatorProcess, IndicatorProcessService, Organization, OrganizationsService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';

type FormData = { organization: Pick<Organization, 'id' | 'name'>; name: string };

export const CreateIndicatorProcessPage = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const { mutate } = useMutation(
    (formData: FormData) =>
      IndicatorProcessService.create({
        requestBody: { organizationId: formData.organization?.id as string, name: formData.name },
      }),
    {
      onSuccess: (indicatorProcess: IndicatorProcess) => navigate(`/beratung/wsa-berechnung/${indicatorProcess.id}`),
    },
  );

  const filters = { includeLocations: false };
  const { data = [] } = useQuery(
    QueryKeys.organizations.allFilters(filters),
    () => OrganizationsService.findAll(filters),
    { keepPreviousData: true },
  );

  return (
    <SidebarLayout>
      <form id="main-form" onSubmit={handleSubmit((submitData) => mutate(submitData))}>
        <FormSection title="WSA Berechnung erstellen">
          <TextFieldLoading isLoading={!data}>
            <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
          </TextFieldLoading>
          <TextFieldLoading isLoading={!data}>
            <Controller
              name="organization"
              control={control}
              defaultValue={null as unknown as undefined}
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                <Autocomplete
                  {...rest}
                  disableClearable
                  options={data}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} inputRef={ref} label="Organisation" error={!!error} />
                  )}
                />
              )}
            />
          </TextFieldLoading>
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
};
