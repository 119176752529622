/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdminLevelType {
    GOVERNMENT_DISTRICT = 'GOVERNMENT_DISTRICT',
    COUNTY = 'COUNTY',
    FREE_CITY = 'FREE_CITY',
    MUNICIPALITY = 'MUNICIPALITY',
    CITY = 'CITY',
    MUNICIPALITY_FREE_AREA = 'MUNICIPALITY_FREE_AREA',
}
