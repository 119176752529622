import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { OrganizationsService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';

export const useQueryOrganizationDefaultFilters = {
  includeLocations: true,
  includeOrganizationUsers: true,
  includeIndicatorProcesses: true,
};

export const useQueryOrganization = () => {
  const { organizationId } = useParams();

  const filter = useQueryOrganizationDefaultFilters;

  return useQuery(
    QueryKeys.organizations.idIncludes(organizationId as string, filter),
    () => OrganizationsService.findOne({ id: organizationId as string, ...filter }),
    { enabled: !!organizationId },
  );
};
