import { useTranslation } from 'react-i18next';
import { Typography, Box, Button, styled, css, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { GeneralInformationForm } from '@/location/location-profile/GeneralInformationForm';
import { GoalsAndIncentivesForm } from '@/location/location-profile/GoalsAndIncentivesForm';
import { LocationReflectionForm } from '@/location/location-profile/LocationReflectionForm';
import { ParkingBicycles } from '@/location/location-profile/ParkingBicycles';
import { ParkingCars } from '@/location/location-profile/ParkingCars';
import { BusinessTripsForm } from '@/location/location-profile/BusinessTripsForm';
import { LocationsService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { PrintFormSection } from '@/location/PrintFormSection';
import { navigationItems } from '@/location/LocationProfilePage';

const StyledTypography = styled(Typography)(
  css`
    @media print {
      position: absolute;
      right: 0;
    }
  `,
);

export const LocationProfilePrint = () => {
  const { t } = useTranslation();
  const current = new Date();
  const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
  const { locationId } = useParams();

  const { data: location } = useQuery(
    QueryKeys.locations.id(locationId as string),
    () => LocationsService.findOne({ id: locationId as string }),
    { enabled: !!locationId },
  );

  const { data: organization } = useQueryOrganization();

  const coreData = [
    { label: 'Organisation', value: organization?.name },
    { label: 'Bezeichnung', value: location?.name },
    { label: 'Anwendungsfall-Nummerierung', value: location?.consultingCaseLocationId },
    { label: 'Anzahl der Beschäftigten', value: location?.size },
    { label: 'Adresse', value: location?.address },
    { label: 'Räumliche Ebene', value: location?.adminLevel?.name },
  ];

  const formSections = [
    <GeneralInformationForm print key="GeneralInformationForm" />,

    <BusinessTripsForm print key="BusinessTripsForm" />,

    <ParkingCars print key="ParkingCars" />,

    <ParkingBicycles print key="ParkingBicycles" />,

    <GoalsAndIncentivesForm print key="GoalsAndIncentivesForm" />,

    <LocationReflectionForm print key="LocationReflectionForm" />,
  ];

  return (
    <Box sx={{ maxWidth: '800px', paddingLeft: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography mr={2}>Mobilitätsplattform - Steckbrief</Typography>
            <StyledTypography>{date}</StyledTypography>
          </Box>
        </Box>
        <Button variant="contained" size="large" onClick={() => window.print()} sx={{ displayPrint: 'none' }}>
          Drucken
        </Button>
      </Box>
      <Grid container spacing={2} alignItems="center">
        {coreData.map((core) => (
          <>
            <Grid item xs={4}>
              {core.label}:
            </Grid>
            <Grid item xs={8}>
              {core.value}
            </Grid>
          </>
        ))}
        {location?.networks?.map((network) => (
          <>
            <Grid item xs={4}>
              {t(`NetworkCategory.${network.category}`)}:
            </Grid>
            <Grid item xs={8}>
              {network.name}
            </Grid>
          </>
        ))}
      </Grid>

      <Box sx={{ pointerEvents: 'none' }}>
        {formSections.map((formSection, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PrintFormSection key={index} title={navigationItems[index]} formSection={formSection} />
        ))}
      </Box>
    </Box>
  );
};
