import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { unpackRules } from '@casl/ability/extra';

import { LoginForm } from '@/authentication/login/LoginForm';
import { ApiError, AuthenticationService, CaslService, LoginUserDto, User } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useDefaultRoute } from '@/hooks/useDefaultRoute';

export const Login = () => {
  const user = useGlobalStore((state) => state.user);
  const { setUser } = useGlobalStore((state) => state.actions);
  const ability = useAppAbility();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: { pathname: string } })?.from?.pathname || '/';
  const { defaultRoute } = useDefaultRoute();

  const { mutate, error } = useMutation<User, ApiError, LoginUserDto>(
    (loginUserDto: LoginUserDto) => AuthenticationService.login({ requestBody: loginUserDto }),
    {
      onSuccess: async (newUser) => {
        setUser(newUser);
        ability.update(unpackRules((await CaslService.getRules()).rules as any));
        navigate(from, { replace: true });
      },
    },
  );

  useEffect(() => {
    if (user) {
      let to;

      if (from === '/') {
        to = defaultRoute;
      } else {
        to = from;
      }

      navigate(to, { replace: true });
    }
  }, [ability, defaultRoute, from, navigate, user]);

  return (
    <LoginForm
      error={error && (error?.status === 401 ? 'Bitte überprüfen Sie Ihre Eingabe' : 'Es ist ein Fehler aufgetreten.')}
      onSubmit={async (requestBody) => mutate(requestBody)}
    />
  );
};
