import i18n from 'i18next';

import { CommuteType, MinimumMonitoringDto } from '@/services/api';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';

const activeModes = [CommuteType.BIKE, CommuteType.WALK, CommuteType.BIKE_AND_RIDE, CommuteType.SCOOTER];

export type MonitoringAnalysisData = {
  commuteType: CommuteType;
  activeMobility: boolean;
  movementShare: number;
  count: number;
  distance: number;
  label: string;
  labelShort: string;
  carbonDioxide: number;
  reducedCarbonDioxide: number;
  color: string;
  order: number;
  icon: string;
  data: MinimumMonitoringDto[];
};

export const processMonitoringData = (
  monitoringData: MinimumMonitoringDto[],
  includeWayBack: boolean,
): MonitoringAnalysisData[] => {
  const multiplier = includeWayBack ? 2 : 1;

  const result = Object.values(CommuteType).map((commuteType) => {
    const data = monitoringData.filter((obj) => obj.commuteType === commuteType);
    const count = data.length;
    const distance = data.reduce((total, current) => total + (current.distance === 0 ? 0.5 : current.distance || 0), 0);

    return {
      commuteType,
      activeMobility: activeModes.includes(commuteType),
      movementShare: (CommuteUtils[commuteType].getMovementAmount(distance, count) * multiplier) / count || 0,
      count: count * multiplier,
      distance: distance * multiplier,
      label: i18n.t(`CommuteType.${commuteType}`),
      labelShort: i18n.t(`CommuteTypeShort.${commuteType}`),
      carbonDioxide: CommuteUtils[commuteType].getCarbonDioxide(distance, count) * multiplier,
      reducedCarbonDioxide: CommuteUtils[commuteType].getReducedCarbonDioxide(data) * multiplier,
      color: CommuteUtils[commuteType].color,
      order: CommuteUtils[commuteType].order,
      icon: CommuteUtils[commuteType].icon,
      data,
    };
  });

  const car = result.find((obj) => obj.commuteType === CommuteType.CAR_MOTORCYCLE) as MonitoringAnalysisData;
  const ecar = result.find((obj) => obj.commuteType === CommuteType.CAR_ELECTRIC) as MonitoringAnalysisData;
  const carpool = result.find((obj) => obj.commuteType === CommuteType.CAR_POOL) as MonitoringAnalysisData;
  carpool.carbonDioxide =
    carpool.data.length > 0
      ? (car.carbonDioxide + ecar.carbonDioxide - (car.carbonDioxide + ecar.carbonDioxide) / 1.2) / carpool.data.length
      : 0;
  if (car.carbonDioxide !== 0) car.carbonDioxide -= carpool.carbonDioxide * (car.count / (car.count + ecar.count));
  if (ecar.carbonDioxide !== 0) ecar.carbonDioxide -= carpool.carbonDioxide * (ecar.count / (car.count + ecar.count));

  result.sort((a, b) => a.order - b.order);

  return result;
};
