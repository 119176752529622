import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { IndicatorRoutingStatusForm } from '@/indicator-process/configuration/IndicatorRoutingStatusForm';
import { IndicatorRoutingScheduleForm } from '@/indicator-process/configuration/IndicatorRoutingScheduleForm';
import { useCurrentTab } from '@/hooks/useCurrentTab';
import { Action, Subject } from '@/services/api';
import { useAppAbility } from '@/hooks/useAppAbility';
import { LastSaved } from '@/components/form-elements/LastSaved';

const navigationItems = ['Status', 'Zeitplan'];

export const IndicatorRoutingConfigurationPage = () => {
  const { currentTab } = useCurrentTab();
  const ability = useAppAbility();

  const [lastSaved, setLastSaved] = useState<Dayjs>();

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems}
      buttonLabel={
        currentTab === 1 && ability.can(Action.UPDATE, Subject.INDICATOR_ROUTING_CONFIGURATION)
          ? 'Speichern'
          : undefined
      }
      actionArea={currentTab === 1 && <LastSaved lastSaved={lastSaved} />}
    >
      {(tab: number) => (
        <>
          {tab === 0 && <IndicatorRoutingStatusForm />}
          {tab === 1 && (
            <IndicatorRoutingScheduleForm
              onMutate={() => setLastSaved(undefined)}
              onSuccess={() => setLastSaved(dayjs())}
            />
          )}
        </>
      )}
    </SidebarLayout>
  );
};
