import { Fragment } from 'react';

type AddressSplitProps = { address?: string | null };

export const AddressSplit = ({ address }: AddressSplitProps) => {
  const parts = (address || '').split(',');

  return (
    <>
      {parts.map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${part}-${index}`}>
          {part.trim()}
          {index < parts.length - 1 ? ', ' : ''}
          {index < parts.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};
