/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInvitationDto } from '../models/AcceptInvitationDto';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitationsService {

    /**
     * @returns User
     * @throws ApiError
     */
    public static accept({
        userId,
        invitationToken,
        requestBody,
    }: {
        userId: string,
        invitationToken: string,
        requestBody: AcceptInvitationDto,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/invitations/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'invitationToken': invitationToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
