import { css, styled, Tab, Tabs } from '@mui/material';

const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    position: relative;
    border-right: 2px;
    border-color: ${theme.palette.divider};
    box-sizing: content-box;
    margin-bottom: ${theme.spacing(4)};

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 2px;
      background-color: ${theme.palette.divider};
    }

    .MuiTab-root {
      align-items: flex-end;
      text-align: right;
    }
  `,
);

export type VerticalNavigationProps = {
  active: number;
  items: string[];
  onChange: (value: number) => void;
};

export const VerticalNavigation = ({ active, items, onChange }: VerticalNavigationProps) => (
  <StyledTabs
    orientation="vertical"
    variant="scrollable"
    value={active}
    onChange={(event, value) => {
      onChange(value);
    }}
    aria-label="Formularabschnitte"
  >
    {items.map((label) => (
      <Tab key={label} label={label} />
    ))}
  </StyledTabs>
);
