import { useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { HoverActions } from '@/components/form-elements/HoverActions';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { OrganizationUsersService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { FilterHeader } from '@/components/form-elements/FilterHeader';

export const OrganizationUsersPage = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();

  const filters = { search, organizationId, includeUsers: true };
  const { data: organizationUsers = [] } = useQuery(
    QueryKeys.organizationUsers.allFilters(filters),
    () => OrganizationUsersService.findAll(filters),
    {
      keepPreviousData: true,
    },
  );

  const { mutate: mutateRemove } = useMutation(
    (userId: string) => OrganizationUsersService.remove({ organizationId: organizationId as string, userId }),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.organizationUsers.allFilters(filters)),
    },
  );

  return (
    <FullHeightLayout>
      <Container>
        <FilterHeader
          addButton={{ label: 'Zuordnen', to: 'zuordnen' }}
          onChange={({ search: newSearch }) => setSearch(newSearch)}
        />

        <TableContainer>
          <Table aria-label="Benutzer/innen">
            <TableHead>
              <TableRow>
                <TableCell>E-Mail</TableCell>
                <TableCell>Rolle</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationUsers.map(({ userId, role, user: { email, status } = {} }) => (
                <TableRow key={userId}>
                  <TableCell component="th" scope="row">
                    <HoverActions
                      title={email as string}
                      actions={[{ label: 'Entfernen', onClick: () => mutateRemove(userId) }]}
                    />
                  </TableCell>

                  <TableCell>{t(`OrganizationUserRole.${role}`)}</TableCell>
                  <TableCell>{t(`UserStatus.${status}`)}</TableCell>
                </TableRow>
              ))}

              {organizationUsers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>Dieser Organisation ist kein/e Benutzer/in zugeordnet.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </FullHeightLayout>
  );
};
