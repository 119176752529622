import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, Ref, ReactElement } from 'react';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Slide direction="right" ref={ref} {...props} />,
);

type MonitoringDialogProps = {
  open: boolean;
  onClose: () => void;
  infoText: string;
};

export const MonitoringDialog = ({ open, onClose, infoText }: MonitoringDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{ sx: { position: 'fixed', left: 0, top: 0, bottom: 0, m: 0, backgroundColor: '#ECF9F3' } }}
    TransitionComponent={Transition}
  >
    <DialogActions>
      <Button onClick={onClose} sx={{ color: 'black', fontSize: 30, fontWeight: 'normal' }}>
        X
      </Button>
    </DialogActions>
    <DialogTitle>Hinweis zur Datenerhebung</DialogTitle>
    <DialogContent>
      <Typography display="block" sx={{ marginBottom: 2 }}>
        {infoText.split('\n').map((v) => (
          <p key={v}>{v}</p>
        ))}
      </Typography>
    </DialogContent>
  </Dialog>
);
