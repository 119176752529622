import { Grid } from '@mui/material';

import { WorkingModelDto } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';

type WorkingModelProps = {
  isLoading: boolean;
  disabled?: boolean;
};

export const WorkingModel = ({ isLoading, disabled }: WorkingModelProps) => {
  const fields: { name: keyof WorkingModelDto; label: string }[] = [
    { name: 'altShift', label: 'Wechselschicht' },
    { name: 'offsetDayShift', label: 'Versetzte Tagesschicht' },
    { name: 'partTimeDays', label: 'Teilzeit (einzelne Tage)' },
    { name: 'partTimeHalfDays', label: 'Teilzeit (halbtags)' },
    { name: 'flexTime', label: 'Normale Arbeitszeiten mit Gleitzeit' },
    { name: 'withoutFlexTime', label: 'Normale Arbeitszeiten ohne Gleitzeit' },
    { name: 'other', label: 'Sonstige' },
  ];

  return (
    <FormSection title="Beschäftigte je Arbeitszeitmodell (Anzahl)">
      <Grid container spacing={1} className="form-section-item">
        {fields.map(({ name, label }) => (
          <Grid key={name} item xs={6}>
            <TextFieldNumber
              label={label}
              isLoading={isLoading}
              name={`workingModel.${name}`}
              rules={{ min: 0 }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </FormSection>
  );
};
