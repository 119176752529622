/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Sector {
    MANUFACTURING_INDUSTRY = 'MANUFACTURING_INDUSTRY',
    ENERGY_WATER_SUPPLY = 'ENERGY_WATER_SUPPLY',
    BUILDING_INDUSTRY = 'BUILDING_INDUSTRY',
    TRADE_HOSPITALITY_INDUSTRY = 'TRADE_HOSPITALITY_INDUSTRY',
    TRANSPORT = 'TRANSPORT',
    INFORMATION_COMMUNICATION = 'INFORMATION_COMMUNICATION',
    FINANCE_INSURANCE = 'FINANCE_INSURANCE',
    PROPERTY_HOUSING = 'PROPERTY_HOUSING',
    SCIENCE = 'SCIENCE',
    ECONOMY_SERVICE = 'ECONOMY_SERVICE',
    OTHER_SERVICES = 'OTHER_SERVICES',
    CIVIL_SERVICE = 'CIVIL_SERVICE',
    HEALTH_SOCIAL_SERVICE = 'HEALTH_SOCIAL_SERVICE',
    AGRICULTURE_FORESTRY_FISHERY = 'AGRICULTURE_FORESTRY_FISHERY',
    MINING = 'MINING',
    OTHER = 'OTHER',
}
