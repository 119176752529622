import { forwardRef } from 'react';
import { TextField, Tooltip, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import { FormSection } from '@/components/form-elements/FormSection';
import { Location, UpdateLocationDto } from '@/services/api';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { validateAsOptionalGtOne } from '@/utils/validateAsOptionalGtOne';
import { TextFieldText } from '@/components/form-elements/TextFieldText';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';

export type FormDataLocationCoreData = Pick<
  UpdateLocationDto,
  'name' | 'consultingCaseLocationId' | 'size' | 'address'
>;

type CoreDataFormProps = {
  location?: Location;
  isLoading: boolean;
};

export const CoreDataForm = forwardRef<HTMLElement, CoreDataFormProps>(
  ({ location, isLoading }: CoreDataFormProps, ref) => {
    const { data: organization } = useQueryOrganization();
    const isFieldDisabled = useIsFieldDisabled(location);

    return (
      <FormSection ref={ref} title="Stammdaten">
        <TextFieldText
          label="Bezeichnung"
          isLoading={isLoading}
          name="name"
          required
          disabled={isFieldDisabled('name')}
        />

        {!isFieldDisabled('consultingCaseLocationId') && (
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationCoreData, 'consultingCaseLocationId'>
              name="consultingCaseLocationId"
              defaultValue=""
              rules={{
                minLength: { value: 2, message: 'Bitte geben sie eine zweistellige Nummer ein' },
                maxLength: { value: 2, message: 'Bitte geben sie eine zweistellige Nummer ein' },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.currentTarget.value.replace(/\D/g, ''));
                    }}
                    label="Anwendungsfall-Nummerierung"
                    inputProps={{ maxLength: 2 }}
                    error={!!error}
                    helperText={error?.message}
                  />
                  <Tooltip
                    title="Zusammensetzung: Beratungsfallnummer (3) + Teilnahmejahr (2) + Anwendungsfall-Nummerierung (2)"
                    placement="top-start"
                  >
                    <Typography sx={{ paddingLeft: '16px', paddingBottom: '10px' }}>
                      {field.value?.length === 2 &&
                        `Referenznummer: ${organization?.consultingCaseId || 'XXX'}${
                          organization?.participationDate ? dayjs(organization?.participationDate).format('YY') : 'JJ'
                        }${field.value}`}
                    </Typography>
                  </Tooltip>
                </>
              )}
            />
          </TextFieldLoading>
        )}

        <TextFieldNumber
          label="Anzahl der Beschäftigten im Anwendungsfall"
          isLoading={isLoading}
          name="size"
          rules={{ min: 1, validate: validateAsOptionalGtOne }}
          disabled={isFieldDisabled('size')}
        />

        <TextFieldText label="Adresse" isLoading={isLoading} name="address" disabled={isFieldDisabled('address')} />
      </FormSection>
    );
  },
);
