import { useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NetworkCategory, NetworksService } from '@/services/api';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { NodeAccordion } from '@/components/tree-map/NodeAccordion';
import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';
import { LocationLeafs } from '@/components/tree-map/LocationLeafs';

export const NetworksTreePage = () => {
  const { t } = useTranslation();

  const navigationItems = Object.values(NetworkCategory);

  const { data = [] } = useQuery(QueryKeys.networks.all, () => NetworksService.findAll());

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems.map((navigationItem) => t(`NetworkCategory.${navigationItem}`))}
    >
      {(tab: number) => {
        const activeCategory = navigationItems[tab];

        return (
          <>
            <Button
              color="tertiary"
              size="large"
              startIcon={<Plus />}
              fullWidth
              variant="contained"
              sx={{ justifyContent: 'flex-start', maxWidth: 560, marginBottom: 1 }}
              component={Link}
              to="/beratung/kategorien/erstellen"
              state={{ category: activeCategory }}
            >
              {t(`NetworkCategory.${activeCategory}`)} erstellen
            </Button>

            {data
              .filter(({ category: networkCategory }) => networkCategory === activeCategory)
              .map(({ id, name, category }) => (
                <NodeAccordion
                  key={id}
                  title={name}
                  type={t(`NetworkCategory.${category}`)}
                  actions={[{ label: 'Bearbeiten', onClick: `/beratung/kategorien/${id}` }]}
                >
                  <LocationLeafs id={id} queryFn={NetworksService.findOne} type="Network" />
                </NodeAccordion>
              ))}
          </>
        );
      }}
    </SidebarLayout>
  );
};
