import { Button, Container, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { ApiError, AuthenticationService, ForgotPasswordDto } from '@/services/api';
import { Navigation } from '@/components/navigations/Navigation';
import { FormSection } from '@/components/form-elements/FormSection';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';

export const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDto>();

  const { mutate, error, isSuccess } = useMutation<any, ApiError, ForgotPasswordDto>((requestBody: ForgotPasswordDto) =>
    AuthenticationService.forgotPassword({ requestBody }),
  );

  return (
    <>
      <Navigation />

      <FullHeightLayout navigations={1}>
        <Container>
          {isSuccess ? (
            <FormSection title="Link angefordert" large>
              <Typography>
                Der Link zum Zurücksetzen des Passworts wird Ihnen zugesandt. Bitte überprüfen Sie Ihre E-Mails.
              </Typography>
            </FormSection>
          ) : (
            <form onSubmit={handleSubmit((requestBody) => mutate(requestBody))}>
              <FormSection title="Passwort vergessen">
                <Typography sx={{ marginLeft: '16px' }} gutterBottom>
                  Sie erhalten bei Angabe Ihrer E-Mail-Adresse einen Link per E-Mail zugeschickt.
                </Typography>

                <TextField
                  label="E-Mail"
                  type="email"
                  error={!!errors.email || !!error}
                  helperText={error && 'Es ist ein Fehler aufgetreten'}
                  {...register('email', { required: true })}
                />

                <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 5 }}>
                  Link anfordern
                </Button>
              </FormSection>
            </form>
          )}
        </Container>
      </FullHeightLayout>
    </>
  );
};
