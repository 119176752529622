import { ReactNode, useRef, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  Container,
  css,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Toolbar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGlobalStore } from '@/stores/useGlobalStore';
import { AuthenticationService } from '@/services/api';
import { ReactComponent as TrafficonGeoservicesLogo } from '@/assets/icons/public/trafficon-o.svg';
import { ReactComponent as User } from '@/assets/icons/user-circle.svg';
import { Loading } from '@/components/navigations/Loading';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useDefaultRoute } from '@/hooks/useDefaultRoute';

const PrimaryAppBar = styled(AppBar)(
  ({ theme }) => css`
    background-color: ${theme.palette.text.primary};
    color: ${theme.palette.common.white};
    text-transform: none;
  `,
);

const SecondaryAppBar = styled(AppBar)(
  () => css`
    background-color: #ededed;
    color: inherit;
    text-transform: none;
  `,
);

const StyledLogo = styled(TrafficonGeoservicesLogo)(
  ({ theme }) => css`
    width: auto;
    height: ${theme.spacing(4)};
  `,
);

const StyledButton = styled(Button)(
  css`
    color: inherit;
    text-transform: none;
    padding-left: 0;
    padding-right: 0;
  `,
) as typeof Button;

type NavigationProps = {
  children?: ReactNode;
};

export const Navigation = ({ children }: NavigationProps) => {
  const user = useGlobalStore((state) => state.user);
  const { setUser } = useGlobalStore((state) => state.actions);
  const ability = useAppAbility();
  const navigate = useNavigate();
  const { defaultRoute } = useDefaultRoute();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutate } = useMutation(() => AuthenticationService.logout(), {
    onSuccess: () => {
      queryClient.clear();
      setUser(undefined);
      ability.update([]);
      navigate('/', { replace: true });
    },
  });

  return (
    <>
      <PrimaryAppBar position="static">
        <Container>
          <Toolbar disableGutters>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: user ? 'space-between' : 'flex-end',
                alignItems: 'center',
              }}
            >
              {user ? (
                <>
                  <StyledButton
                    color="inherit"
                    variant="text"
                    component={Link}
                    to={defaultRoute}
                    startIcon={<StyledLogo />}
                  >
                    Start
                  </StyledButton>

                  <StyledButton
                    endIcon={<User />}
                    color="inherit"
                    variant="text"
                    ref={anchorRef}
                    id="menu-button"
                    aria-controls={menuOpen ? 'menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={() => setMenuOpen(true)}
                  >
                    {user.email}
                  </StyledButton>

                  <Popper
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-end"
                    transition
                    disablePortal
                    sx={{ zIndex: 100 }}
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: 'right top',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                            <MenuList autoFocusItem={menuOpen} id="menu" aria-labelledby="menu-button">
                              <MenuItem component={Link} to="/passwort-aendern">
                                Passwort ändern
                              </MenuItem>
                              <MenuItem onClick={() => mutate()}>Ausloggen</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              ) : (
                <StyledButton endIcon={<User />} color="inherit" variant="text" component={Link} to="/#login">
                  Einloggen
                </StyledButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </PrimaryAppBar>

      {children && (
        <SecondaryAppBar position="static">
          <Container>
            <Toolbar disableGutters>{children}</Toolbar>
          </Container>
        </SecondaryAppBar>
      )}

      <Box sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
        <Loading />
      </Box>
    </>
  );
};
