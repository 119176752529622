import { ReactNode } from 'react';
import { Container, css, styled } from '@mui/material';

import { Navigation } from '@/components/navigations/Navigation';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';

const StyledFullHeightLayout = styled(FullHeightLayout)(
  css`
    display: flex;
  `,
);

type ContentLayoutPageProps = { children: ReactNode };

export const ContentLayoutPage = ({ children }: ContentLayoutPageProps) => (
  <>
    <Navigation />
    <StyledFullHeightLayout navigations={1}>
      <Container sx={{ minHeight: '100%' }}>{children}</Container>
    </StyledFullHeightLayout>
  </>
);
