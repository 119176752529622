import { useState } from 'react';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { AcceptInvitationDto } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { NavigationLink } from '@/components/navigations/NavigationLink';

type AcceptInvitationFormProps = {
  error: string | null;
  onSubmit: (data: AcceptInvitationDto) => void;
};

export const AcceptInvitationForm = ({ error, onSubmit }: AcceptInvitationFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AcceptInvitationDto & { repeatPassword: string }>();

  const handlePolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptPolicy(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection title="Einladung annehmen">
        <TextField
          label="Passwort"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          error={!!error || !!errors.password}
          helperText="Mindestens 8 Zeichen"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                >
                  {showPassword ? 'Verstecken' : 'Anzeigen'}
                </Button>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true, minLength: 8 })}
        />
        <FormControlLabel
          checked={acceptPolicy}
          control={<Checkbox onChange={handlePolicyChange} />}
          label={
            <span>
              Willkommen auf der digitalen Beratungsplattform der ivm GmbH im Rahmen des Projekts &quot;Besser zur
              Arbeit&quot;. Mit Annahme der Einladung und Registrierung stimmen Sie der Verarbeitung der E-Mailadresse
              in diesem Webportal zu. Details zur Datennutzung finden Sie auf der Seite{' '}
              <NavigationLink key="/datenschutz" to="/datenschutz">
                <b>Datenschutz</b>
              </NavigationLink>
            </span>
          }
        />
      </FormSection>

      <Button type="submit" color="primary" variant="contained" size="large" disabled={!acceptPolicy}>
        Einladung annehmen
      </Button>
    </form>
  );
};
