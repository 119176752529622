import { ReactNode, useState } from 'react';
import { IconButton, Box, Popover, Stack, Button, Typography, Chip, styled, css } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as ClearIcon } from '@/assets/icons/form/clear.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/form/filter.svg';

const ResetButton = styled(Button)(
  ({ theme }) =>
    css`
      color: ${theme.palette.text.primary};
      &:hover {
        color: ${theme.palette.text.secondary};
      }
    `,
);

const MainButton = styled(Button)(
  ({ theme }) =>
    css`
      height: 100%;
      background-color: ${theme.palette.tertiary.light};

      &:hover {
        background-color: ${theme.palette.tertiary.main};
      }

      .MuiButton-startIcon {
        margin-left: 0;
        margin-right: ${theme.spacing(2)};
      }
    `,
);

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1.5)};

    .MuiChip-label {
      height: ${theme.spacing(3)};
      min-width: ${theme.spacing(3)};
      line-height: 21px;
    }
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) =>
    css`
      color: ${theme.palette.text.primary};
      font-weight: bold;
      font-size: 1.15rem;
      text-transform: uppercase;
    `,
);

const FilterBox = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    border: 2px solid ${theme.palette.tertiary.light};
    padding: ${theme.spacing(3)};

    .MuiFormGroup-root,
    .MuiFormControlLabel-root {
      display: block;
    }
  `,
);

type FilterButtonProps = { children: ReactNode; filterCount: number };

export const FilterButton = ({ children, filterCount }: FilterButtonProps) => {
  const { reset } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const filterEnabled = filterCount > 0;

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <MainButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        startIcon={<FilterIcon />}
        size="large"
        variant="contained"
      >
        {filterCount > 0 && <StyledChip label={filterCount} color="primary" size="small" />}
        <StyledTypography sx={{ marginRight: (theme) => theme.spacing(filterEnabled ? 4 : 0) }}>
          Filter
        </StyledTypography>
      </MainButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <FilterBox>
          <Stack direction="column">{children}</Stack>

          <Box>
            <ResetButton form="filter-header" type="submit" onClick={() => reset()}>
              Zurücksetzen
            </ResetButton>
          </Box>
        </FilterBox>
      </Popover>
      {filterEnabled && (
        <IconButton
          form="filter-header"
          type="submit"
          onClick={() => reset()}
          sx={{
            position: 'absolute',
            top: '50%',
            right: (theme) => `${theme.spacing(2)}`,
            width: (theme) => `${theme.spacing(4)}`,
            height: (theme) => `${theme.spacing(4)}`,
            transform: 'translateY(-50%)',
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
