/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DurationType {
    LESS_THAN_5_MIN = 'LESS_THAN_5_MIN',
    BETWEEN_6_AND_10_MIN = 'BETWEEN_6_AND_10_MIN',
    BETWEEN_11_AND_20_MIN = 'BETWEEN_11_AND_20_MIN',
    BETWEEN_21_AND_40_MIN = 'BETWEEN_21_AND_40_MIN',
    BETWEEN_41_AND_60_MIN = 'BETWEEN_41_AND_60_MIN',
    MORE_THAN_60_MIN = 'MORE_THAN_60_MIN',
}
