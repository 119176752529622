import { ReactNode } from 'react';
import { css, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  `,
);

type NavigationLinkProps = { children: ReactNode; to: string; end?: boolean };

export const NavigationLink = ({ children, to, end = true }: NavigationLinkProps) => (
  <StyledLink to={to} end={end}>
    {children}
  </StyledLink>
);
