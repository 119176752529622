import { Tooltip } from '@mui/material';

import { ReactComponent as Complete } from '@/assets/icons/status/complete.svg';
import { ReactComponent as Empty } from '@/assets/icons/status/empty.svg';

type MonitoringStatusIconProps = { status: boolean };

export const MonitoringStatusIcon = ({ status }: MonitoringStatusIconProps) =>
  status ? (
    <Tooltip title="aktiviert">
      <span>
        <Complete />
      </span>
    </Tooltip>
  ) : (
    <Tooltip title="nicht aktiviert">
      <span>
        <Empty />
      </span>
    </Tooltip>
  );
