import { Button, Grid, css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as Dropdown } from '@/assets/icons/form/dropdown.svg';

const ArrowLeft = styled(Dropdown)(
  ({ theme }) => css`
    transform: rotate(90deg);
    color: ${theme.palette.primary.main};
  `,
);

const ArrowRight = styled(Dropdown)(
  ({ theme }) => css`
    transform: rotate(-90deg);
    color: ${theme.palette.primary.main};
  `,
);

type LocationProfileFooterProps = { tab: number; navigationItems: string[] };

export const LocationProfileFooter = ({ tab, navigationItems }: LocationProfileFooterProps) => (
  <Grid container justifyContent="space-between" marginTop={4}>
    <Grid item>
      {tab > 0 && tab < navigationItems.length - 1 && (
        <Button startIcon={<ArrowLeft />} component={Link} to={`?tab=${tab - 1}`}>
          {navigationItems[tab - 1]}
        </Button>
      )}
    </Grid>
    <Grid item>
      {tab < navigationItems.length - 1 && (
        <Button endIcon={<ArrowRight />} component={Link} to={`?tab=${tab + 1}`}>
          {navigationItems[tab + 1]}
        </Button>
      )}
    </Grid>
  </Grid>
);
