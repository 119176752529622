import { useState } from 'react';
import { Box, Button, css, styled, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import wrneustadt from '@/assets/images/wr-neustadt-bg.jpg';
import ileLogo from '@/assets/images/ile-fsa-logo.png';
import landscape from '@/assets/images/landscape.jpg';
import tcGsBike from '@/assets/images/tc-gs-bike.png';
import { RadioButtonsCommute } from '@/components/form-elements/RadioButtonsCommute';
import { SliderDistance } from '@/components/form-elements/SliderDistance';
import {
  ApiError,
  CommuteType,
  CreateMonitoringDto,
  Monitoring,
  MonitoringLinkType,
  MonitoringService,
  MonitoringStyle,
} from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { MonitoringDialog } from '@/components/form-elements/MonitoringDialog';
import { ToggleButtonsCommune } from '@/components/form-elements/ToggleButtonsCommune';
import { ToggleButtonsRating } from '@/components/form-elements/ToggleButtonsRating';
import { ReactComponent as TrafficonLogo } from '@/assets/images/trafficon-logo.svg';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { transientOptions } from '@/utils/transientOptions';

const MONITORING_STYLE = {
  [MonitoringStyle.TRAFFICON]: { bgImage: wrneustadt, logo: undefined, color: '#FFBA00', hover: '#FFBA00' },
  [MonitoringStyle.RADGIPFEL]: { bgImage: tcGsBike, logo: undefined, color: '#FFBA00', hover: '#FFBA00' },
  [MonitoringStyle.ILE_FSA]: { bgImage: landscape, logo: ileLogo, color: '#FFBA00', hover: '#FFBA00' },
};

const BackgroundBox = styled(
  Box,
  transientOptions,
)<{ monitoringStyle: MonitoringStyle }>(
  ({ theme, monitoringStyle }) => css`
    min-height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.57), rgba(255, 255, 255, 0.57)),
      url(${MONITORING_STYLE[monitoringStyle].bgImage});
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
    }
  `,
);

const StyledContentBox = styled(Box)(
  ({ theme }) => css`
    min-width: 250px;
    max-width: 850px;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    text-align: center;
    padding-left: 0px;
    position: absolute;
    max-height: 100vh;
    overflow: auto;

    ${theme.breakpoints.up('md')} {
      margin-top: 60px;
      border-radius: 10px;
    }
  `,
) as typeof Box;

const StyledLogo = styled(Box)(
  ({ theme }) => css`
    width: 150px;

    ${theme.breakpoints.up('md')} {
      position: absolute;
      top: 0;
      left: 0;
      // TODO: Broken CSS
      margin 10px;
    }
  `,
) as typeof Box;

const StyledInfoIcon = styled(Box)(
  ({ theme }) => css`
    margin: 10px;

    ${theme.breakpoints.up('md')} {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  `,
) as typeof Box;

const StyledBoxTitle = styled(Box)(
  () => css`
    padding: 30px 25px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  `,
) as typeof Box;

export const MonitoringPage = () => {
  const [open, setOpen] = useState(false);
  const [throttleError, setThrottleError] = useState(false);

  const monitoringThrottle = useGlobalStore((state) => state.monitoringThrottle);
  const { addMonitoringThrottle } = useGlobalStore((state) => state.actions);

  const navigate = useNavigate();
  const { monitoringId } = useParams();
  const { control, watch, handleSubmit, reset } = useForm<CreateMonitoringDto>({
    defaultValues: { distance: 0, commuteType: undefined, locationId: undefined },
  });

  const { data: location } = useQuery(
    QueryKeys.locations.id(monitoringId as string),
    () => MonitoringService.findOne({ monitoringId: monitoringId as string }),
    { enabled: !!monitoringId },
  );

  const { mutate } = useMutation<Monitoring, ApiError, CreateMonitoringDto>(
    (formData: CreateMonitoringDto) => {
      if (location?.linkType === MonitoringLinkType.SMARTPHONE) {
        return MonitoringService.createMobile({ requestBody: { ...formData, locationId: location?.locationId! } });
      }

      return MonitoringService.create({ requestBody: { ...formData, locationId: location?.locationId! } });
    },
    {
      onSuccess: () => {
        if (location?.linkType === MonitoringLinkType.SMARTPHONE) {
          addMonitoringThrottle(monitoringId!);
        }

        reset();
        navigate(`/monitoring/${monitoringId}/daily`);
      },
      onError: (error) => {
        if (error.status === 429) {
          setThrottleError(true);
        }
      },
    },
  );

  return (
    <BackgroundBox monitoringStyle={location?.monitoringStyle || MonitoringStyle.TRAFFICON}>
      <StyledContentBox sx={{ backdropFilter: 'blur(20px)' }}>
        {location && MONITORING_STYLE[location.monitoringStyle].logo !== undefined ? (
          <StyledLogo
            component="img"
            src={MONITORING_STYLE[location.monitoringStyle].logo}
            alt="ILE Fränkische Schweiz Logo"
          />
        ) : (
          ''
        )}

        {location?.monitoringActive && !throttleError ? (
          <form
            name="monitoring-form"
            onSubmit={handleSubmit((submitData) => {
              if (
                location.linkType !== MonitoringLinkType.SMARTPHONE ||
                !monitoringThrottle[monitoringId!] ||
                dayjs(monitoringThrottle[monitoringId!]).isBefore(dayjs().subtract(1, 'hour'))
              ) {
                mutate(submitData);
              } else {
                setThrottleError(true);
              }
            })}
          >
            <Box sx={{ textAlign: 'center', paddingTop: '30px' }}>
              <Typography sx={{ fontSize: 24, fontWeight: 'bold', textTransform: 'uppercase' }}>
                {location.monitoringTitle}
              </Typography>
              <Typography sx={{ fontSize: 12, color: 'primary.main' }}>{location.monitoringSubTitle}</Typography>
            </Box>

            <Box sx={{ padding: 3, textAlign: 'center' }}>
              <Box>
                <RadioButtonsCommute
                  name="commuteType"
                  control={control}
                  label={location.monitoringCommuteTitle || ''}
                  subLabel={location.monitoringCommuteSubTitle || ''}
                  commuteTypes={location.monitoringCommuteTypes as CommuteType[]}
                />
              </Box>
              {location.monitoringDistanceActive && (
                <Box>
                  <SliderDistance
                    name="distance"
                    control={control}
                    label={location.monitoringDistanceTitle || ''}
                    subLabel={location.monitoringDistanceSubTitle || ''}
                    maxValue={location.monitoringScaleMax}
                  />
                </Box>
              )}

              {location.monitoringCommuneActive && (
                <Box sx={{ marginBottom: 3 }}>
                  <ToggleButtonsCommune
                    name="commune"
                    control={control}
                    label={location.monitoringCommuneTitle || ''}
                    subLabel={location.monitoringCommuneSubTitle || ''}
                  />
                </Box>
              )}
              {location.monitoringRatingActive && (
                <Box sx={{ textAlign: 'center' }}>
                  <ToggleButtonsRating
                    name="rating"
                    control={control}
                    label={location.monitoringRatingTitle || ''}
                    subLabel={location.monitoringRatingSubTitle || ''}
                  />
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ width: '200px', height: '56px', fontSize: 14 }}
                  type="submit"
                  disabled={
                    !watch('commuteType') ||
                    (location.monitoringCommuneActive && !watch('commune')) ||
                    (location.monitoringRatingActive && !watch('rating'))
                  }
                >
                  Fertig
                </Button>
              </Box>
              <a href="https://www.trafficon.eu" target="_blank" rel="noreferrer">
                <TrafficonLogo height={40} />
              </a>
            </Box>
          </form>
        ) : (
          <>
            <StyledBoxTitle>Limit erreicht</StyledBoxTitle>
            <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 6, paddingBottom: 10 }}>
              <Typography>
                Aufgrund einer zeitlichen Beschränkung ist derzeit keine weitere Teilnahme an der Umfrage möglich. Bitte
                probieren Sie es zu einem späteren Zeitpunkt noch einmal.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    setThrottleError(false);
                    reset();
                  }}
                >
                  Zurück
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    navigate(`/monitoring/${monitoringId}/daily`);
                  }}
                >
                  Übersicht
                </Button>
              </Box>
            </Box>
          </>
        )}
        {!location?.monitoringActive && (
          <>
            <StyledBoxTitle>Monitoring nicht aktiv</StyledBoxTitle>
            <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 6, paddingBottom: 10 }}>
              <Typography>Das Monitoring ist derzeit nicht aktiviert</Typography>
            </Box>
          </>
        )}
        <StyledInfoIcon onClick={() => setOpen(true)}>
          <InfoIcon fill="grey" stroke="grey" />
        </StyledInfoIcon>
      </StyledContentBox>
      <MonitoringDialog open={open} onClose={() => setOpen(false)} infoText={location?.monitoringInfoText || ''} />
    </BackgroundBox>
  );
};
