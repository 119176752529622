/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrganizationUserRole {
    IVM_INTERNAL = 'IVM_INTERNAL',
    IVM_CONSULTANT = 'IVM_CONSULTANT',
    ORGANIZATION_MOBILITY_AGENT_WRITE = 'ORGANIZATION_MOBILITY_AGENT_WRITE',
    ORGANIZATION_MOBILITY_AGENT_READ = 'ORGANIZATION_MOBILITY_AGENT_READ',
    ORGANIZATION_DECISION_MAKER = 'ORGANIZATION_DECISION_MAKER',
}
