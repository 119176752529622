import { ReactElement } from 'react';
import { Chip, css, styled } from '@mui/material';

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    svg {
      margin-left: ${theme.spacing(0.5)};
    }
  `,
);

type StatusChipProps = { icon?: ReactElement; label: string };

export const StatusChip = ({ icon, label }: StatusChipProps) => <StyledChip icon={icon} label={label} />;
