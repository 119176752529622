import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { LocationsService } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { QueryKeys } from '@/services/QueryKeys';

type FormData = { name: string };

export const CreateLocationPage = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ name }: FormData) =>
      LocationsService.create({ requestBody: { organizationId: organizationId as string, name } }),
    {
      onSuccess: (location) => {
        navigate(`/organisation/${organizationId}/anwendungsfaelle/${location.id}`);

        return queryClient.invalidateQueries(QueryKeys.organizations.id(organizationId as string));
      },
    },
  );

  return (
    <SidebarLayout>
      <form
        id="main-form"
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <FormSection title="Anwendungsfall erstellen">
          <TextField label="Bezeichnung" {...register('name', { required: true })} error={!!errors.name} />
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
};
