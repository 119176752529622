import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Authenticated } from '@/authentication/Authenticated';
import { Navigation } from '@/components/navigations/Navigation';
import { UserRole } from '@/services/api';

type ProtectedLayoutProps = {
  navigation?: ReactNode;
  allowedRoles: UserRole[];
};

export const ProtectedLayout = ({ navigation, allowedRoles }: ProtectedLayoutProps) => (
  <Authenticated allowedRoles={allowedRoles}>
    <Navigation>{navigation}</Navigation>
    <Outlet />
  </Authenticated>
);
