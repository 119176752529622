import { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type ConfirmDialogProps = {
  title: string;
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({ title, children, open, onClose, onConfirm }: ConfirmDialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    {children && <DialogContent>{children}</DialogContent>}
    <DialogActions>
      <Button onClick={onClose}>Nein</Button>
      <Button onClick={onConfirm}>Ja</Button>
    </DialogActions>
  </Dialog>
);
