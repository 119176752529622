import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { Action, IndicatorProcess, IndicatorProcessService, UpdateIndicatorProcessDto } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { DeleteDialog } from '@/components/form-elements/DeleteDialog';
import { queryClient } from '@/setup/query-client';
import { useAppAbility } from '@/hooks/useAppAbility';

type FormData = {
  name: string;
};

export const CoreDataForm = () => {
  const { indicatorProcessId } = useParams();
  const navigate = useNavigate();
  const ability = useAppAbility();

  const methods = useForm<FormData>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const { data: indicatorProcess } = useQuery(
    QueryKeys.indicatorProcesses.id(indicatorProcessId as string),
    () => IndicatorProcessService.findOne({ id: indicatorProcessId as string }),
    { enabled: !!indicatorProcessId },
  );

  useInitialFormValues<Omit<FormData, 'indicatorProcess'>>({
    entity: indicatorProcess && {
      ...indicatorProcess,
    },
    useFormReturn: methods,
    fields: ['name'],
  });

  const { mutate } = useMutation((requestBody: UpdateIndicatorProcessDto) =>
    IndicatorProcessService.update({
      id: indicatorProcessId as string,
      requestBody,
    }),
  );
  const { mutate: mutateRemove } = useMutation(
    () => IndicatorProcessService.remove({ id: indicatorProcessId as string }),
    {
      onSuccess: async () => {
        navigate('/beratung/wsa-berechnung');
        await queryClient.invalidateQueries(QueryKeys.indicatorProcesses.all);
      },
    },
  );

  const isLoading = !indicatorProcess;

  return (
    <form id="main-form" onSubmit={handleSubmit((data) => mutate(data))}>
      <FormSection title="WSA Berechnung">
        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
        </TextFieldLoading>

        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Organisation" value={indicatorProcess?.organization?.name} disabled />
        </TextFieldLoading>
      </FormSection>

      <DeleteDialog
        onDelete={mutateRemove}
        entityLabel="WSA Berechnung"
        entityName={indicatorProcess?.name as string}
        can={ability.can(Action.DELETE, indicatorProcess as IndicatorProcess)}
      >
        Durch das Löschen der WSA Berechnung werden auch alle damit verknüpften Daten (z.B. Relationen) gelöscht.
      </DeleteDialog>
    </form>
  );
};
