/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IndicatorProcessStatus {
    EMPTY = 'EMPTY',
    RELATIONS_UPLOADED = 'RELATIONS_UPLOADED',
    RELATIONS_GEOCODED = 'RELATIONS_GEOCODED',
    READY_FOR_CALCULATION = 'READY_FOR_CALCULATION',
    CALCULATION_DONE = 'CALCULATION_DONE',
    INDICATORS_APPROVED = 'INDICATORS_APPROVED',
}
