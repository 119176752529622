import { Box } from '@mui/material';

import { CommuteType } from '@/services/api';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';

type RadioCommuteIconProps = {
  commuteType: CommuteType;
  checked: boolean;
};

export const RadioCommuteIcon = ({ commuteType, checked }: RadioCommuteIconProps) => (
  <Box
    sx={{
      backgroundColor: checked ? 'secondary.main' : 'white',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    width={68}
    height={68}
  >
    <Box component="img" src={CommuteUtils[commuteType].icon} width={60} height={60} />
  </Box>
);
