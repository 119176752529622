import { AppBar, Box, Container, css, Stack, styled, Toolbar } from '@mui/material';
import { NavLink } from 'react-router-dom';

const PrimaryAppBar = styled(AppBar)(
  ({ theme }) => css`
    background-color: ${theme.palette.text.primary};

    a {
      color: ${theme.palette.common.white};
    }
  `,
) as typeof AppBar;

const StyledLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  `,
);

export const Footer = () => (
  <PrimaryAppBar position="static" component="footer">
    <Container>
      <Toolbar disableGutters>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            displayPrint: 'none',
          }}
        >
          <Stack direction="row" spacing={4}>
            {[
              { label: 'Kontakt', to: 'https://www.trafficon.eu/geoservices/' },
              { label: 'Datenschutz', to: 'https://www.trafficon.eu/datenschutz/' },
              { label: 'Impressum', to: 'https://www.trafficon.eu/impressum/' },
            ].map(({ label, to }) => (
              <StyledLink to={to} key={label} target="_blank">
                {label}
              </StyledLink>
            ))}
          </Stack>
        </Box>
      </Toolbar>
    </Container>
  </PrimaryAppBar>
);
