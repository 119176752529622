import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { ApiError, InvitationsService, User } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { AcceptInvitationForm } from '@/authentication/accept-invitation/AcceptInvitationForm';
import { useDefaultRoute } from '@/hooks/useDefaultRoute';

export const AcceptInvitation = () => {
  const user = useGlobalStore((state) => state.user);
  const { setUser } = useGlobalStore((state) => state.actions);
  const { defaultRoute } = useDefaultRoute();

  const navigate = useNavigate();
  const { userId } = useParams();
  const [searchParams] = useSearchParams();

  const { mutate, error } = useMutation<User, ApiError, string>(
    (password: string) =>
      InvitationsService.accept({
        userId: userId as string,
        invitationToken: searchParams.get('token') as string,
        requestBody: { password },
      }),
    {
      onSuccess: (newUser) => {
        setUser(newUser);
      },
    },
  );

  useEffect(() => {
    if (user) {
      navigate(defaultRoute);
    }
  }, [defaultRoute, navigate, user]);

  return (
    <AcceptInvitationForm
      error={error && (error?.status === 404 ? 'Ungültige Einladung' : 'Es ist ein Fehler aufgetreten.')}
      onSubmit={async ({ password }) => mutate(password)}
    />
  );
};
