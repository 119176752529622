import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { ApiError, CreateUserDto, User, UserRole, UsersService } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldSelect } from '@/components/form-elements/TextFieldSelect';
import { useSetValidationErrors } from '@/hooks/useSetValidationErrors';

type FormData = CreateUserDto;

export const CreateUserPage = () => {
  const navigate = useNavigate();

  const methods = useForm<FormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { setValidationErrors } = useSetValidationErrors(methods.setError);

  const { mutate } = useMutation<User, ApiError, CreateUserDto>(
    (formData: FormData) => UsersService.create({ requestBody: formData }),
    {
      onSuccess: (user) => navigate(`/beratung/benutzerinnen/${user.id}`),
      onError: setValidationErrors,
    },
  );

  return (
    <SidebarLayout>
      <FormProvider {...methods}>
        <form
          id="main-form"
          onSubmit={handleSubmit((data) => {
            mutate(data);
          })}
        >
          <FormSection title="Benutzer/in erstellen">
            <TextField
              label="E-Mail"
              type="email"
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={errors?.email?.message}
            />

            <TextFieldSelect label="Rolle" name="role" i18nKey="UserRole" options={Object.values(UserRole)} />
          </FormSection>

          <Button type="submit" variant="contained" size="large">
            Erstellen
          </Button>
        </form>
      </FormProvider>
    </SidebarLayout>
  );
};
