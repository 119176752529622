import { Chip } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';

import {
  CreateIndicatorRelationDto,
  IndicatorProcess,
  IndicatorProcessService,
  IndicatorRelationStatus,
  UpdateIndicatorProcessDto,
} from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { QueryKeys } from '@/services/QueryKeys';
import { IndicatorRelationsTable } from '@/indicator-process/relations-form/IndicatorRelationsTable';
import { FormIndent } from '@/components/form-elements/FormIndent';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { RelationsCalculation } from '@/indicator-process/relations-form/RelationsCalculation';
import { RelationsApproval } from '@/indicator-process/relations-form/RelationsApproval';
import { RelationsUpload } from '@/indicator-process/relations-form/RelationsUpload';
import { RelationsGeocoding } from '@/indicator-process/relations-form/RelationsGeocoding';

type FormData = { dateOfRelations: Dayjs | null; arrivalSpanFrom: Dayjs | null; arrivalSpanTo: Dayjs | null };

const autoRefetching = (
  indicatorProcess: IndicatorProcess | undefined,
  fileRelations: CreateIndicatorRelationDto[] | undefined,
) =>
  !fileRelations &&
  !!indicatorProcess?.relations?.some(({ geocodingStatus }) => geocodingStatus === IndicatorRelationStatus.TODO);

export const RelationsForm = () => {
  const { indicatorProcessId } = useParams();
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [fileRelations, setFileRelations] = useState<CreateIndicatorRelationDto[]>();
  const methods = useForm<FormData>();

  const queryClient = useQueryClient();
  const { data: indicatorProcess } = useQuery(
    QueryKeys.indicatorProcesses.id(indicatorProcessId as string),
    () => IndicatorProcessService.findOne({ id: indicatorProcessId as string }),
    {
      keepPreviousData: true,
      enabled: !!indicatorProcessId,
      refetchInterval: (data) => (autoRefetching(data, fileRelations) ? 1000 : false),
    },
  );

  useInitialFormValues<FormData>({
    entity: indicatorProcess && {
      ...indicatorProcess,
      dateOfRelations: dayjs(indicatorProcess.dateOfRelations),
      arrivalSpanFrom: indicatorProcess.arrivalSpanFrom
        ? dayjs(indicatorProcess.arrivalSpanFrom)
        : dayjs().startOf('day').hour(8),
      arrivalSpanTo: indicatorProcess.arrivalSpanTo
        ? dayjs(indicatorProcess.arrivalSpanTo)
        : dayjs().startOf('day').hour(9),
    },
    useFormReturn: methods,
    fields: ['dateOfRelations', 'arrivalSpanFrom', 'arrivalSpanTo'],
  });

  const { mutate } = useMutation(
    (requestBody: UpdateIndicatorProcessDto) =>
      IndicatorProcessService.update({
        id: indicatorProcessId as string,
        requestBody,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(QueryKeys.indicatorProcesses.id(indicatorProcessId as string), data);
      },
    },
  );

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <FormSection title="Aktueller Status">
            <FormIndent>
              <Chip label={t(`IndicatorProcessStatus.${indicatorProcess?.status}`)} size="medium" />
            </FormIndent>
          </FormSection>

          {indicatorProcess && (
            <>
              <RelationsUpload
                indicatorProcess={indicatorProcess}
                file={file}
                fileRelations={fileRelations}
                onFileChange={(newFile) => setFile(newFile)}
                onFileRelationsChange={(newFileRelations) => setFileRelations(newFileRelations)}
              />
              <RelationsGeocoding
                indicatorProcess={indicatorProcess}
                file={file}
                fileRelations={fileRelations}
                onFileChange={(newFile) => setFile(newFile)}
                onFileRelationsChange={(newFileRelations) => setFileRelations(newFileRelations)}
                mutate={mutate}
              />

              {!fileRelations && (
                <>
                  <RelationsCalculation indicatorProcess={indicatorProcess} mutate={mutate} />
                  <RelationsApproval indicatorProcess={indicatorProcess} mutate={mutate} />
                </>
              )}
            </>
          )}
        </form>
      </FormProvider>

      <IndicatorRelationsTable relations={fileRelations || indicatorProcess?.relations || []} />
    </>
  );
};
