import { Button, ButtonGroup, Grid } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IndicatorProcess, IndicatorProcessStatus, IndicatorType, UpdateIndicatorProcessDto } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';

type RelationsApprovalProps = {
  indicatorProcess: IndicatorProcess;
  mutate: UseMutateFunction<IndicatorProcess, unknown, UpdateIndicatorProcessDto>;
};

export const RelationsApproval = ({ indicatorProcess, mutate }: RelationsApprovalProps) => {
  const isFieldDisabled = useIsFieldDisabled(indicatorProcess);
  const { t } = useTranslation();

  return [IndicatorProcessStatus.CALCULATION_DONE, IndicatorProcessStatus.INDICATORS_APPROVED].includes(
    indicatorProcess.status,
  ) ? (
    <>
      <FormSection title="Freigabe">
        <Grid container alignItems="center" spacing={1}>
          {indicatorProcess?.status === IndicatorProcessStatus.CALCULATION_DONE && (
            <Grid item>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => mutate({ status: IndicatorProcessStatus.INDICATORS_APPROVED })}
                disabled={isFieldDisabled('status')}
              >
                Zur Ansicht freigeben
              </Button>
            </Grid>
          )}

          {indicatorProcess?.status === IndicatorProcessStatus.INDICATORS_APPROVED && (
            <Grid item>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => mutate({ status: IndicatorProcessStatus.CALCULATION_DONE })}
                disabled={isFieldDisabled('status')}
              >
                Freigabe zur Ansicht widerrufen
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              size="large"
              color="primary"
              component={Link}
              to={`/organisation/${indicatorProcess.organizationId}/wohnstandortanalyse/${indicatorProcess.id}`}
            >
              Zur Analyse
            </Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Export / Download CSV">
        <Grid item>
          <ButtonGroup size="large" color="primary">
            {Object.values(IndicatorType).map((indicatorType) => (
              <Button
                key={indicatorType}
                href={`${import.meta.env.VITE_API_URL}/api/v1/indicator-processes/${
                  indicatorProcess.id
                }/export/?indicatorType=${indicatorType}`}
                target="_blank"
              >
                {t(`IndicatorType.${indicatorType}`)}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </FormSection>
    </>
  ) : null;
};
