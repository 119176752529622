import { Button, Divider, Grid } from '@mui/material';

import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';

type FieldArrayRemoveProps = { onClick: () => void; print?: boolean; disabled?: boolean };

export const FieldArrayRemove = ({ onClick, print, disabled }: FieldArrayRemoveProps) => (
  <>
    {!disabled && (
      <Grid container justifyContent="flex-end" mb={2}>
        <Grid item>
          <Button
            startIcon={<Plus style={{ transform: 'rotate(45deg)' }} />}
            variant="contained"
            onClick={onClick}
            color="tertiary"
            sx={{ ...(print && { display: 'none' }) }}
          >
            Stellplatzanlage entfernen
          </Button>
        </Grid>
      </Grid>
    )}

    <Divider />
  </>
);
