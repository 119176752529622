import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { FormSection } from '@/components/form-elements/FormSection';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { QueryKeys } from '@/services/QueryKeys';
import {
  Action,
  IndicatorRoutingConfiguration,
  IndicatorRoutingQueueService,
  Subject,
  UpdateIndicatorRoutingConfigurationDto,
} from '@/services/api';
import { useAppAbility } from '@/hooks/useAppAbility';

type FormData = {
  weekdaysFrom: Dayjs | null;
  weekdaysTo: Dayjs | null;
  weekendsFrom: Dayjs | null;
  weekendsTo: Dayjs | null;
};

type IndicatorRoutingScheduleFormProps = {
  onMutate: () => void;
  onSuccess: () => void;
};

export const IndicatorRoutingScheduleForm = ({ onMutate, onSuccess }: IndicatorRoutingScheduleFormProps) => {
  const methods = useForm<FormData>();
  const { control, handleSubmit } = methods;
  const ability = useAppAbility();

  const { data: routingConfiguration } = useQuery(QueryKeys.routingConfiguration.getConfig, async () =>
    IndicatorRoutingQueueService.getConfiguration(),
  );

  useInitialFormValues<FormData>({
    entity: routingConfiguration && {
      ...routingConfiguration,
      weekdaysFrom: dayjs(routingConfiguration?.weekdaysFrom, 'HH:mm:ss'),
      weekdaysTo: dayjs(routingConfiguration?.weekdaysTo, 'HH:mm:ss'),
      weekendsFrom: dayjs(routingConfiguration?.weekendsFrom, 'HH:mm:ss'),
      weekendsTo: dayjs(routingConfiguration?.weekendsTo, 'HH:mm:ss'),
    },
    useFormReturn: methods,
    fields: ['weekdaysFrom', 'weekdaysTo', 'weekendsFrom', 'weekendsTo'],
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (requestBody: UpdateIndicatorRoutingConfigurationDto) =>
      IndicatorRoutingQueueService.updateConfiguration({ requestBody }),
    {
      onMutate,
      onSuccess: (newConfiguration) => {
        onSuccess();

        queryClient.setQueryData<IndicatorRoutingConfiguration>(
          QueryKeys.routingConfiguration.getConfig,
          newConfiguration,
        );
      },
    },
  );

  const isLoading = !routingConfiguration;

  return (
    <form
      id="main-form"
      onSubmit={handleSubmit((data) => {
        mutate({
          weekdaysFrom: typeof data.weekdaysFrom?.format === 'function' ? data.weekdaysFrom.format('HH:mm:ss') : null,
          weekdaysTo: typeof data.weekdaysTo?.format === 'function' ? data.weekdaysTo.format('HH:mm:ss') : null,
          weekendsFrom: typeof data.weekendsFrom?.format === 'function' ? data.weekendsFrom.format('HH:mm:ss') : null,
          weekendsTo: typeof data.weekendsTo?.format === 'function' ? data.weekendsTo.format('HH:mm:ss') : null,
        });
      })}
    >
      {[
        { label: 'Wochentags (Montags bis Freitags)', name: 'weekdays' },
        { label: 'Wochenends (Samstag, Sonntag)', name: 'weekends' },
      ].map(({ label, name }) => (
        <FormSection title={label} key={name}>
          <Grid container spacing={1}>
            {['From', 'To'].map((nameSuffix) => {
              const fieldName = (name + nameSuffix) as keyof FormData;

              return (
                <Grid item xs={6} key={fieldName}>
                  <TextFieldLoading isLoading={isLoading}>
                    <Controller
                      control={control}
                      name={fieldName}
                      render={({ field: { ref, ...rest } }) => (
                        <TimePicker
                          ampm={false}
                          label={{ From: 'Von', To: 'Bis' }[nameSuffix]}
                          format="HH:mm"
                          inputRef={ref}
                          disabled={ability.cannot(Action.UPDATE, Subject.INDICATOR_ROUTING_CONFIGURATION)}
                          {...rest}
                        />
                      )}
                    />
                  </TextFieldLoading>
                </Grid>
              );
            })}
          </Grid>
        </FormSection>
      ))}
    </form>
  );
};
