/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NetworkCategory {
    TRANSPORT_ASSOCIATION = 'TRANSPORT_ASSOCIATION',
    IHK_DISTRICT = 'IHK_DISTRICT',
    SHAREHOLDER_DISTRICT = 'SHAREHOLDER_DISTRICT',
    REGION = 'REGION',
    BUSINESS_PARK = 'BUSINESS_PARK',
    COOPERATION = 'COOPERATION',
    QUARTER = 'QUARTER',
    ORGANIZATION_TYPE = 'ORGANIZATION_TYPE',
}
