import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, FormControlLabel, Grid, Switch, SxProps, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { AnalyseMonitoringData, MonitoringFilter } from '@/components/form-elements/MonitoringFilter';
import { FranconianSwitzerlandCommuneType, MonitoringService } from '@/services/api';
import { ActiveInactivePlot } from '@/monitoring/plots/ActiveInactivePlot';
import { CarbonDioxidePlot } from '@/monitoring/plots/CarbonDioxidePlot';
import { MovementAmount } from '@/monitoring/plots/MovementAmount';
import { QueryKeys } from '@/services/QueryKeys';
import { ReducedCarbonDioxidePlot } from '@/monitoring/plots/ReducedCarbonDioxidePlot';
import { DistancePlot } from '@/monitoring/plots/DistancePlot';
import { processMonitoringData } from '@/monitoring/utils/processMonitoringData';
import { ModalSplitPlot } from '@/monitoring/plots/ModalSplitPlot';
import { CarbonDioxidBadge } from '@/monitoring/plots/CarbonDioxidBadge';
import { ModalSplitProgressPlot } from '@/monitoring/plots/ModalSplitProgressPlot';
import { CommunePlot } from '@/monitoring/plots/CommunePlot';
import { RatingPlot } from '@/monitoring/plots/RatingPlot';

const filtersDefaultValues: AnalyseMonitoringData = {
  locationId: null as unknown as undefined,
  organizationId: null as unknown as undefined,
  networkId: null as unknown as undefined,
  adminLevelId: null as unknown as undefined,
  communes: [FranconianSwitzerlandCommuneType.NOT_SPECIFIED],
  start: dayjs().startOf('year'),
  end: dayjs().endOf('year'),
};

const infoBoxStyle: SxProps = {
  backgroundColor: '#ededed',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

const infoTextStyle: SxProps = {
  textAlign: 'center',
};

export const MonitoringAnalysisPage = () => {
  const { organizationId } = useParams();
  filtersDefaultValues.organizationId = organizationId !== undefined ? organizationId : (null as unknown as undefined);
  const [filters, setFilters] = useState<AnalyseMonitoringData>(filtersDefaultValues);
  const [showIcons, setShowIcons] = useState(true);
  const [wayBack, setWayBack] = useState(true);
  const [showSubtitle, setShowSubtitle] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const methods = useForm({ defaultValues: filtersDefaultValues });

  const { data: monitoringData = [], isLoading } = useQuery(
    QueryKeys.monitoring.allFilters(filters),
    () =>
      MonitoringService.analysis({
        requestBody: {
          locationId: filters.locationId,
          organizationId: filters.organizationId,
          adminLevelId: filters.adminLevelId,
          networkId: filters.networkId,
          communes: filters.communes,
          start: filters.start.toISOString(),
          end: filters.end.toISOString(),
        },
      }),
    { refetchOnWindowFocus: false, ...(autoRefresh && { refetchInterval: 30000 }) },
  );

  const data = processMonitoringData(monitoringData, wayBack);

  return (
    <FullHeightLayout>
      <Container>
        <FormProvider {...methods}>
          <form>
            <MonitoringFilter
              defaultValues={filtersDefaultValues}
              onFilter={(formData) => {
                setFilters(formData);
              }}
              autoRefresh={autoRefresh}
              setAutoRefresh={setAutoRefresh}
            />
          </form>
        </FormProvider>
        {!isLoading &&
          ((monitoringData.length > 0 && organizationId === undefined) ||
            (monitoringData.length >= 20 && organizationId !== undefined)) && (
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              <Grid item xs={3}>
                <Box sx={{ ...infoBoxStyle, alignContent: 'center' }}>
                  <Typography sx={infoTextStyle}>Stichprobengröße</Typography>
                  <Typography sx={{ ...infoTextStyle, fontSize: 26, fontWeight: 'bold' }}>
                    {monitoringData.length}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ ...infoBoxStyle, paddingLeft: '20px' }}>
                  <FormControlLabel
                    control={<Switch onChange={(event) => setShowIcons(event.target.checked)} checked={showIcons} />}
                    label="Icons anzeigen"
                  />
                  <FormControlLabel
                    control={<Switch onChange={(event) => setWayBack(event.target.checked)} checked={wayBack} />}
                    label="Rückweg berücksichtigen"
                  />
                  <FormControlLabel
                    control={
                      <Switch onChange={(event) => setShowSubtitle(event.target.checked)} checked={showSubtitle} />
                    }
                    label="Untertitel anzeigen"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <CarbonDioxidBadge data={data} reduced={false} monitoringData={monitoringData} />
              </Grid>
              <Grid item xs={3}>
                <CarbonDioxidBadge data={data} reduced monitoringData={monitoringData} />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ModalSplitPlot data={data} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ActiveInactivePlot data={data} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <CarbonDioxidePlot data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ReducedCarbonDioxidePlot data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <MovementAmount data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <DistancePlot
                    data={data}
                    showIcons={showIcons}
                    includeWayBack={wayBack}
                    showSubtitle={showSubtitle}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ModalSplitProgressPlot data={monitoringData} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <CommunePlot data={monitoringData} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <RatingPlot data={monitoringData} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
            </Grid>
          )}
        {!isLoading && monitoringData.length < 20 && organizationId !== undefined && (
          <Typography sx={{ marginTop: 2 }}>
            Die verfügbare Datengrundlage unterschreitet die erforderliche Mindest-Stichprobengröße für eine
            Diagramm-Anzeige. Bitte passen Sie die Filterkriterien an oder informieren Sie sich zu einem späteren
            Zeitpunkt des Erhebungszeitraums über die Ergebnisse.
          </Typography>
        )}
      </Container>
    </FullHeightLayout>
  );
};
