import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { OrganizationsService } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';

type FormData = { name: string };

export const CreateOrganizationPage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { mutate } = useMutation((formData: FormData) => OrganizationsService.create({ requestBody: formData }), {
    onSuccess: (organization) => navigate(`/organisation/${organization.id}/stammdaten`),
  });

  return (
    <SidebarLayout>
      <form
        id="main-form"
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <FormSection title="Organisation erstellen">
          <TextField label="Organisationsname" {...register('name', { required: true })} error={!!errors.name} />
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
};
