import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from '@/assets/icons/form/copy.svg';
import { ReactComponent as QRIcon } from '@/assets/icons/form/qrcode.svg';
import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormHeading } from '@/components/form-elements/FormHeading';
import { FormIndent } from '@/components/form-elements/FormIndent';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { TextFieldText } from '@/components/form-elements/TextFieldText';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import {
  CommuteType,
  FranconianSwitzerlandCommuneType,
  Location,
  MonitoringStyle,
  RatingType,
  UpdateLocationDto,
} from '@/services/api';

export type FormDataLocationMonitoring = Pick<
  UpdateLocationDto,
  | 'monitoringStyle'
  | 'monitoringTitle'
  | 'monitoringSubTitle'
  | 'monitoringInfoText'
  | 'monitoringCredits'
  | 'monitoringCreditsLink'
  | 'monitoringCommuteTitle'
  | 'monitoringCommuteSubTitle'
  | 'monitoringDistanceActive'
  | 'monitoringDistanceTitle'
  | 'monitoringDistanceSubTitle'
  | 'monitoringRatingActive'
  | 'monitoringRatingTitle'
  | 'monitoringRatingSubTitle'
  | 'monitoringCommuneActive'
  | 'monitoringCommuneTitle'
  | 'monitoringCommuneSubTitle'
  | 'monitoringActive'
  | 'monitoringCommuteTypes'
  | 'monitoringScaleMax'
>;

type MonitoringConfigFormProps = {
  location?: Location;
  isLoading: boolean;
};

export const MonitoringConfigForm = ({ location, isLoading }: MonitoringConfigFormProps) => {
  const isFieldDisabled = useIsFieldDisabled(location);
  const { t } = useTranslation();

  const createLink = (id: string) => `${window.location.protocol}//${window.location.host}/monitoring/${id}`;

  const [tooltipLabel, setTooltipLabel] = useState('Link kopieren');
  const { watch } = useFormContext<FormDataLocationMonitoring>();

  const monitoringActive = watch('monitoringActive');
  const monitoringDistanceActive = watch('monitoringDistanceActive');
  const monitoringCommuneActive = watch('monitoringCommuneActive');
  const monitoringRatingActive = watch('monitoringRatingActive');

  const downloadQRCode = () => {
    const svg = document.getElementById('QRCode');
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      if (!ctx) return;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <FormSection title="Monitoring Status">
        <Typography gutterBottom sx={{ marginLeft: 2 }}>{`Monitoring ist ${
          monitoringActive ? 'aktiviert' : 'deaktiviert'
        }`}</Typography>

        <Box sx={{ marginLeft: 2 }}>
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationMonitoring, 'monitoringActive'>
              name="monitoringActive"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  label="aktiv"
                  value={field.name}
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                  control={<Checkbox />}
                />
              )}
            />
          </TextFieldLoading>
        </Box>
      </FormSection>

      <FormSection title="Stil">
        <FormControl component="fieldset" sx={{ display: 'block', marginBottom: 2 }}>
          <FormIndent>
            <Controller
              name="monitoringStyle"
              defaultValue={null}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {Object.values(MonitoringStyle).map((v) => (
                    <FormControlLabel key={v} value={v} control={<Radio />} label={v} />
                  ))}
                </RadioGroup>
              )}
            />
          </FormIndent>
        </FormControl>
      </FormSection>

      <FormSection title="Monitoring Überschrift">
        <TextFieldText
          label="Überschrift"
          isLoading={isLoading}
          name="monitoringTitle"
          disabled={isFieldDisabled('monitoringTitle')}
        />
        <TextFieldText
          label="Zweite Zeile"
          isLoading={isLoading}
          name="monitoringSubTitle"
          disabled={isFieldDisabled('monitoringSubTitle')}
        />
      </FormSection>

      <FormSection title="Hinweis zur Datenerhebung">
        <TextFieldText
          label="Infotext"
          isLoading={isLoading}
          name="monitoringInfoText"
          multiline
          disabled={isFieldDisabled('monitoringInfoText')}
        />
      </FormSection>

      <FormSection title="Abschluss">
        <TextFieldText
          label="Abschluss Text"
          isLoading={isLoading}
          name="monitoringCredits"
          disabled={isFieldDisabled('monitoringCredits')}
        />
        <TextFieldText
          label="Abschluss Link"
          isLoading={isLoading}
          name="monitoringCreditsLink"
          disabled={isFieldDisabled('monitoringCreditsLink')}
        />
      </FormSection>

      <FormSection title="Verfügbare Verkehrsmittel">
        <TextFieldText
          label="Überschrift"
          isLoading={isLoading}
          name="monitoringCommuteTitle"
          disabled={isFieldDisabled('monitoringCommuteTitle')}
        />
        <TextFieldText
          label="Zweite Zeile"
          isLoading={isLoading}
          name="monitoringCommuteSubTitle"
          disabled={isFieldDisabled('monitoringCommuteSubTitle')}
        />
        <CheckboxesGroup<CommuteType, FormDataLocationMonitoring>
          label=""
          name="monitoringCommuteTypes"
          i18nKey="CommuteType"
          options={Object.values(CommuteType)}
          isLoading={isLoading}
          gridXs={6}
        />
      </FormSection>

      <FormSection title="Entfernung">
        <Box sx={{ marginLeft: 2 }}>
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationMonitoring, 'monitoringDistanceActive'>
              name="monitoringDistanceActive"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  label="aktiv"
                  value={field.name}
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                  control={<Checkbox />}
                />
              )}
            />
          </TextFieldLoading>
        </Box>
        {monitoringDistanceActive && (
          <>
            <TextFieldText
              label="Überschrift"
              isLoading={isLoading}
              name="monitoringDistanceTitle"
              disabled={isFieldDisabled('monitoringDistanceTitle')}
            />
            <TextFieldText
              label="Zweite Zeile"
              isLoading={isLoading}
              name="monitoringDistanceSubTitle"
              disabled={isFieldDisabled('monitoringDistanceSubTitle')}
            />
            <FormControl component="fieldset" sx={{ display: 'block', marginBottom: 2 }}>
              <FormHeading>Maximale Entfernung für Skala</FormHeading>
              <FormIndent>
                <Controller
                  name="monitoringScaleMax"
                  defaultValue={null}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel key="100" value={100} control={<Radio />} label="100 km" />
                      <FormControlLabel key="250" value={250} control={<Radio />} label="250 km" />
                      <FormControlLabel key="500" value={500} control={<Radio />} label="500 km" />
                      <FormControlLabel key="1000" value={1000} control={<Radio />} label="1000 km" />
                    </RadioGroup>
                  )}
                />
              </FormIndent>
            </FormControl>
          </>
        )}
      </FormSection>

      <FormSection title="Kommune">
        <Box sx={{ marginLeft: 2 }}>
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationMonitoring, 'monitoringCommuneActive'>
              name="monitoringCommuneActive"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  label="aktiv"
                  value={field.name}
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                  control={<Checkbox />}
                />
              )}
            />
          </TextFieldLoading>
        </Box>
        {monitoringCommuneActive && (
          <>
            <TextFieldText
              label="Überschrift"
              isLoading={isLoading}
              name="monitoringCommuneTitle"
              disabled={isFieldDisabled('monitoringCommuneTitle')}
            />
            <TextFieldText
              label="Zweite Zeile"
              isLoading={isLoading}
              name="monitoringCommuneSubTitle"
              disabled={isFieldDisabled('monitoringCommuneSubTitle')}
            />

            <Typography sx={{ marginLeft: 2 }}>
              {`Auswahl: ${Object.values(FranconianSwitzerlandCommuneType)
                .map((v) => t(`FranconianSwitzerlandCommuneType.${v}`))
                .join(', ')}`}
            </Typography>
          </>
        )}
      </FormSection>

      <FormSection title="Bewertung">
        <Box sx={{ marginLeft: 2 }}>
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationMonitoring, 'monitoringRatingActive'>
              name="monitoringRatingActive"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  label="aktiv"
                  value={field.name}
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                  control={<Checkbox />}
                />
              )}
            />
          </TextFieldLoading>
        </Box>
        {monitoringRatingActive && (
          <>
            <TextFieldText
              label="Überschrift"
              isLoading={isLoading}
              name="monitoringRatingTitle"
              disabled={isFieldDisabled('monitoringRatingTitle')}
            />
            <TextFieldText
              label="Zweite Zeile"
              isLoading={isLoading}
              name="monitoringRatingSubTitle"
              disabled={isFieldDisabled('monitoringRatingSubTitle')}
            />

            <Typography sx={{ marginLeft: 2 }}>
              {`Auswahl: ${Object.values(RatingType)
                .map((v) => t(`RatingType.${v}`))
                .join(', ')}`}
            </Typography>
          </>
        )}
      </FormSection>

      {location && (
        <>
          <FormSection title="Monitoring Links">
            {[
              {
                link: createLink(location.monitoringLinkIdTablet),
                label: 'Tablet',
              },
              {
                link: createLink(location.monitoringLinkIdIntranet),
                label: 'Intranet',
              },
              {
                link: createLink(location.monitoringLinkIdSmartphone),
                label: 'Smartphone',
              },
            ].map((val) => (
              <TextField
                key={val.label}
                label={val.label}
                disabled={!monitoringActive}
                value={val.link}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <Box sx={{ marginTop: '23px', cursor: 'pointer' }}>
                      <Tooltip
                        title={tooltipLabel}
                        onClose={() => tooltipLabel === 'Link kopiert' && setTooltipLabel('Link kopieren')}
                      >
                        <span>
                          <CopyIcon
                            onClick={async () => {
                              await navigator.clipboard.writeText(val.link);
                              setTooltipLabel('Link kopiert');
                            }}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  ),
                  endAdornment: val.label === 'Smartphone' && (
                    <Tooltip title="QR Code herunterladen">
                      <Box sx={{ marginTop: '8px', cursor: 'pointer' }}>
                        <QRIcon onClick={downloadQRCode} />
                      </Box>
                    </Tooltip>
                  ),
                }}
              />
            ))}
          </FormSection>
          <QRCode id="QRCode" value={createLink(location.monitoringLinkIdSmartphone)} size={512} display="none" />
        </>
      )}
    </>
  );
};
