/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Location } from './Location';
import type { Subject } from './Subject';

export type Monitoring = {
    __caslSubjectType__: Subject;
    location: Location;
    commuteType: Monitoring.commuteType;
    distance: number | null;
    commune: Record<string, any> | null;
    rating: Record<string, any> | null;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Monitoring {

    export enum commuteType {
        HOME_OFFICE = 'HOME_OFFICE',
        WALK = 'WALK',
        BIKE = 'BIKE',
        BIKE_AND_RIDE = 'BIKE_AND_RIDE',
        PARK_AND_RIDE = 'PARK_AND_RIDE',
        PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
        CAR_POOL = 'CAR_POOL',
        CAR_MOTORCYCLE = 'CAR_MOTORCYCLE',
        CAR_ELECTRIC = 'CAR_ELECTRIC',
        LONG_DISTANCE_TRANSPORT = 'LONG_DISTANCE_TRANSPORT',
        SCOOTER = 'SCOOTER',
        MOPED = 'MOPED',
    }


}

