import { useTheme } from '@mui/material';

import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { Plot } from '@/monitoring/plots/Plot';

type ActiveInactiveProps = {
  data: MonitoringAnalysisData[];
  showSubtitle: boolean;
};

export const ActiveInactivePlot = ({ data, showSubtitle }: ActiveInactiveProps) => {
  const theme = useTheme();

  const plotData = [
    data.filter((obj) => obj.activeMobility).reduce((total, current) => total + current.count, 0),
    data.filter((obj) => !obj.activeMobility).reduce((total, current) => total + current.count, 0),
  ];

  return (
    <Plot
      data={[
        {
          values: plotData,
          labels: ['Aktiv', 'Inaktiv'],
          type: 'pie',
          hole: 0.4,
          texttemplate: '%{percent:.0%}<br>%{label}',
          marker: {
            colors: [CommuteUtils.SCOOTER.color, CommuteUtils.CAR_MOTORCYCLE.color],
            line: {
              color: theme.palette.tertiary.light,
              width: 3,
            },
          },
          hoverinfo: 'label+value',
        },
      ]}
      layout={{
        title: `Gegenüberstellung aktive/inaktive Mobilität${
          showSubtitle
            ? '<br><sub>Der aktive Anteil ergibt sich aus Verkehrsmittel zu Fuß, Fahrrad/Pedelec und B+R</sub>'
            : ''
        }`,
        showlegend: false,
        margin: { t: 120 },
      }}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
    />
  );
};
