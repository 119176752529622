import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { QueryKeys } from '@/services/QueryKeys';
import { Action, Location, LocationProfilesService, UpdateLocationProfileDto } from '@/services/api';
import { DataTransformer } from '@/services/DataTransformer';
import { useAutoSave } from '@/hooks/useAutoSave';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useGlobalStore } from '@/stores/useGlobalStore';

export const useLocationProfileForm = () => {
  const { locationId } = useParams();

  const ability = useAppAbility();

  const methods = useForm<UpdateLocationProfileDto>();
  const { setLastSaved } = useGlobalStore((state) => state.actions);

  useEffect(() => () => setLastSaved(undefined), [setLastSaved]);

  const queryClient = useQueryClient();
  const { data: locationProfile } = useQuery(
    QueryKeys.locationProfiles.id(locationId as string),
    () => LocationProfilesService.findOne({ locationId: locationId as string }),
    { enabled: !!locationId },
  );
  const { mutate } = useMutation(
    (formData: UpdateLocationProfileDto) =>
      LocationProfilesService.update({ locationId: locationId as string, requestBody: formData }),
    {
      onMutate: () => setLastSaved(undefined),
      onSuccess: () => {
        setLastSaved(dayjs());

        return queryClient.invalidateQueries(QueryKeys.locationProfiles.id(locationId as string));
      },
    },
  );

  const handleSubmit = methods.handleSubmit(
    (data) => {
      mutate(DataTransformer.toApi(data, ability.can(Action.UPDATE, locationProfile?.location as Location, 'profile')));
    },
    () => setLastSaved(undefined),
  );
  useAutoSave(handleSubmit, methods.watch);

  const isLoading = !locationProfile;

  const disabled = !locationProfile || ability.cannot(Action.UPDATE, locationProfile?.location as Location, 'profile');

  return { locationProfile, isLoading, disabled, handleSubmit, methods };
};
