import { InputHTMLAttributes } from 'react';
import { TextField } from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';

export type TextFieldTextProps<U extends FieldValues> = {
  label: string;
  type?: InputHTMLAttributes<unknown>['type'];
  isLoading?: boolean;
  name: FieldPath<U>;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
};

export const TextFieldText = <U extends FieldValues>({
  label,
  type = 'text',
  isLoading = false,
  name,
  required = false,
  disabled,
  multiline = false,
}: TextFieldTextProps<U>) => (
  <TextFieldLoading isLoading={isLoading}>
    <Controller<U>
      name={name}
      defaultValue={'' as any}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <TextField label={label} type={type} disabled={disabled} error={!!error} {...field} multiline={multiline} />
      )}
    />
  </TextFieldLoading>
);
