/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLocationDto } from '../models/CreateLocationDto';
import type { Location } from '../models/Location';
import type { LocationProfileStatus } from '../models/LocationProfileStatus';
import type { UpdateLocationDto } from '../models/UpdateLocationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationsService {

    /**
     * @returns Location
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateLocationDto,
    }): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Location
     * @throws ApiError
     */
    public static findAll({
        search,
        profileStatus,
    }: {
        search?: string,
        profileStatus?: Array<LocationProfileStatus>,
    }): CancelablePromise<Array<Location>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/locations',
            query: {
                'search': search,
                'profileStatus': profileStatus,
            },
        });
    }

    /**
     * @returns Location
     * @throws ApiError
     */
    public static findOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/locations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Location
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateLocationDto,
    }): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/locations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Location
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/locations/{id}',
            path: {
                'id': id,
            },
        });
    }

}
