/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FranconianSwitzerlandCommuneType {
    WAISCHENFELD = 'WAISCHENFELD',
    GROESSWEINSTEIN = 'GROESSWEINSTEIN',
    PRETZFELD = 'PRETZFELD',
    WIESENTTAL = 'WIESENTTAL',
    EBERMANNSTADT = 'EBERMANNSTADT',
    UNTERLEINLEITER = 'UNTERLEINLEITER',
    KUNREUTH = 'KUNREUTH',
    PINZBERG = 'PINZBERG',
    WIESENTHAU = 'WIESENTHAU',
    KIRCHEHRENBACH = 'KIRCHEHRENBACH',
    LEUTENBACH = 'LEUTENBACH',
    WEILERSBACH = 'WEILERSBACH',
    LK_FORCHHEIM = 'LK_FORCHHEIM',
    OTHER = 'OTHER',
    NOT_SPECIFIED = 'NOT_SPECIFIED',
}
