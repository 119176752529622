import { useState } from 'react';
import { TextField, Alert, Snackbar, InputAdornment, Button, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { ApiError, UpdateUserDto, User, UsersService } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { FormSection } from '@/components/form-elements/FormSection';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';

type FormData = Pick<UpdateUserDto, 'oldPassword' | 'newPassword'>;

export const ChangePasswordPage = () => {
  const user = useGlobalStore((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { mutate, error } = useMutation<User, ApiError, FormData>(
    (formData: FormData) => UsersService.update({ id: user?.id as string, requestBody: formData }),
    {
      onSuccess: () => {
        setSnackbarOpen(true);
        reset();
      },
    },
  );

  return (
    <FullHeightLayout navigations={1}>
      <Container>
        <form id="main-form" onSubmit={handleSubmit((data) => mutate(data))}>
          <FormSection title="Passwort ändern">
            <TextField
              label="Altes Passwort"
              type="password"
              fullWidth
              error={!!errors.oldPassword || !!error}
              helperText={error?.status === 401 && 'Ihr altes Passwort stimmt nicht überein'}
              {...register('oldPassword', { required: true })}
            />

            <TextField
              label="Neues Passwort"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              error={!!errors.newPassword}
              helperText="Mindestens 8 Zeichen"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                      {showPassword ? 'Verstecken' : 'Anzeigen'}
                    </Button>
                  </InputAdornment>
                ),
              }}
              {...register('newPassword', { required: true, minLength: 8 })}
            />
          </FormSection>

          <Button type="submit" variant="contained" size="large">
            Passwort ändern
          </Button>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Ihr Passwort wurde geändert
          </Alert>
        </Snackbar>
      </Container>
    </FullHeightLayout>
  );
};
