/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParkingFeePeriod {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}
