import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '@/components/form-elements/ConfirmDialog';
import { Network, NetworkCategory, NetworksService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';

type CreateNetworkDialogProps = {
  category: NetworkCategory;
  name: string;
  onClose: () => void;
  onCreate: (network: Network) => void;
};

export const CreateNetworkDialog = ({ category, name, onClose, onCreate }: CreateNetworkDialogProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(() => NetworksService.create({ requestBody: { category, name } }), {
    onSuccess: async (network) => {
      queryClient.setQueryData<Network[]>(QueryKeys.networks.all, (networks) =>
        [...(networks || []), network].sort(({ name: a }, { name: b }) => a.localeCompare(b)),
      );

      onCreate(network);
    },
  });

  return (
    <ConfirmDialog
      title={`${t(`NetworkCategory.${category}`)} "${name}" erstellen?`}
      open={!!name}
      onConfirm={mutate}
      onClose={onClose}
    />
  );
};
