/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Subject {
    USER = 'User',
    ORGANIZATION = 'Organization',
    ORGANIZATION_USER = 'OrganizationUser',
    LOCATION = 'Location',
    NETWORK = 'Network',
    ADMIN_LEVEL = 'AdminLevel',
    EMPLOYEE = 'Employee',
    INDICATOR_PROCESS = 'IndicatorProcess',
    INDICATOR_RELATION = 'IndicatorRelation',
    INDICATOR_ROUTING_CONFIGURATION = 'IndicatorRoutingConfiguration',
}
