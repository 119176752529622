/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNetworkDto } from '../models/CreateNetworkDto';
import type { Network } from '../models/Network';
import type { UpdateNetworkDto } from '../models/UpdateNetworkDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NetworksService {

    /**
     * @returns Network
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateNetworkDto,
    }): CancelablePromise<Network> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/networks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Network
     * @throws ApiError
     */
    public static findAll(): CancelablePromise<Array<Network>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/networks',
        });
    }

    /**
     * @returns Network
     * @throws ApiError
     */
    public static findOne({
        id,
        includeLocations,
    }: {
        id: string,
        includeLocations?: boolean,
    }): CancelablePromise<Network> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/networks/{id}',
            path: {
                'id': id,
            },
            query: {
                'includeLocations': includeLocations,
            },
        });
    }

    /**
     * @returns Network
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateNetworkDto,
    }): CancelablePromise<Network> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/networks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Network
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<Network> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/networks/{id}',
            path: {
                'id': id,
            },
        });
    }

}
