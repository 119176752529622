/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminLevel } from '../models/AdminLevel';
import type { AdminLevelType } from '../models/AdminLevelType';
import type { CreateAdminLevelDto } from '../models/CreateAdminLevelDto';
import type { UpdateAdminLevelDto } from '../models/UpdateAdminLevelDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminLevelsService {

    /**
     * @returns AdminLevel
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateAdminLevelDto,
    }): CancelablePromise<AdminLevel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin-levels',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AdminLevel
     * @throws ApiError
     */
    public static findAll({
        type,
        depth,
    }: {
        /**
         * Räumlicher Ebenentyp
         */
        type?: Array<AdminLevelType>,
        depth?: number,
    }): CancelablePromise<Array<AdminLevel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin-levels',
            query: {
                'type': type,
                'depth': depth,
            },
        });
    }

    /**
     * @returns AdminLevel
     * @throws ApiError
     */
    public static findOne({
        id,
        includeLocations,
    }: {
        id: string,
        includeLocations?: boolean,
    }): CancelablePromise<AdminLevel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin-levels/{id}',
            path: {
                'id': id,
            },
            query: {
                'includeLocations': includeLocations,
            },
        });
    }

    /**
     * @returns AdminLevel
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateAdminLevelDto,
    }): CancelablePromise<AdminLevel> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/admin-levels/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AdminLevel
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<AdminLevel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/admin-levels/{id}',
            path: {
                'id': id,
            },
        });
    }

}
