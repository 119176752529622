import { useParams } from 'react-router-dom';

import { User, UserRole } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { SecondaryNavigation } from '@/components/navigations/SecondaryNavigation';

export const LocationNavigation = () => {
  const { organizationId, locationId } = useParams();
  const user = useGlobalStore((state) => state.user);

  return (
    <SecondaryNavigation
      items={
        locationId && locationId !== 'erstellen'
          ? [
              {
                label: 'Anwendungsfall',
                to: `/organisation/${organizationId}/anwendungsfaelle/${locationId}`,
                end: true,
              },
              ...(UserRole.ORGANIZATION_USER !== (user as User).role
                ? [
                    {
                      label: 'Steckbrief',
                      to: `/organisation/${organizationId}/anwendungsfaelle/${locationId}/steckbrief`,
                    },
                  ]
                : []),
              ...([UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN].includes((user as User).role)
                ? [
                    {
                      label: 'Mobilitätsumfrage',
                      to: `/organisation/${organizationId}/anwendungsfaelle/${locationId}/monitoring-einstellung`,
                    },
                  ]
                : []),
            ]
          : []
      }
    />
  );
};
