import { Checkbox, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { AnalyseMonitoringDto } from '@/services/api';
import { ReactComponent as Dropdown } from '@/assets/icons/form/dropdown.svg';

type MonitoringFilterSelectProps<T extends { id: string; name: string }> = {
  name: keyof AnalyseMonitoringDto;
  defaultValue: string[] | undefined;
  options: Array<T>;
  label: string;
  isLoading: boolean;
};

export const MonitoringFilterSelect = <T extends { id: string; name: string }>({
  name,
  defaultValue,
  options,
  label,
  isLoading,
}: MonitoringFilterSelectProps<T>) => (
  <TextFieldLoading isLoading={isLoading}>
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          {...field}
          multiple
          variant="filled"
          input={<OutlinedInput label={label} />}
          displayEmpty
          renderValue={(selected) => `${selected.length} Kommune${selected.length !== 1 ? 'n' : ''} ausgewählt`}
          MenuProps={{
            PaperProps: {
              sx: {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'tertiary.light',
              },
            },
          }}
          IconComponent={Dropdown}
        >
          {options.map((commune) => (
            <MenuItem key={commune.id} value={commune.id} sx={{ height: 30 }}>
              <Checkbox checked={field.value.indexOf(commune.id) > -1} />
              {commune.name}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </TextFieldLoading>
);
