import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { MinimumMonitoringDto } from '@/services/api';

type CommunePlotProps = {
  data: MinimumMonitoringDto[];
  showSubtitle: boolean;
};

export const CommunePlot = ({ data, showSubtitle }: CommunePlotProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const plotData = Object.entries(
    data.reduce((communes, { commune }) => {
      if (commune === null) return communes;

      if (!communes[commune]) {
        communes[commune] = 1;
      } else {
        communes[commune] += 1;
      }

      return communes;
    }, {} as Record<string, number>),
  ).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Plot
      data={[
        {
          values: plotData.map(([, value]) => value),
          labels: plotData.map(([label]) => t(`FranconianSwitzerlandCommuneType.${label}`)),
          type: 'pie',
          hole: 0.7,
          sort: false,
          texttemplate: '%{percent:.0%}',
          marker: {
            line: {
              color: theme.palette.tertiary.light,
              width: 3,
            },
          },
          hoverinfo: 'label+value+percent',
        },
      ]}
      layout={{
        title: `Kommunen${showSubtitle ? '<br><sub>Regionale Verteilung der gewählten Ausgangspunkte</sub>' : ''}`,
        showlegend: true,
        margin: { t: 100, b: 20 },
      }}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
    />
  );
};
