import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { NetworkCategory, NetworksService } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';

type FormData = { name: string; category: NetworkCategory };

export const CreateNetworkPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const category = (location.state as { category: NetworkCategory })?.category || NetworkCategory.REGION;

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { category } });

  const { mutate } = useMutation((formData: FormData) => NetworksService.create({ requestBody: formData }), {
    onSuccess: (network) =>
      navigate(`/beratung/kategorien?tab=${Object.values(NetworkCategory).indexOf(network.category)}`),
  });

  return (
    <SidebarLayout>
      <form
        id="main-form"
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <FormSection title={`${t(`NetworkCategory.${category}`)} erstellen`}>
          <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
};
