import { FormProvider, useFieldArray } from 'react-hook-form';
import { Grid, InputAdornment, TextField } from '@mui/material';

import {
  ParkingFeeType,
  ParkingFeePeriod,
  ParkingLocation,
  ParkingPermitAllocationType,
  UpdateLocationProfileDto,
} from '@/services/api';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonsYesNo } from '@/components/form-elements/RadioButtonsYesNo';
import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { TextFieldSelect } from '@/components/form-elements/TextFieldSelect';
import { RadioButtonYesNoPartly } from '@/components/form-elements/RadioButtonYesNoPartly';
import { FieldArrayHeader } from '@/location/location-profile/fields/FieldArrayHeader';
import { FieldArrayRemove } from '@/location/location-profile/fields/FieldArrayRemove';
import { setValuesAsOptionalInt } from '@/utils/setValuesAsOptionalInt';
import { FieldArrayFooter } from '@/location/location-profile/fields/FieldArrayFooter';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';

export type FormDataParkingCars = Pick<UpdateLocationProfileDto, 'parkingCars' | 'parkingPermitAllocation'>;

type ParkingCarsProps = { print?: boolean };

export const ParkingCars = ({ print }: ParkingCarsProps) => {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();
  const {
    register,
    watch,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray<FormDataParkingCars>({
    name: 'parkingCars',
    control: methods.control,
  });

  useInitialFormValues<FormDataParkingCars>({
    entity: locationProfile,
    useFormReturn: methods,
    fields: ['parkingCars', 'parkingPermitAllocation'],
    once: true,
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Parken PKW" print={print} />

        <RadioButtonsEnum<ParkingPermitAllocationType, FormDataParkingCars>
          label="Nach welchen Kriterien werden die Parkberechtigungen den Beschäftigten zugeteilt?"
          name="parkingPermitAllocation.type"
          otherName="parkingPermitAllocation.other"
          i18nKey="ParkingPermitAllocationType"
          options={Object.values(ParkingPermitAllocationType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <FieldArrayHeader
          title="PKW Stellplatzanlagen"
          onClick={() => append({})}
          length={fields.length}
          print={print}
          disabled={disabled}
        />

        {fields.map((field, index) => {
          const parkingFeeType = watch(`parkingCars.${index}.parkingFeeType`);

          return (
            <FormSection key={field.id} title={`${index + 1}. PKW Stellplatzanlage`}>
              <TextFieldLoading isLoading={isLoading}>
                <TextField label="Bezeichnung" {...register(`parkingCars.${index}.name`)} disabled={disabled} />
              </TextFieldLoading>

              <RadioButtonsYesNo<FormDataParkingCars>
                label="Firmeneigener Stellplatz"
                name={`parkingCars.${index}.proprietary`}
                isLoading={isLoading}
                disabled={disabled}
              />

              <TextFieldLoading isLoading={isLoading}>
                <TextField
                  label="Anzahl Stellplätze"
                  type="number"
                  inputProps={{ min: 0 }}
                  disabled={disabled}
                  error={!!errors.parkingCars?.[index]?.size}
                  {...register(`parkingCars.${index}.size`, { min: 0, setValueAs: setValuesAsOptionalInt })}
                />
              </TextFieldLoading>

              <RadioButtonsEnum<ParkingLocation, FormDataParkingCars>
                label="Lage"
                name={`parkingCars.${index}.location`}
                i18nKey="ParkingLocation"
                options={Object.values(ParkingLocation)}
                isLoading={isLoading}
                disabled={disabled}
              />

              <RadioButtonYesNoPartly
                label="Nutzungsberechtigung erforderlich"
                name={`parkingCars.${index}.usageAuthorization`}
                isLoading={isLoading}
                disabled={disabled}
              />

              <Grid container columnSpacing={1} mb={1}>
                <Grid item xs={8}>
                  <TextFieldSelect<ParkingFeeType, FormDataParkingCars>
                    label="Gebührenpflicht"
                    name={`parkingCars.${index}.parkingFeeType`}
                    i18nKey="ParkingFeeType"
                    options={Object.values(ParkingFeeType)}
                    isLoading={isLoading}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextFieldLoading isLoading={isLoading}>
                    <TextField
                      label="Gebühr"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        inputProps: { min: 0, step: 0.01 },
                      }}
                      disabled={!parkingFeeType || parkingFeeType === ParkingFeeType.NO || disabled}
                      error={!!errors.parkingCars?.[index]?.parkingFeeAmount}
                      {...register(`parkingCars.${index}.parkingFeeAmount`, {
                        setValueAs: setValuesAsOptionalInt,
                        min: 0,
                      })}
                    />
                  </TextFieldLoading>
                </Grid>
                <Grid item xs={2}>
                  <TextFieldSelect<ParkingFeePeriod, FormDataParkingCars>
                    label="Zeitraum"
                    name={`parkingCars.${index}.parkingFeePeriod`}
                    i18nKey="ParkingFeePeriod"
                    options={Object.values(ParkingFeePeriod)}
                    isLoading={isLoading}
                    disabled={!parkingFeeType || parkingFeeType === ParkingFeeType.NO || disabled}
                  />
                </Grid>
              </Grid>

              <TextFieldLoading isLoading={isLoading}>
                <TextField
                  label="Entfernung vom Parkplatz zum (Betriebs-)Eingang"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    inputProps: { min: 0 },
                  }}
                  disabled={disabled}
                  error={!!errors.parkingCars?.[index]?.distance}
                  {...register(`parkingCars.${index}.distance`, { min: 0, setValueAs: setValuesAsOptionalInt })}
                />
              </TextFieldLoading>

              <FormSection title="Anzahl Stellplätze mit Ladepunkten für E-Fahrzeuge ">
                <Grid container spacing={1} className="form-section-item">
                  {(
                    [
                      { name: 'chargingPointsPoolCar', label: 'Poolwagen/Dienstfahrzeuge' },
                      { name: 'chargingPointsPrivate', label: 'Privatnutzung durch Beschäftigte' },
                    ] as const
                  ).map(({ name, label }) => (
                    <Grid key={name} item xs={6}>
                      <TextFieldLoading isLoading={isLoading}>
                        <TextField
                          label={label}
                          type="number"
                          inputProps={{ min: 0 }}
                          disabled={disabled}
                          error={!!errors.parkingCars?.[index]?.[name]}
                          {...register(`parkingCars.${index}.${name}`, { min: 0, setValueAs: setValuesAsOptionalInt })}
                        />
                      </TextFieldLoading>
                    </Grid>
                  ))}
                </Grid>
              </FormSection>

              <RadioButtonYesNoPartly
                label="Wird die jeweils benötigte Ladeleistung durch ein Lastmanagement optimiert?"
                name={`parkingCars.${index}.chargingPointLoadOptimization`}
                isLoading={isLoading}
                disabled={disabled}
              />
              <RadioButtonYesNoPartly
                label="Ist das Laden privater Pkw für die Beschäftigten kostenpflichtig?"
                name={`parkingCars.${index}.chargingPayable`}
                isLoading={isLoading}
                disabled={disabled}
              />

              <FieldArrayRemove onClick={() => remove(index)} print={print} disabled={disabled} />
            </FormSection>
          );
        })}

        <FieldArrayFooter onClick={() => append({})} length={fields.length} print={print} disabled={disabled} />
      </FormProvider>
    </form>
  );
};
