import { createContext } from 'react';
import { Ability } from '@casl/ability';

import { Action, IndicatorProcess, Location, LocationProfile, Organization, Subject, User } from '@/services/api';

export type SubjectEntity = Organization | Location | LocationProfile | User | IndicatorProcess;

export type AppAbilityTuple = [Action, Subject | SubjectEntity];

export const getAbilityContextDefault = () => new Ability<AppAbilityTuple>([], { anyAction: Action.MANAGE });

export const AbilityContext = createContext(getAbilityContextDefault());
