/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRole {
    TRAFFICON_ADMIN = 'TRAFFICON_ADMIN',
    IVM_ADMIN = 'IVM_ADMIN',
    IVM_USER = 'IVM_USER',
    ORGANIZATION_USER = 'ORGANIZATION_USER',
}
