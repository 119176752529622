import { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateIndicatorRelationDto, IndicatorRelation, IndicatorRelationStatus } from '@/services/api';
import { StatusChip } from '@/components/icons/StatusChip';
import { ReactComponent as Partial } from '@/assets/icons/status/partial.svg';
import { FilterButton } from '@/components/form-elements/FilterButton';
import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { getFilterCount } from '@/utils/getFilterCount';
import { AddressSplit } from '@/components/form-elements/AddressSplit';

type FormData = {
  filterGeocodingStatus: IndicatorRelationStatus[];
  filterIndicatorsStatus: IndicatorRelationStatus[];
};

type IndicatorRelationsTableProps = {
  relations?: (CreateIndicatorRelationDto | IndicatorRelation)[];
};

export const IndicatorRelationsTable = ({ relations = [] }: IndicatorRelationsTableProps) => {
  const { t } = useTranslation();
  const methods = useForm<FormData>({ defaultValues: { filterGeocodingStatus: [], filterIndicatorsStatus: [] } });
  const { watch } = methods;
  const [filterCount, setFilterCount] = useState(0);
  const [filter, setFilter] = useState<FormData>({ filterGeocodingStatus: [], filterIndicatorsStatus: [] });

  const handleSubmit = methods.handleSubmit((formData) => {
    setFilterCount(getFilterCount(formData, { filterGeocodingStatus: [], filterIndicatorsStatus: [] }));
    setFilter(formData);
  });

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmit();
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const filteredRelations = useMemo(
    () =>
      relations.filter(
        (relation) =>
          filterCount === 0 ||
          ('geocodingStatus' in relation &&
            'indicatorsStatus' in relation &&
            (filter.filterGeocodingStatus.includes(relation.geocodingStatus) ||
              filter.filterIndicatorsStatus.includes(relation.indicatorsStatus))),
      ),
    [filter, filterCount, relations],
  );

  return (
    <>
      <Box mb={2} display="flex">
        <FormProvider {...methods}>
          <FilterButton filterCount={filterCount}>
            <CheckboxesGroup
              label="Geocoding"
              name="filterGeocodingStatus"
              i18nKey="IndicatorRelationStatus"
              options={Object.values(IndicatorRelationStatus)}
              isLoading={false}
            />
            <CheckboxesGroup
              label="Berechnung"
              name="filterIndicatorsStatus"
              i18nKey="IndicatorRelationStatus"
              options={Object.values(IndicatorRelationStatus)}
              isLoading={false}
            />
          </FilterButton>
        </FormProvider>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell colSpan={2}>Start</TableCell>
              <TableCell colSpan={2}>Ziel</TableCell>
              <TableCell>Anwendungsfall</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRelations.map((item) => (
              <TableRow key={item.ivmId}>
                <TableCell>{item.ivmId}</TableCell>
                <TableCell>
                  <AddressSplit address={item.originAddress} />
                </TableCell>
                <TableCell align="right">
                  {'errors' in item && item.errors?.geocoding?.origin ? (
                    <StatusChip icon={<Partial />} label={t(`IndicatorError.${item.errors.geocoding.origin}`)} />
                  ) : (
                    <>
                      {item.originLatitude}
                      <br />
                      {item.originLongitude}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <AddressSplit address={item.destinationAddress} />
                </TableCell>
                <TableCell align="right">
                  {'errors' in item && item.errors?.geocoding?.destination ? (
                    <StatusChip icon={<Partial />} label={t(`IndicatorError.${item.errors.geocoding.destination}`)} />
                  ) : (
                    <>
                      {item.destinationLatitude}
                      <br />
                      {item.destinationLongitude}
                    </>
                  )}
                </TableCell>
                <TableCell align="right">{item.consultingCaseLocationId}</TableCell>
              </TableRow>
            ))}

            {filteredRelations.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  Keine Relationen gefunden
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
