/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CommuteType {
    HOME_OFFICE = 'HOME_OFFICE',
    WALK = 'WALK',
    BIKE = 'BIKE',
    BIKE_AND_RIDE = 'BIKE_AND_RIDE',
    PARK_AND_RIDE = 'PARK_AND_RIDE',
    PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
    CAR_POOL = 'CAR_POOL',
    CAR_MOTORCYCLE = 'CAR_MOTORCYCLE',
    CAR_ELECTRIC = 'CAR_ELECTRIC',
    LONG_DISTANCE_TRANSPORT = 'LONG_DISTANCE_TRANSPORT',
    SCOOTER = 'SCOOTER',
    MOPED = 'MOPED',
}
