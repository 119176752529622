import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { QueryKeys } from '@/services/QueryKeys';
import { Action, Network, NetworkCategory, NetworksService, Subject } from '@/services/api';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { CreateNetworkDialog } from '@/components/form-elements/CreateNetworkDialog';
import { useAppAbility } from '@/hooks/useAppAbility';

type Option = Network | { name: string; inputValue: string };

const filter = createFilterOptions<Option>();

type NetworkAutocompleteProps = {
  isLoading: boolean;
  disabled: boolean;
  category: NetworkCategory;
};

export const NetworkAutocomplete = ({ isLoading, disabled, category }: NetworkAutocompleteProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [createNetworkName, setCreateNetworkName] = useState('');
  const ability = useAppAbility();

  const { data: networks = [] } = useQuery(QueryKeys.networks.all, () => NetworksService.findAll());

  const options = useMemo(
    () => networks.filter(({ category: itemCategory }) => itemCategory === category),
    [category, networks],
  );

  return (
    <TextFieldLoading key={category} isLoading={isLoading}>
      <Controller
        name={`networks.${category}`}
        defaultValue={null}
        render={({ field }) => (
          <Autocomplete<Option>
            {...field}
            disabled={disabled}
            options={options}
            filterOptions={(filterOptions, params) => {
              const filtered = filter(options, params);

              if (ability.can(Action.CREATE, Subject.NETWORK)) {
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `${t(`NetworkCategory.${category}`)} "${inputValue}" erstellen`,
                  });
                }
              }

              return filtered;
            }}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) =>
              ('id' in option && value && 'id' in value && option.id === value.id) ||
              ('inputValue' in option && 'inputValue' in value)
            }
            onChange={(event, value) => {
              if (value && 'inputValue' in value) {
                setCreateNetworkName(value.inputValue);
              }

              field.onChange(value || null);
            }}
            renderInput={(params) => <TextField {...params} label={t(`NetworkCategory.${category}`)} />}
            renderOption={(props, network) => (
              <li {...props} key={'id' in network ? network.id : network.name}>
                {network.name}
              </li>
            )}
          />
        )}
      />

      <CreateNetworkDialog
        category={category}
        name={createNetworkName}
        onClose={() => {
          setCreateNetworkName('');
          setValue(`networks.${category}`, null);
        }}
        onCreate={(network) => {
          setCreateNetworkName('');
          setValue(`networks.${category}`, network);
        }}
      />
    </TextFieldLoading>
  );
};
