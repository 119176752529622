/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IndicatorRoutingConfiguration } from '../models/IndicatorRoutingConfiguration';
import type { IndicatorRoutingQueueStatusDto } from '../models/IndicatorRoutingQueueStatusDto';
import type { UpdateIndicatorRoutingConfigurationDto } from '../models/UpdateIndicatorRoutingConfigurationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IndicatorRoutingQueueService {

    /**
     * @returns IndicatorRoutingQueueStatusDto
     * @throws ApiError
     */
    public static status(): CancelablePromise<IndicatorRoutingQueueStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/indicator-routing-queue',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static resumeQueue(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/indicator-routing-queue',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static pauseQueue(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/indicator-routing-queue',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static scheduleCronJobs(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/indicator-routing-queue/cron-jobs',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static deleteCronJobs(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/indicator-routing-queue/cron-jobs',
        });
    }

    /**
     * @returns IndicatorRoutingConfiguration
     * @throws ApiError
     */
    public static getConfiguration(): CancelablePromise<IndicatorRoutingConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/indicator-routing-queue/configuration',
        });
    }

    /**
     * @returns IndicatorRoutingConfiguration
     * @throws ApiError
     */
    public static updateConfiguration({
        requestBody,
    }: {
        requestBody: UpdateIndicatorRoutingConfigurationDto,
    }): CancelablePromise<IndicatorRoutingConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/indicator-routing-queue/configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
