import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Action, IndicatorProcess, IndicatorProcessService } from '@/services/api';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { useAppAbility } from '@/hooks/useAppAbility';
import { CoreDataForm } from '@/indicator-process/CoreDataForm';
import { RelationsForm } from '@/indicator-process/RelationsForm';
import { useCurrentTab } from '@/hooks/useCurrentTab';

const navigationItems = ['Allgemeine Angaben', 'Relationen'];

export const IndicatorProcessPage = () => {
  const { indicatorProcessId } = useParams();
  const ability = useAppAbility();
  const { currentTab } = useCurrentTab();

  const { data: indicatorProcess } = useQuery(
    QueryKeys.indicatorProcesses.id(indicatorProcessId as string),
    () => IndicatorProcessService.findOne({ id: indicatorProcessId as string }),
    { enabled: !!indicatorProcessId },
  );

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems}
      buttonLabel={
        ability.can(Action.UPDATE, indicatorProcess as IndicatorProcess) && currentTab === 0 ? 'Speichern' : undefined
      }
    >
      {(tab: number) => (
        <>
          {tab === 0 && <CoreDataForm />}
          {tab === 1 && <RelationsForm />}
        </>
      )}
    </SidebarLayout>
  );
};
