import {
  Button,
  Checkbox,
  Container,
  css,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { Navigation } from '@/components/navigations/Navigation';
import { CreateInterestDto, InterestsService } from '@/services/api';

export const StyledFullHeightLayout = styled(FullHeightLayout)(
  ({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      background: linear-gradient(to left, ${theme.palette.tertiary.light} calc(50%), transparent 0);
    }
  `,
);

const StyledLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-weight: bold;
  `,
);

export const InterestPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateInterestDto & { privacy: boolean }>();

  const { mutate, isSuccess } = useMutation(
    (formData: CreateInterestDto) => InterestsService.create({ requestBody: formData }),
    {},
  );

  return (
    <>
      <Navigation />

      {isSuccess ? (
        <FullHeightLayout navigations={1}>
          <Container>
            <FormSection title="Vielen Dank" large>
              <Typography>Wir haben Ihre Daten weitergeleitet.</Typography>
            </FormSection>
          </Container>
        </FullHeightLayout>
      ) : (
        <StyledFullHeightLayout navigations={1}>
          <form
            id="main-form"
            onSubmit={handleSubmit(({ privacy, ...formData }) => {
              mutate(formData);
            })}
          >
            <Container>
              <Grid container columnSpacing={24} alignItems="center">
                <Grid item lg={6}>
                  <Typography variant="h1" gutterBottom>
                    Interesse am Beratungsprogramm
                  </Typography>
                  <FormSection title="" ml={false}>
                    <TextField
                      label="Organisationsname"
                      {...register('organizationName', { required: true })}
                      error={!!errors.organizationName}
                    />
                    <TextField
                      label="Anzahl der Beschäftigten in der Organisation"
                      type="number"
                      {...register('organizationSize', { required: true })}
                      error={!!errors.organizationSize}
                      inputProps={{ min: 0 }}
                      helperText={errors?.organizationSize?.message}
                    />
                    <TextField
                      label="Stadt / Ort Hauptanwendungsfall"
                      {...register('organizationPlace', { required: true })}
                      error={!!errors.organizationPlace}
                    />
                  </FormSection>
                  <FormSection title="Kontakt" ml={false}>
                    <TextField
                      label="Vorname"
                      {...register('contactFirstName', { required: true })}
                      error={!!errors.contactFirstName}
                    />
                    <TextField
                      label="Nachname"
                      {...register('contactLastName', { required: true })}
                      error={!!errors.contactLastName}
                    />
                    <TextField
                      label="E-Mail Adresse"
                      type="email"
                      {...register('contactEmail', { required: true })}
                      error={!!errors.contactEmail}
                      helperText={errors?.contactEmail?.message}
                    />
                    <TextField
                      label="Telefon Nr."
                      type="tel"
                      {...register('contactTel', {
                        required: true,
                        validate: (value) => {
                          try {
                            return parsePhoneNumber(value, 'DE').isValid();
                          } catch {
                            return false;
                          }
                        },
                      })}
                      error={!!errors.contactTel}
                      helperText={errors?.contactTel?.message}
                    />
                  </FormSection>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant="h2" gutterBottom>
                    Datenschutz
                  </Typography>
                  <Typography sx={{ marginBottom: 2 }}>
                    Im Rahmen des Beratungsprogramms &quot;Besser zur Arbeit&quot; wurde ein digitales Werkzeug für die
                    Unterstützung des Beratungsprozesses (kurz BMM-Beratungsplattform) erstellt. Mit dem Absenden Ihrer
                    Kontaktanfrage werden die von Ihnen eingegebenen Formulardaten im Webportal verarbeitet. Zur
                    Überwachung und Steuerung unserer Systeme werden Systemdaten in Protokolldateien (Serverlogs)
                    gespeichert. Wir verwenden ausschließlich technisch notwendige Cookies sowie Local Storage, z.B. um
                    den Login-Status zu speichern. Darüber hinaus werden keine Cookies eingesetzt. Wir verwenden keine
                    automatisierten Entscheidungsfindungen und führen kein Profiling nach Art. 22 DSGVO durch.
                    <br />
                    <br />
                    Der verantwortungsbewusste Umgang mit personenbezogenen Daten hat für uns hohe Priorität. Wir
                    möchten Sie darüber informieren, welche Daten erhoben werden und wie wir sie verwenden.
                    <br />
                    <br />
                    <StyledLink to="/datenschutz">Weiterlesen</StyledLink>
                  </Typography>

                  <FormControl error={!!errors.privacy} sx={{ display: 'block', marginTop: 0 }}>
                    <FormControlLabel
                      control={<Checkbox {...register('privacy', { required: true })} />}
                      label="Ich stimme der Verarbeitung der Daten zu."
                    />
                    {errors.privacy && <FormHelperText>Ihre Zustimmung ist erforderlich</FormHelperText>}
                  </FormControl>

                  <Button type="submit" variant="contained" size="large">
                    Kontaktanfrage senden
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        </StyledFullHeightLayout>
      )}
    </>
  );
};
