import { Container } from '@mui/material';

import { AcceptInvitation } from '@/authentication/accept-invitation/AcceptInvitation';
import { Navigation } from '@/components/navigations/Navigation';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';

export const AcceptInvitationPage = () => (
  <>
    <Navigation />

    <SidebarLayout navigations={1}>
      <Container>
        <AcceptInvitation />
      </Container>
    </SidebarLayout>
  </>
);
