import { FormControl, FormLabel, ToggleButton, ToggleButtonGroup, Typography, css, styled } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import { RatingType } from '@/services/api';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  css`
    display: flex;
    justify-content: center;
  `,
) as typeof ToggleButtonGroup;

const StyledToggleButton = styled(ToggleButton)(
  ({ theme }) => css`
    border-radius: 50%;
    background-color: white;

    &.Mui-selected {
      background-color: ${theme.palette.secondary.main};
    }
    &:hover {
      background-color: ${theme.palette.tertiary.dark};
    }
    &.Mui-selected:hover {
      background-color: ${theme.palette.secondary.main};
    }
  `,
) as typeof ToggleButton;

type ToggleButtonsRatingProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  subLabel: string;
  control: Control<T>;
};

export const ToggleButtonsRating = <T extends FieldValues>({
  name,
  label,
  subLabel,
  control,
}: ToggleButtonsRatingProps<T>) => {
  const { t } = useTranslation();

  const customIcons = [
    {
      rating: RatingType.VERY_BAD,
      icon: <SentimentVeryDissatisfiedIcon sx={{ fontSize: 50 }} />,
      label: t(`RatingType.${RatingType.VERY_BAD}`),
    },
    {
      rating: RatingType.POOR,
      icon: <SentimentDissatisfiedIcon sx={{ fontSize: 50 }} />,
      label: t(`RatingType.${RatingType.POOR}`),
    },
    {
      rating: RatingType.MEDIUM,
      icon: <SentimentSatisfiedIcon sx={{ fontSize: 50 }} />,
      label: t(`RatingType.${RatingType.MEDIUM}`),
    },
    {
      rating: RatingType.GOOD,
      icon: <SentimentSatisfiedAltIcon sx={{ fontSize: 50 }} />,
      label: t(`RatingType.${RatingType.GOOD}`),
    },
    {
      rating: RatingType.EXCELLENT,
      icon: <SentimentVerySatisfiedIcon sx={{ fontSize: 50 }} />,
      label: t(`RatingType.${RatingType.EXCELLENT}`),
    },
  ];

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          fontSize: 14,
          margin: 3,
          fontWeight: 'bold',
          textAlign: 'center',
          color: 'text.primary',
        }}
        focused={false}
      >
        {label}
      </FormLabel>
      {subLabel && (
        <Typography sx={{ fontSize: 14, textAlign: 'center', marginTop: '10px', marginBottom: '25px' }}>
          {subLabel}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledToggleButtonGroup
            {...field}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            exclusive
          >
            {customIcons.map((smile) => (
              <StyledToggleButton key={smile.rating} value={smile.rating} size="small">
                {smile.icon}
              </StyledToggleButton>
            ))}
          </StyledToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};
