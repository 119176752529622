/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalyseMonitoringDto } from '../models/AnalyseMonitoringDto';
import type { CreateMonitoringDto } from '../models/CreateMonitoringDto';
import type { MinimumMonitoringDto } from '../models/MinimumMonitoringDto';
import type { Monitoring } from '../models/Monitoring';
import type { MonitoringLocationDto } from '../models/MonitoringLocationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MonitoringService {

    /**
     * @returns MonitoringLocationDto
     * @throws ApiError
     */
    public static findOne({
        monitoringId,
    }: {
        monitoringId: string,
    }): CancelablePromise<MonitoringLocationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/monitoring/{monitoringId}',
            path: {
                'monitoringId': monitoringId,
            },
        });
    }

    /**
     * @returns Monitoring
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateMonitoringDto,
    }): CancelablePromise<Monitoring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/monitoring',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Monitoring
     * @throws ApiError
     */
    public static createMobile({
        requestBody,
    }: {
        requestBody: CreateMonitoringDto,
    }): CancelablePromise<Monitoring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/monitoring/qr',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                429: `ThrottlerException: Too Many Requests.`,
            },
        });
    }

    /**
     * @returns MonitoringLocationDto
     * @throws ApiError
     */
    public static daily({
        monitoringId,
    }: {
        monitoringId: string,
    }): CancelablePromise<MonitoringLocationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/monitoring/daily/{monitoringId}',
            path: {
                'monitoringId': monitoringId,
            },
        });
    }

    /**
     * @returns MinimumMonitoringDto
     * @throws ApiError
     */
    public static analysis({
        requestBody,
    }: {
        requestBody: AnalyseMonitoringDto,
    }): CancelablePromise<Array<MinimumMonitoringDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/monitoring/analysis',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
