/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ManagementInstrumentType {
    OEKO_AUDIT = 'OEKO_AUDIT',
    UMWELTMANAGEMENT_ISO14001 = 'UMWELTMANAGEMENT_ISO14001',
    OEKO_PROFIT = 'OEKO_PROFIT',
    UMWELTBERICHTERSTATTUNG = 'UMWELTBERICHTERSTATTUNG',
    NACHHALTIGKEITSBERICHTERSTATTUNG = 'NACHHALTIGKEITSBERICHTERSTATTUNG',
    UMWELTKOSTENRECHNUNG = 'UMWELTKOSTENRECHNUNG',
    VERWALTUNGSMODERNISIERUNG = 'VERWALTUNGSMODERNISIERUNG',
    QUALITAETSMANAGEMENT_ISO9001 = 'QUALITAETSMANAGEMENT_ISO9001',
    BETRIEBLICHE_GESUNDHEITSFOERDERUNG = 'BETRIEBLICHE_GESUNDHEITSFOERDERUNG',
    SICHERHEIT_ARBEITSSCHUTZMANAGEMENT = 'SICHERHEIT_ARBEITSSCHUTZMANAGEMENT',
    OTHER = 'OTHER',
}
