import { Box, Grid } from '@mui/material';

type CommuteBadgeTotalProps = {
  count: number | undefined;
  size: number;
};

export const CommuteBadgeTotal = ({ count, size }: CommuteBadgeTotalProps) => (
  <Grid item xs={size} key={0}>
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Box
        sx={{
          color: 'secondary.main',
          fontSize: 32,
          fontWeight: 'bold',
          marginTop: '-3px',
        }}
      >
        {count}
      </Box>
      <Box sx={{ paddingLeft: 1, fontSize: 14 }}>
        {`${count === 1 ? 'Person' : 'Personen'} haben heute an der Befragung teilgenommen`}
      </Box>
    </Box>
  </Grid>
);
