import { Button, css, FormControlLabel, Grid, styled, Switch } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import {
  AdminLevelsService,
  AdminLevelType,
  AnalyseMonitoringDto,
  FranconianSwitzerlandCommuneType,
  LocationsService,
  NetworksService,
  OrganizationsService,
} from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { MonitoringFilterAutocomplete } from '@/components/form-elements/MonitoringFilterAutocomplete';
import { MonitoringFilterSelect } from '@/components/form-elements/MonitoringFilterSelect';

export type AnalyseMonitoringData = Omit<AnalyseMonitoringDto, 'start' | 'end'> & {
  start: Dayjs;
  end: Dayjs;
};

const StyledSwitch = styled(Switch)(
  ({ theme }) => css`
    .MuiSwitch-thumb {
      color: ${theme.palette.secondary.main};
    }
    .Mui-checked .MuiSwitch-thumb {
      color: ${theme.palette.primary.main};
    }
  `,
);

type MonitoringFilterProps = {
  defaultValues?: AnalyseMonitoringData;
  onFilter: (formData: AnalyseMonitoringData) => void;
  autoRefresh: boolean;
  setAutoRefresh: (newValue: boolean) => void;
};

export const MonitoringFilter = ({ onFilter, defaultValues, autoRefresh, setAutoRefresh }: MonitoringFilterProps) => {
  const methods = useForm<AnalyseMonitoringData>({ defaultValues });
  const { t } = useTranslation();

  const { data: organizations = [] } = useQuery(
    QueryKeys.organizations.allFilters({ includeLocations: false }),
    () => OrganizationsService.findAll({ includeLocations: false }),
    { refetchOnWindowFocus: false },
  );

  const { data: locations = [] } = useQuery(QueryKeys.locations.all, () => LocationsService.findAll({}), {
    refetchOnWindowFocus: false,
  });

  const adminLevelfilter = {
    type: [AdminLevelType.MUNICIPALITY, AdminLevelType.CITY, AdminLevelType.MUNICIPALITY_FREE_AREA],
    depth: 0,
  };
  const { data: adminLevels = [] } = useQuery(
    QueryKeys.adminLevels.allFilter(adminLevelfilter),
    () => AdminLevelsService.findAll(adminLevelfilter),
    { refetchOnWindowFocus: false },
  );

  const { data: networks = [] } = useQuery(QueryKeys.networks.all, () => NetworksService.findAll(), {
    refetchOnWindowFocus: false,
  });

  const start = methods.watch('start');
  const end = methods.watch('end');

  return (
    <FormProvider {...methods}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="organizationId"
            options={organizations}
            label="Organisation"
            isLoading={!organizations}
            defaultValue={defaultValues?.organizationId}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="locationId"
            options={
              defaultValues?.organizationId !== null
                ? locations.filter((a) => a.organizationId === defaultValues?.organizationId)
                : locations
            }
            label="Anwendungsfall"
            isLoading={!locations}
            defaultValue={defaultValues?.locationId}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterSelect
            name="communes"
            options={Object.values(FranconianSwitzerlandCommuneType).map((commune) => ({
              id: commune,
              name: t(`FranconianSwitzerlandCommuneType.${commune}`),
            }))}
            label="Kommune"
            isLoading={false}
            defaultValue={defaultValues?.communes}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="adminLevelId"
            options={adminLevels}
            label="Räumliche Ebene"
            isLoading={!adminLevels}
            defaultValue={defaultValues?.adminLevelId}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="networkId"
            options={networks}
            label="Kategorie"
            isLoading={!networks}
            defaultValue={defaultValues?.networkId}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="start"
            defaultValue={defaultValues?.start}
            render={({ field: { ref, ...rest } }) => (
              <DatePicker label="Von" maxDate={end} format="DD.MM.YYYY" inputRef={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="end"
            defaultValue={defaultValues?.end}
            render={({ field: { ref, ...rest } }) => (
              <DatePicker label="Bis" minDate={start} format="DD.MM.YYYY" inputRef={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item xs={2} sx={{ boxSizing: 'border-box' }}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => onFilter(methods.getValues())}
            sx={{ boxSizing: 'border-box', width: '100%' }}
          >
            Filtern
          </Button>
        </Grid>
        <Grid item xs={2} sx={{ boxSizing: 'border-box' }}>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
              onFilter(methods.getValues());
            }}
            sx={{ boxSizing: 'border-box', width: '100%', height: '56px' }}
          >
            Zurücksetzen
          </Button>
        </Grid>
        <Grid item xs={4} sx={{ boxSizing: 'border-box' }}>
          <FormControlLabel
            label="Anzeige automatisch aktualisieren"
            sx={{ height: '56px' }}
            control={<StyledSwitch checked={autoRefresh} onChange={(event) => setAutoRefresh(event.target.checked)} />}
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
