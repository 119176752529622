import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { HoverActions } from '@/components/form-elements/HoverActions';
import { FormSection } from '@/components/form-elements/FormSection';
import { FillStatusIcon } from '@/components/icons/FillStatusIcon';
import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';
import { Action, Location, LocationsService, Organization } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { useAppAbility } from '@/hooks/useAppAbility';
import { LocationProfileStatusIcon } from '@/components/icons/LocationProfileStatusIcon';
import { MonitoringStatusIcon } from '@/components/icons/MonitoringStatusIcon';

type LocationTableProps = {
  locations?: Location[];
  createEnabled: boolean;
};

export const LocationTable = ({ locations = [], createEnabled }: LocationTableProps) => {
  const { organizationId } = useParams();
  const ability = useAppAbility();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (locationId: string) => LocationsService.update({ id: locationId, requestBody: { mainLocation: true } }),
    {
      onSuccess: (updatedLocation) => {
        queryClient.setQueryData<Organization>(
          QueryKeys.organizations.idIncludes(organizationId as string, {
            includeLocations: true,
            includeOrganizationUsers: true,
          }),
          (organization) => {
            organization?.locations?.map((location) => {
              // eslint-disable-next-line no-param-reassign
              location.mainLocation = updatedLocation.id === location.id;

              return location;
            });
            organization?.locations?.sort((a, b) => Number(b.mainLocation) - Number(a.mainLocation));

            return organization as Organization;
          },
        );
      },
    },
  );

  return (
    <FormSection title="Übersicht" ml={false}>
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table aria-label="Anwendungsfälle">
          <TableHead>
            <TableRow>
              <TableCell>Anwendungsfall</TableCell>
              <TableCell align="center" width={90}>
                Stammdaten
              </TableCell>
              <TableCell align="center" width={90}>
                Steckbrief
              </TableCell>
              <TableCell align="center" width={90}>
                Monitoring
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => {
              const { id, name, mainLocation, coreDataStatus, profileStatus, monitoringActive } = location;

              return (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <HoverActions
                      title={`${name}${mainLocation ? ' ☆' : ''}`}
                      actions={[
                        { label: 'Bearbeiten', onClick: `/organisation/${organizationId}/anwendungsfaelle/${id}` },
                        ...(ability.can(Action.UPDATE, location) && !location.mainLocation
                          ? [
                              {
                                label: 'Als Haupt-Anwendungsfall markieren',
                                onClick: () => mutate(id),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FillStatusIcon status={coreDataStatus} />
                  </TableCell>
                  <TableCell align="center">
                    <LocationProfileStatusIcon status={profileStatus} />
                  </TableCell>
                  <TableCell align="center">
                    <MonitoringStatusIcon status={monitoringActive} />
                  </TableCell>
                </TableRow>
              );
            })}

            {locations.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  Keine Anwendungsfälle erstellt
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {createEnabled && (
        <Button
          size="large"
          startIcon={<Plus />}
          fullWidth
          variant="contained"
          sx={{ justifyContent: 'flex-start' }}
          component={Link}
          to={`/organisation/${organizationId}/anwendungsfaelle/erstellen`}
        >
          Anwendungsfall erstellen
        </Button>
      )}
    </FormSection>
  );
};
