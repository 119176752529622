import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development';
const release =
  import.meta.env.VITE_SENTRY_RELEASE_VERSION && `moma-frontend@${import.meta.env.VITE_SENTRY_RELEASE_VERSION}`;

Sentry.init({
  dsn: 'https://e604a800bd884d5b896074d33925d53f@sentry.trafficon.eu/18',
  enabled: environment !== 'development',
  environment,
  ...(release && { release }),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
