import { useQuery } from '@tanstack/react-query';

import { AdminLevel, AdminLevelsService, Network, NetworksService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { Leaf } from '@/components/tree-map/Leaf';

type LocationLeafsProps = {
  id: string;
  type: 'AdminLevel' | 'Network';
  queryFn: typeof AdminLevelsService.findOne | typeof NetworksService.findOne;
};

export const LocationLeafs = ({ id, type, queryFn }: LocationLeafsProps) => {
  const filter = { includeLocations: true };
  const { data: { locations } = {} } = useQuery<AdminLevel | Network>(
    {
      AdminLevel: QueryKeys.adminLevels.idIncludes(id, filter),
      Network: QueryKeys.networks.idIncludes(id, filter),
    }[type],
    () => queryFn({ id, ...filter }),
  );

  return locations ? (
    <>
      {locations.map((location) => (
        <Leaf key={location.id} location={location} />
      ))}
    </>
  ) : null;
};
