import { useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { HoverActions } from '@/components/form-elements/HoverActions';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { LocationProfileStatus, LocationsService, NetworkCategory } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { FilterHeader } from '@/components/form-elements/FilterHeader';
import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { LocationProfileStatusIcon } from '@/components/icons/LocationProfileStatusIcon';
import { StatusChip } from '@/components/icons/StatusChip';

type FiltersFormData = { search: string; profileStatus: LocationProfileStatus[] };
const filtersDefaultValues: FiltersFormData = { search: '', profileStatus: [] };
const sortOrder = [LocationProfileStatus.SUBMITTED, LocationProfileStatus.EDITABLE, LocationProfileStatus.CONFIRMED];

export const LocationsPage = () => {
  const [filters, setFilters] = useState<FiltersFormData>(filtersDefaultValues);
  const { t } = useTranslation();

  const { data: locations = [], isLoading } = useQuery(
    QueryKeys.locations.allFilters(filters),
    async () => {
      const result = await LocationsService.findAll(filters);
      result.sort((a, b) => sortOrder.indexOf(a.profileStatus) - sortOrder.indexOf(b.profileStatus));

      return result;
    },
    {
      keepPreviousData: true,
    },
  );

  return (
    <FullHeightLayout>
      <Container>
        <FilterHeader<FiltersFormData>
          defaultValues={filtersDefaultValues}
          onChange={(formData) => setFilters(formData)}
        >
          <CheckboxesGroup
            label="Steckbrief Freigabe"
            name="profileStatus"
            i18nKey="LocationProfileStatus"
            options={Object.values(LocationProfileStatus)}
            isLoading={false}
          />
        </FilterHeader>

        <TableContainer>
          <Table aria-label="Anwendungsfälle">
            <TableHead>
              <TableRow>
                <TableCell>Anwwendungsfall</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Steckbrief Freigabe</TableCell>
                <TableCell>Stadt/Ort</TableCell>
                <TableCell>IHK-Bezirk</TableCell>
                <TableCell>Anzahl Beschäftigte</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map(({ id, name, organization, size, profileStatus, adminLevel, networks }) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <HoverActions
                      title={name}
                      actions={[
                        { label: 'Bearbeiten', onClick: `/organisation/${organization?.id}/anwendungsfaelle/${id}` },
                      ]}
                    />
                  </TableCell>
                  <TableCell>{organization?.name}</TableCell>
                  <TableCell>
                    <StatusChip
                      icon={<LocationProfileStatusIcon status={profileStatus} />}
                      label={t(`LocationProfileStatus.${profileStatus}`)}
                    />
                  </TableCell>
                  <TableCell>{adminLevel?.name}</TableCell>
                  <TableCell>
                    {networks?.find(({ category }) => category === NetworkCategory.IHK_DISTRICT)?.name}
                  </TableCell>
                  <TableCell align="right">{size}</TableCell>
                </TableRow>
              ))}

              {!isLoading && locations.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    Keine Anwendungsfälle gefunden
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </FullHeightLayout>
  );
};
