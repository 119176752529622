import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OrganizationUser, OrganizationUsersService, User } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { HoverActions } from '@/components/form-elements/HoverActions';
import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';
import { FormHeading } from '@/components/form-elements/FormHeading';

type OrganizationListProps = { organizationUsers: OrganizationUser[] };

export const OrganizationList = ({ organizationUsers }: OrganizationListProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: mutateRemove } = useMutation(
    ({ userId, organizationId }: { userId: string; organizationId: string }) =>
      OrganizationUsersService.remove({ userId, organizationId }),
    {
      onSuccess: async (organizationUser, { userId, organizationId }) => {
        await queryClient.invalidateQueries(QueryKeys.organizations.id(organizationId));
        await queryClient.setQueryData<User | undefined>(QueryKeys.users.id(userId), (userItem) => {
          if (userItem) {
            return {
              ...userItem,
              organizations: userItem?.organizations?.filter(
                ({ organizationId: itemOrganizationId }) => itemOrganizationId !== organizationId,
              ),
            };
          }

          return undefined;
        });
      },
    },
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item>
          <FormHeading>Zugeordnete Organisation</FormHeading>
        </Grid>
        <Grid item>
          <Button startIcon={<Plus />} variant="contained" component={Link} to="zuordnen">
            Zuordnen
          </Button>
        </Grid>
      </Grid>

      <TableContainer sx={{ marginBottom: 2 }}>
        <Table aria-label="Zugeordnete Organisation">
          <TableHead>
            <TableRow>
              <TableCell>Organisation</TableCell>
              <TableCell>Rolle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationUsers.map(({ userId, role, organizationId, organization: { name } = { name: '' } }) => (
              <TableRow key={organizationId}>
                <TableCell component="th" scope="row">
                  <HoverActions
                    title={name}
                    actions={[{ label: 'Entfernen', onClick: () => mutateRemove({ userId, organizationId }) }]}
                  />
                </TableCell>
                <TableCell>{t(`OrganizationUserRole.${role}`)}</TableCell>
              </TableRow>
            ))}
            {organizationUsers.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>Diese/r Benutzer/in ist keiner Organisation zugeordnet.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
