import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { ReactComponent as Complete } from '@/assets/icons/status/complete.svg';
import { ReactComponent as Partial } from '@/assets/icons/status/partial.svg';
import { ReactComponent as Empty } from '@/assets/icons/status/empty.svg';
import { FillStatus } from '@/services/api';

type FillStatusProps = { status: FillStatus };

export const FillStatusIcon = ({ status }: FillStatusProps) => {
  const { t } = useTranslation();

  return {
    [FillStatus.EMPTY]: (
      <Tooltip title={t(`FillStatus.${FillStatus.EMPTY}`)}>
        <span>
          <Empty />
        </span>
      </Tooltip>
    ),
    [FillStatus.PARTIAL]: (
      <Tooltip title={t(`FillStatus.${FillStatus.PARTIAL}`)}>
        <span>
          <Partial />
        </span>
      </Tooltip>
    ),
    [FillStatus.COMPLETE]: (
      <Tooltip title={t(`FillStatus.${FillStatus.COMPLETE}`)}>
        <span>
          <Complete />
        </span>
      </Tooltip>
    ),
  }[status];
};
