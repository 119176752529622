import { Fragment } from 'react';
import { css, Divider, FormControl, NativeSelect, Stack, styled } from '@mui/material';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { NavigationLink } from '@/components/navigations/NavigationLink';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { NavigationExternalLink } from '@/components/navigations/NavigationExternalLink';

const StyledSelect = styled(NativeSelect)(
  ({ theme }) =>
    css`
      margin-right: ${theme.spacing(-2)};

      &::before,
      &::after {
        content: none;
      }

      .MuiNativeSelect-select {
        padding-bottom: 0;
      }
    `,
);

type SecondaryNavigationProps = {
  organizationSelect?: boolean;
  items: ({ label: string } & (
    | { to: string; end?: boolean; href?: undefined }
    | { to?: undefined; end?: undefined; href: string }
  ))[];
};

export const SecondaryNavigation = ({ organizationSelect = true, items }: SecondaryNavigationProps) => {
  const { organizationId, locationId } = useParams();
  const navigate = useNavigate();
  const match = useMatch(`/organisation/${organizationId}/anwendungsfaelle/erstellen`);

  const { data: organization } = useQueryOrganization();

  return (
    <Stack
      direction="row"
      spacing={5}
      divider={
        <Divider
          sx={{ borderColor: 'text.primary', borderRightWidth: 2, height: 16, alignSelf: 'center' }}
          orientation="vertical"
          flexItem
        />
      }
    >
      {organizationSelect && (
        <Stack direction="row" spacing={5}>
          <FormControl>
            <StyledSelect
              size="small"
              value={`/organisation/${organizationId}${
                (locationId || match) && `/anwendungsfaelle/${locationId || 'erstellen'}`
              }`}
              aria-label="Organisation und Anwendungsfälle"
              onChange={(event) => navigate(event.currentTarget.value)}
            >
              <option value={`/organisation/${organizationId}`}>{organization?.name}</option>

              {((organization?.locations && organization.locations.length > 0) || match) && (
                <optgroup label="Anwendungsfälle">
                  {match && (
                    <option value={`/organisation/${organizationId}/anwendungsfaelle/erstellen`}>
                      Anwendungsfall erstellen
                    </option>
                  )}

                  {organization?.locations?.map(({ id: locationItemId, name: locationName }) => (
                    <option
                      key={locationItemId}
                      value={`/organisation/${organizationId}/anwendungsfaelle/${locationItemId}`}
                    >
                      {locationName}
                    </option>
                  ))}
                </optgroup>
              )}
            </StyledSelect>
          </FormControl>
        </Stack>
      )}
      {items.length > 0 && (
        <Stack direction="row" spacing={5}>
          {items.map(({ label, href, to, end = false }) => (
            <Fragment key={href || to}>
              {href && <NavigationExternalLink href={href}>{label}</NavigationExternalLink>}
              {to && (
                <NavigationLink to={to} end={end}>
                  {label}
                </NavigationLink>
              )}
            </Fragment>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
