import { CommuteType } from '@/services/api';
import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';
import { Plot } from '@/monitoring/plots/Plot';

type DistancePlotProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  includeWayBack: boolean;
  showSubtitle: boolean;
};

export const DistancePlot = ({ data, showIcons, includeWayBack, showSubtitle }: DistancePlotProps) => {
  const plotData = data.filter((obj) => obj.count !== 0 && obj.commuteType !== CommuteType.HOME_OFFICE);

  return (
    <Plot
      data={plotData.map((obj) => ({
        x: obj.data.map((val) => (includeWayBack ? (val.distance || 0) * 2 : val.distance)),
        type: 'box',
        name: obj.labelShort,
        marker: { color: obj.color, size: 2 },
        line: { width: 1 },
        boxpoints: 'all',
      }))}
      layout={{
        title: `Distanz pro Verkehrsmittel${
          showSubtitle ? '<br><sub>Verteilung der zurückgelegten Streckenlängen pro Verkehrsmittel</sub>' : ''
        }`,
        xaxis: {
          zeroline: false,
        },
        yaxis: { fixedrange: true, showticklabels: !showIcons },
        showlegend: false,
        margin: { b: 40, l: 120 },
        images: showIcons
          ? plotData.map((obj, index) => ({
              source: obj.icon,
              xref: 'paper',
              yref: 'paper',
              y: (index + 1 - 1 / plotData.length) / plotData.length,
              x: -0.02,
              sizex: 0.09,
              sizey: 0.09,
              layer: 'above',
              sizing: 'contain',
              xanchor: 'center',
              yanchor: 'top',
            }))
          : [],
      }}
      config={{
        modeBarButtonsToRemove: ['lasso2d', 'zoom2d', 'pan2d', 'select2d', 'resetScale2d'],
      }}
      style={{ width: '100%', height: '100%', minHeight: '600px' }}
    />
  );
};
