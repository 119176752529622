/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsultingStatus } from '../models/ConsultingStatus';
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { Organization } from '../models/Organization';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateOrganizationDto,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static findAll({
        search,
        adminLevelId,
        networkId,
        employeeRange,
        includeLocations,
        status,
    }: {
        search?: string,
        adminLevelId?: string,
        networkId?: string,
        employeeRange?: Array<number>,
        includeLocations?: boolean,
        status?: Array<ConsultingStatus>,
    }): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations',
            query: {
                'search': search,
                'adminLevelId': adminLevelId,
                'networkId': networkId,
                'employeeRange': employeeRange,
                'includeLocations': includeLocations,
                'status': status,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static findOne({
        id,
        includeLocations,
        includeOrganizationUsers,
        includeIndicatorProcesses,
    }: {
        id: string,
        includeLocations?: boolean,
        includeOrganizationUsers?: boolean,
        includeIndicatorProcesses?: boolean,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/{id}',
            path: {
                'id': id,
            },
            query: {
                'includeLocations': includeLocations,
                'includeOrganizationUsers': includeOrganizationUsers,
                'includeIndicatorProcesses': includeIndicatorProcesses,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateOrganizationDto,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }

}
