import dayjs from 'dayjs';

import Rating1 from '@/assets/icons/form/rating/rating-1.svg';
import Rating2 from '@/assets/icons/form/rating/rating-2.svg';
import Rating3 from '@/assets/icons/form/rating/rating-3.svg';
import Rating4 from '@/assets/icons/form/rating/rating-4.svg';
import Rating5 from '@/assets/icons/form/rating/rating-5.svg';
import { MinimumMonitoringDto, RatingType } from '@/services/api';
import { Plot } from '@/monitoring/plots/Plot';

type RatingPlotProps = {
  data: MinimumMonitoringDto[];
  showSubtitle: boolean;
};

const RATING = Object.values(RatingType);

export const RatingPlot = ({ data, showSubtitle }: RatingPlotProps) => {
  const datesWithRatings = data.reduce((dates, { createdAt, rating }) => {
    if (rating === null) return dates;
    const date = dayjs(createdAt).format('YYYY-MM-DD');

    if (dates[date]) {
      dates[date].push(RATING.indexOf(rating));
    } else {
      dates[date] = [RATING.indexOf(rating)];
    }

    return dates;
  }, {} as Record<string, number[]>);

  const plotData = Object.entries(datesWithRatings).reduce((dates, [date, ratings]) => {
    dates[date] = {
      average: ratings.reduce((a, b) => a + b + 1, 0) / ratings.length,
      count: ratings.length,
    };

    return dates;
  }, {} as Record<string, { average: number; count: number }>);

  return (
    <Plot
      data={[
        {
          x: Object.keys(plotData),
          y: Object.values(plotData).map(({ average }) => average),
          mode: 'markers',
          marker: {
            size: Object.values(plotData).map(({ count }) => count * 10),
            sizemode: 'area',
            sizemin: 4,
            sizemax: 96,
          },
          text: Object.values(plotData).map(({ count }) => `Stichprobengröße ${count}`),
          type: 'scatter',
        },
      ]}
      layout={{
        title: `Zufriedenheit mit Mobilitätsangebot${
          showSubtitle ? '<br><sub>Durchschnittswert pro Kalendertag</sub>' : ''
        }`,
        xaxis: {
          tickformat: '%_d\n%B',
          tickvals: Object.keys(plotData),
        },
        yaxis: {
          fixedrange: true,
          range: [0, 5],
          tickvals: [1, 2, 3, 4, 5],
          zeroline: false,
          showticklabels: false,
        },
        images: [Rating1, Rating2, Rating3, Rating4, Rating5].map((icon, index) => ({
          source: icon,
          xref: 'paper',
          yref: 'paper',
          y: (index + 1) / 5,
          sizex: 0.1,
          sizey: 0.1,
          sizing: 'contain',
          xanchor: 'right',
          yanchor: 'middle',
        })),
      }}
      config={{
        modeBarButtonsToRemove: ['lasso2d', 'zoom2d', 'pan2d', 'select2d', 'resetScale2d'],
      }}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
