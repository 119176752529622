/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationProfile } from '../models/LocationProfile';
import type { UpdateLocationProfileDto } from '../models/UpdateLocationProfileDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationProfilesService {

    /**
     * @returns LocationProfile
     * @throws ApiError
     */
    public static findOne({
        locationId,
    }: {
        locationId: string,
    }): CancelablePromise<LocationProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/location-profiles/{locationId}',
            path: {
                'locationId': locationId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static update({
        locationId,
        requestBody,
    }: {
        locationId: string,
        requestBody: UpdateLocationProfileDto,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/location-profiles/{locationId}',
            path: {
                'locationId': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
