import { Button, css, styled, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoginUserDto } from '@/services/api';
import { ReactComponent as CompanyIcon } from '@/assets/icons/public/trafficon-o.svg';

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)};
    background-color: ${theme.palette.common.white};
  `,
);

const StyledCompanyIcon = styled(CompanyIcon)(
  ({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing(5)};
  `,
);

type LoginFormProps = {
  error: string | null;
  onSubmit: (data: LoginUserDto) => void;
};

export const LoginForm = ({ error, onSubmit }: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUserDto & { privacy: boolean }>();

  return (
    <form id="login" onSubmit={handleSubmit(onSubmit)}>
      <StyledCompanyIcon fill="#00000" />
      <Typography variant="h1" sx={{ marginBottom: 5 }}>
        Anmeldung auf der Trafficon Mobilitätsplattform
      </Typography>

      <StyledTextField
        label="E-Mail"
        type="email"
        fullWidth
        error={!!errors.email || !!error}
        {...register('email', { required: true })}
      />

      <StyledTextField
        label="Passwort"
        type="password"
        fullWidth
        error={!!errors.password || !!error}
        helperText={error}
        {...register('password', { required: true })}
      />

      <Button type="submit" variant="contained" size="large" sx={{ marginTop: 4, marginBottom: 2 }}>
        Login
      </Button>
      <br />
      <Button sx={{ padding: 0, marginBottom: 2 }} component={Link} to="/passwort/vergessen">
        Passwort vergessen
      </Button>
      <br />
      <Typography sx={{ marginBottom: 1 }}>
        Die Trafficon Mobilitätsplattform wird für die Nutzung unterstützender Tools im Bereich Mobilitätsmanagement
        betrieben.
      </Typography>
    </form>
  );
};
