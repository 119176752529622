import { useParams } from 'react-router-dom';

import { useGlobalStore } from '@/stores/useGlobalStore';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { useAppAbility } from '@/hooks/useAppAbility';
import { Action, Subject, User, UserRole } from '@/services/api';
import { SecondaryNavigation } from '@/components/navigations/SecondaryNavigation';

export const OrganizationNavigation = () => {
  const { organizationId } = useParams();
  const ability = useAppAbility();
  const user = useGlobalStore((state) => state.user);

  const { data: organization } = useQueryOrganization();

  return (
    <SecondaryNavigation
      organizationSelect={!!organization}
      items={
        organization
          ? [
              { label: 'Übersicht', to: `/organisation/${organizationId}`, end: true },
              { label: 'Stammdaten', to: `/organisation/${organizationId}/stammdaten` },
              ...(organization?.fileStorage ? [{ label: 'Dokumente', href: organization.fileStorage }] : []),
              ...(ability.can(Action.MANAGE, Subject.ORGANIZATION_USER)
                ? [
                    {
                      label: 'Benutzer/innen',
                      to: `/organisation/${organizationId}/benutzerinnen`,
                    },
                  ]
                : []),
              ...(organization.processes &&
              organization?.processes.length > 0 &&
              UserRole.ORGANIZATION_USER !== (user as User).role
                ? [
                    {
                      label: 'Wohnstandortanalyse',
                      to: `/organisation/${organizationId}/wohnstandortanalyse`,
                    },
                  ]
                : []),
              { label: 'Umfrageergebnisse', to: `/organisation/${organizationId}/monitoring-analysis` },
            ]
          : [{ label: 'Organisation', to: '/organisation' }]
      }
    />
  );
};
