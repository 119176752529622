import { Stack } from '@mui/material';

import { NavigationLink } from '@/components/navigations/NavigationLink';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { User, UserRole } from '@/services/api';

export const ConsultingNavigation = () => {
  const user = useGlobalStore((state) => state.user);

  return (
    <Stack direction="row" spacing={5}>
      {[
        { label: 'Organisation', to: 'organisation' },
        ...([UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN].includes((user as User).role)
          ? [
              { label: 'Anwendungsfälle', to: 'anwendungsfaelle' },
              { label: 'Benutzer/innen', to: 'benutzerinnen' },
              { label: 'Räumliche Ebenen', to: 'raeumliche-ebenen' },
              { label: 'Kategorien', to: 'kategorien' },
            ]
          : []),
        { label: 'WSA Berechnung', to: 'wsa-berechnung' },
        { label: 'Umfrageergebnisse', to: 'monitoring-analysis' },
      ].map(({ label, to }) => (
        <NavigationLink key={to} to={to} end={false}>
          {label}
        </NavigationLink>
      ))}
    </Stack>
  );
};
