import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CommuteType } from '@/services/api/models/CommuteType';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';

type CommuteBadgeProps = {
  commuteType: CommuteType;
  count: number;
  size: number;
};

export const CommuteBadge = ({ commuteType, count, size }: CommuteBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={size} key={commuteType}>
      <Box sx={{ backgroundColor: 'white', borderRadius: '10px', padding: 2, height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            sx={{
              color: 'secondary.main',
              fontSize: 32,
              fontWeight: 'bold',
              paddingLeft: 2,
              paddingBottom: '5px',
            }}
          >
            {count}
          </Typography>
          <Box sx={{ paddingRight: 2 }}>
            <Box component="img" src={CommuteUtils[commuteType].icon} width={70} height={70} />
          </Box>
        </Box>

        <Typography sx={{ display: 'inline-block', marginTop: 2, fontSize: 14, color: 'text.primary' }}>
          {t(`CommuteBadgeLabel${count > 1 ? 'Plural' : 'Singular'}.${commuteType}`)}
        </Typography>
      </Box>
    </Grid>
  );
};
