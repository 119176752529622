import { Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { OrganizationTwoWheelersDto, TwoWheelersDto, UpdateLocationProfileDto } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { setValuesAsOptionalInt } from '@/utils/setValuesAsOptionalInt';

const twoWheelerTypes: { name: keyof OrganizationTwoWheelersDto; label: string }[] = [
  { name: 'bicycles', label: 'Fahrräder' },
  { name: 'cargoBikes', label: 'Lastenräder' },
  { name: 'scooters', label: 'Roller' },
];

const specifications: { name: keyof TwoWheelersDto; label: string }[] = [
  { name: 'total', label: 'Insgesamt' },
  { name: 'electric', label: 'Elektrisch' },
  { name: 'leasing', label: 'Leasingrad' },
];

type OrganizationCarsProps = { isLoading: boolean; disabled?: boolean };

export const OrganizationTwoWheelers = ({ isLoading, disabled }: OrganizationCarsProps) => {
  const { register, formState } = useFormContext<Pick<UpdateLocationProfileDto, 'organizationTwoWheelers'>>();

  return (
    <FormSection title="Firmeneigene Zweiräder">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              {specifications.map(({ label }) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {twoWheelerTypes.map(({ name: twoWheelerType, label: twoWheelerTypeLabel }) => (
              <TableRow key={twoWheelerType}>
                <TableCell>{twoWheelerTypeLabel}</TableCell>
                {specifications.map(({ name: specification, label: specificationLabel }) => (
                  <TableCell key={specification}>
                    {isLoading ? null : (
                      <Input
                        inputProps={{
                          min: 0,
                          'aria-label': `${twoWheelerTypeLabel} ${specificationLabel}`,
                          style: {
                            textAlign: 'right',
                          },
                        }}
                        type="number"
                        disabled={disabled}
                        error={!!formState.errors.organizationTwoWheelers}
                        {...register(`organizationTwoWheelers.${twoWheelerType}.${specification}`, {
                          setValueAs: setValuesAsOptionalInt,
                          min: 0,
                        })}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormSection>
  );
};
