import { Box, SxProps, Typography } from '@mui/material';

import carIcon from '@/assets/icons/commute/car.svg';
import co2Icon from '@/assets/icons/commute/co2.svg';
import ptIcon from '@/assets/icons/commute/pt.svg';
import arrows from '@/assets/icons/commute/arrows.svg';
import { CommuteType, MinimumMonitoringDto } from '@/services/api';
import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';

const infoBoxStyle: SxProps = {
  backgroundColor: '#ededed',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

const infoTextStyle: SxProps = {
  textAlign: 'center',
  fontSize: 20,
};

const imgStyle: SxProps = {
  width: 50,
  height: 50,
  margin: '0 5px',
};

type CarbonDioxidBadgeProps = {
  data: MonitoringAnalysisData[];
  monitoringData: MinimumMonitoringDto[];
  reduced: boolean;
};

export const CarbonDioxidBadge = ({ data, monitoringData, reduced }: CarbonDioxidBadgeProps) => {
  const days = [...new Set(monitoringData.map((obj) => obj.createdAt.split('T')[0]))].length;

  const totalCo2 =
    data
      .filter((obj) => obj.commuteType === CommuteType.CAR_MOTORCYCLE || obj.commuteType === CommuteType.CAR_ELECTRIC)
      .reduce((total, current) => total + (reduced ? current.reducedCarbonDioxide : current.carbonDioxide), 0) / 1000;

  const annualAmount = Number(((totalCo2 * 207) / days).toFixed(2));

  return (
    <Box sx={infoBoxStyle}>
      <Typography sx={{ textAlign: 'center', marginTop: 1 }}>{`Hochrechnung ${
        reduced ? 'bei Umstieg auf ÖV' : ''
      }`}</Typography>
      <Box sx={{ textAlign: 'center', height: 45 }}>
        <Box component="img" src={co2Icon} sx={imgStyle} />
        <Box component="img" src={carIcon} sx={imgStyle} />
        {reduced && <Box component="img" src={arrows} sx={{ ...imgStyle, width: 30 }} />}
        {reduced && <Box component="img" src={ptIcon} sx={imgStyle} />}
      </Box>
      <Typography sx={infoTextStyle}>
        <b>{annualAmount.toLocaleString('de')}</b> t CO2
      </Typography>
      <Typography sx={infoTextStyle} gutterBottom>
        <b>
          {(annualAmount * 80).toLocaleString('de-DE', {
            style: 'decimal',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </b>{' '}
        € p.a.
      </Typography>
    </Box>
  );
};
