import { Box } from '@mui/material';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteType } from '@/services/api';
import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';

type ReducedCarbonDioxidePlotProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  showSubtitle: boolean;
};

const reducedModes = [CommuteType.CAR_MOTORCYCLE, CommuteType.CAR_ELECTRIC, CommuteType.MOPED];

export const ReducedCarbonDioxidePlot = ({ data, showIcons, showSubtitle }: ReducedCarbonDioxidePlotProps) => {
  const plotData = data.filter((obj) => reducedModes.includes(obj.commuteType));

  return (
    <Box sx={{ position: 'relative' }}>
      <Plot
        data={[
          {
            x: plotData.map((val) => val.labelShort),
            y: plotData.map((val) => Number(val.carbonDioxide.toFixed(1))),
            type: 'bar',
            name: 'CO2 normal',
            orientation: 'v',
            marker: {
              color: plotData.map((obj) => obj.color),
            },
          },
          {
            x: plotData.map((val) => val.labelShort),
            y: plotData.map((val) => Number(val.reducedCarbonDioxide.toFixed(1))),
            type: 'bar',
            name: 'CO2 reduziert',
            orientation: 'v',
            marker: {
              color: plotData.map((obj) => obj.color.replace('1.0', '0.6')),
            },
          },
        ]}
        layout={{
          barmode: 'group',
          title: `CO<sub>2</sub>-Reduzierung${
            showSubtitle
              ? '<br><sub>Bei Umstieg auf ÖPNV ab 10 km</sub><br><sup>auf zu Fuß, Fahrrad/Pedelec unter 10 km</sup>'
              : ''
          }`,
          yaxis: { title: 'kg CO2', fixedrange: true },
          xaxis: { fixedrange: true, showticklabels: !showIcons },
          showlegend: false,
          images: showIcons
            ? plotData.map((obj, index) => ({
                source: obj.icon,
                xref: 'paper',
                yref: 'paper',
                x: (index + 0.5) / plotData.length,
                y: -0.02,
                sizex: 0.2,
                sizey: 0.2,
                layer: 'above',
                sizing: 'contain',
                xanchor: 'center',
                yanchor: 'top',
              }))
            : [],
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
};
