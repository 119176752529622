import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { NodeAccordion } from '@/components/tree-map/NodeAccordion';
import { AdminLevelsService, AdminLevelType } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { LocationLeafs } from '@/components/tree-map/LocationLeafs';

export const AdminLevelsTreePage = () => {
  const { t } = useTranslation();

  const filter = { type: [AdminLevelType.GOVERNMENT_DISTRICT], depth: 2 };
  const { data = [] } = useQuery(QueryKeys.adminLevels.allFilter(filter), () => AdminLevelsService.findAll(filter));

  return (
    <SidebarLayout type="tabs" navigationItems={data.map(({ name }) => name)}>
      {(tab: number) =>
        data[tab]?.children?.map(({ id, name, children, type }) => (
          <NodeAccordion key={id} title={name} type={t(`AdminLevelType.${type}`)} actions={[]}>
            {children?.map(({ id: municipalityId, name: municipalityName, type: municipalityType }) => (
              <NodeAccordion
                key={municipalityId}
                type={t(`AdminLevelType.${municipalityType}`)}
                title={municipalityName}
                actions={[]}
              >
                <LocationLeafs id={municipalityId} queryFn={AdminLevelsService.findOne} type="AdminLevel" />
              </NodeAccordion>
            ))}
          </NodeAccordion>
        ))
      }
    </SidebarLayout>
  );
};
