import { FormControl, FormLabel, ToggleButton, ToggleButtonGroup, Typography, css, styled } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FranconianSwitzerlandCommuneType } from '@/services/api';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  css`
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
  `,
) as typeof ToggleButtonGroup;

const StyledToggleButton = styled(ToggleButton)(
  ({ theme }) => css`
    text-transform: none;
    flex: 1 1 0;
    display: flex;
    white-space: nowrap;
    border: none;
    background-color: white;
    margin-right: 4px;
    margin-bottom: 4px;

    &.Mui-selected {
      background-color: ${theme.palette.secondary.main};
    }
    &:hover {
      background-color: ${theme.palette.tertiary.dark};
    }
    &.Mui-selected:hover {
      background-color: ${theme.palette.secondary.main};
    }
  `,
) as typeof ToggleButton;

type ToggleButtonsCommuneProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  subLabel: string;
  control: Control<T>;
};

export const ToggleButtonsCommune = <T extends FieldValues>({
  name,
  label,
  subLabel,
  control,
}: ToggleButtonsCommuneProps<T>) => {
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          fontSize: 14,
          margin: 3,
          fontWeight: 'bold',
          textAlign: 'center',
          color: 'text.primary',
        }}
        focused={false}
      >
        {label}
      </FormLabel>
      {subLabel && (
        <Typography sx={{ fontSize: 14, textAlign: 'center', marginTop: '10px', marginBottom: '25px' }}>
          {subLabel}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledToggleButtonGroup
            {...field}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            exclusive
          >
            {Object.values(FranconianSwitzerlandCommuneType)
              .filter((a) => a !== FranconianSwitzerlandCommuneType.NOT_SPECIFIED)
              .map((commune) => (
                <StyledToggleButton key={commune} value={commune}>
                  {t(`FranconianSwitzerlandCommuneType.${commune}`)}
                </StyledToggleButton>
              ))}
          </StyledToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};
