/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ArrivalCount {
    BETWEEN_0_AND_1 = 'BETWEEN_0_AND_1',
    BETWEEN_2_AND_5 = 'BETWEEN_2_AND_5',
    BETWEEN_6_AND_12 = 'BETWEEN_6_AND_12',
    BETWEEN_13_AND_24 = 'BETWEEN_13_AND_24',
    BETWEEN_25_AND_48 = 'BETWEEN_25_AND_48',
    MORE_THAN_48 = 'MORE_THAN_48',
}
