import { CommuteType, MinimumMonitoringDto } from '@/services/api';
import bike from '@/assets/icons/commute/bike.svg';
import walk from '@/assets/icons/commute/walk.svg';
import br from '@/assets/icons/commute/br.svg';
import car from '@/assets/icons/commute/car.svg';
import ecar from '@/assets/icons/commute/ecar.svg';
import carpool from '@/assets/icons/commute/carpool.svg';
import homeoffice from '@/assets/icons/commute/homeoffice.svg';
import pr from '@/assets/icons/commute/pr.svg';
import pt from '@/assets/icons/commute/pt.svg';
import moped from '@/assets/icons/commute/moped.svg';
import plane from '@/assets/icons/commute/flugverkehr.svg';
import scooter from '@/assets/icons/commute/tretroller.svg';

const CAR_CO2_PER_KM = 0.2;
const MOPED_CO2_PER_KM = 0.1;
const ECAR_CO2_PER_KM = 0.136;
const PT_CO2_PER_KM = 0.0675;
const LDT_CO2_PER_KM = 0.211;
const BR_BIKE_DISTANCE = 1.5;
const PR_CAR_DISTANCE = 5;

const LESS_CO2_BIKE_THRESHOLD = 10;
const LESS_CO2_PT_THRESHOLD = 10;

const SPEED_WALK = 5;
const SPEED_BIKE = 15;
const SPEED_SCOOTER = 10;

const getReducedCarbonDioxide = (data: MinimumMonitoringDto[]) => {
  let carbonDioxide = 0;
  data.forEach((record) => {
    if ((record.distance || 0) > LESS_CO2_PT_THRESHOLD) carbonDioxide += (record.distance || 0) * PT_CO2_PER_KM;
    else if ((record.distance || 0) <= LESS_CO2_BIKE_THRESHOLD) carbonDioxide += 0;
  });

  return carbonDioxide;
};

export const CommuteUtils = {
  [CommuteType.HOME_OFFICE]: {
    order: 0,
    color: 'rgba(127,127,127,1.0)',
    icon: homeoffice,
    getCarbonDioxide: () => 0,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: () => 0,
  },
  [CommuteType.WALK]: {
    order: 1,
    color: 'rgba(42,96,65,1.0)',
    icon: walk,
    getCarbonDioxide: () => 0,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: (distance: number) => (distance / SPEED_WALK) * 60,
  },
  [CommuteType.SCOOTER]: {
    order: 2,
    color: 'rgba(44,139,93,1.0)',
    icon: scooter,
    getCarbonDioxide: () => 0,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: (distance: number) => (distance / SPEED_SCOOTER) * 60,
  },
  [CommuteType.BIKE]: {
    order: 3,
    color: 'rgba(48,189,104,1.0)',
    icon: bike,
    getCarbonDioxide: () => 0,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: (distance: number) => (distance / SPEED_BIKE) * 60,
  },
  [CommuteType.BIKE_AND_RIDE]: {
    order: 4,
    color: 'rgba(72,195,133,1.0)',
    icon: br,
    getCarbonDioxide: (distance: number, count: number) =>
      Math.max(0, distance - count * BR_BIKE_DISTANCE) * PT_CO2_PER_KM,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: (distance: number, count: number) => ((count * 1.5) / SPEED_BIKE) * 60,
  },
  [CommuteType.PUBLIC_TRANSPORT]: {
    order: 5,
    color: 'rgba(119,192,211,1.0)',
    icon: pt,
    getCarbonDioxide: (distance: number) => distance * PT_CO2_PER_KM,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: () => 0,
  },
  [CommuteType.PARK_AND_RIDE]: {
    order: 6,
    color: 'rgba(251,215,112,1.0)',
    icon: pr,
    getCarbonDioxide: (distance: number, count: number) =>
      PR_CAR_DISTANCE * count * CAR_CO2_PER_KM + Math.max(0, distance - count * PR_CAR_DISTANCE) * PT_CO2_PER_KM,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: () => 0,
  },
  [CommuteType.CAR_ELECTRIC]: {
    order: 7,
    color: 'rgba(219,158,3,1.0)',
    icon: ecar,
    getCarbonDioxide: (distance: number) => distance * ECAR_CO2_PER_KM,
    getReducedCarbonDioxide,
    getMovementAmount: () => 0,
  },
  [CommuteType.CAR_POOL]: {
    order: 9,
    color: 'rgba(190,128,121,1.0)',
    icon: carpool,
    getCarbonDioxide: (distance: number) => distance * 0.5 * CAR_CO2_PER_KM,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: () => 0,
  },
  [CommuteType.MOPED]: {
    order: 8,
    color: 'rgba(219,124,2,1.0)',
    icon: moped,
    getCarbonDioxide: (distance: number) => distance * MOPED_CO2_PER_KM,
    getReducedCarbonDioxide,
    getMovementAmount: () => 0,
  },
  [CommuteType.CAR_MOTORCYCLE]: {
    order: 10,
    color: 'rgba(192,80,77,1.0)',
    icon: car,
    getCarbonDioxide: (distance: number) => distance * CAR_CO2_PER_KM,
    getReducedCarbonDioxide,
    getMovementAmount: () => 0,
  },
  [CommuteType.LONG_DISTANCE_TRANSPORT]: {
    order: 11,
    color: 'rgba(179, 25, 25, 1)',
    icon: plane,
    getCarbonDioxide: (distance: number) => distance * LDT_CO2_PER_KM,
    getReducedCarbonDioxide: () => 0,
    getMovementAmount: () => 0,
  },
};
