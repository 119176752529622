import { Box, Container, css, Grid, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LocationTable } from '@/organization/LocationTable';
import { FormSection } from '@/components/form-elements/FormSection';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { ConsultingStatusMutate } from '@/components/form-elements/ConsultingStatusMutate';
import { Action, Location, Subject } from '@/services/api';
import { useAppAbility } from '@/hooks/useAppAbility';
import { StatusChip } from '@/components/icons/StatusChip';
import { IndicatorProcessStatusIcon } from '@/components/icons/IndicatorProcessStatusIcon';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';

const StyledFullHeightLayout = styled(FullHeightLayout)(
  ({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      background: linear-gradient(to right, ${theme.palette.primary.light} calc(50% - 112px), transparent 0);
    }
  `,
);

export const OverviewPage = () => {
  const { t } = useTranslation();
  const ability = useAppAbility();

  const { data: organization } = useQueryOrganization();
  const isFieldDisabled = useIsFieldDisabled(organization);

  return (
    <StyledFullHeightLayout>
      <Container>
        <Grid container columnSpacing={24} alignItems="center">
          <Grid item lg={5}>
            <Typography variant="h1" gutterBottom>
              {organization?.name}
            </Typography>
          </Grid>
          <Grid item lg={7}>
            <FormSection title="Status" ml={false}>
              <Typography component="div" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                Der Status Ihres Beratungsprozesses ist
                <Box ml={2}>
                  {organization &&
                    (isFieldDisabled('status') ? (
                      <StatusChip label={t(`ConsultingStatus.${organization.status}`)} />
                    ) : (
                      <ConsultingStatusMutate organization={organization} />
                    ))}
                </Box>
              </Typography>
              <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                {organization?.processes && (organization?.processes?.length || 0) > 0 ? (
                  <>
                    Der Status der aktuellsten Wohnstandortanalyse ist
                    <Box ml={2}>
                      <StatusChip
                        icon={
                          ability.can(Action.READ_ALL, Subject.INDICATOR_PROCESS) ? (
                            <IndicatorProcessStatusIcon
                              status={organization.processes[organization.processes.length - 1].status}
                            />
                          ) : undefined
                        }
                        label={t(
                          `${
                            ability.can(Action.READ_ALL, Subject.INDICATOR_PROCESS)
                              ? 'IndicatorProcessStatus'
                              : 'IndicatorProcessStatusPublic'
                          }.${organization.processes[organization.processes.length - 1].status}`,
                        )}
                      />
                    </Box>
                  </>
                ) : (
                  <>Bisher wurde keine Wohnstandortanalyse erstellt.</>
                )}
              </Typography>
            </FormSection>
            <LocationTable
              locations={organization?.locations?.map((location) => ({ ...location, organization }))}
              createEnabled={ability.can(Action.CREATE, {
                __caslSubjectType__: Subject.LOCATION,
                organization,
              } as Location)}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledFullHeightLayout>
  );
};
