/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IndicatorType {
    REL = 'REL',
    BIKE = 'BIKE',
    WALK = 'WALK',
    MIV = 'MIV',
    PT = 'PT',
    PR = 'PR',
    BR = 'BR',
}
