/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BicycleIncentiveType {
    FINANCIAL = 'FINANCIAL',
    SERVICES = 'SERVICES',
    LEASING = 'LEASING',
    OTHER = 'OTHER',
}
