/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BusinessTripTransportationType {
    PRIVATE_CAR = 'PRIVATE_CAR',
    ORGANIZATION_CAR = 'ORGANIZATION_CAR',
    POOL_CAR = 'POOL_CAR',
    PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
    AIRCRAFT = 'AIRCRAFT',
    CAR_SHARING = 'CAR_SHARING',
    RENTAL_CAR = 'RENTAL_CAR',
    BICYCLE = 'BICYCLE',
    SERVICE_BICYCLE = 'SERVICE_BICYCLE',
    BICYCLE_RENT = 'BICYCLE_RENT',
    OTHER = 'OTHER',
}
