import { Typography } from '@mui/material';

import { ContentLayoutPage } from '@/components/layouts/ContentLayoutPage';

export const ContactPage = () => (
  <ContentLayoutPage>
    <Typography variant="h1">Kontakt</Typography>
    <Typography>
      <br />
      <b>Anfragen an die ivm GmbH zum Beratungsprogramm &quot;Besser zur Arbeit&quot;</b>
      <br /> bmm@ivm-rheinmain.de
      <br />
      <br />
      <b>Auftraggeber</b>
      <br /> ivm GmbH
      <br /> Frau Heike Mühlhans (Geschäftsführerin)
      <br /> Bessie-Coleman-Straße 7
      <br /> 60549 Frankfurt am Main
      <br /> Tel: +49 (0) 69 / 660 759-0
      <br /> Fax: +49 (0) 69 / 660 759-90
      <br />
      <br />
      <b>Technische Umsetzung</b>
      <br /> Trafficon - Traffic Consultants GmbH
      <br /> info@trafficon.eu
      <br /> www.trafficon.eu
      <br />
      <br />
    </Typography>
  </ContentLayoutPage>
);
