import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { UseMutateFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  IndicatorProcess,
  IndicatorProcessStatus,
  IndicatorRelationStatus,
  UpdateIndicatorProcessDto,
} from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { QueueStatus } from '@/indicator-process/relations-form/QueueStatus';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';

type RelationsCalculationProps = {
  indicatorProcess: IndicatorProcess;
  mutate: UseMutateFunction<IndicatorProcess, unknown, UpdateIndicatorProcessDto>;
};

export const RelationsCalculation = ({ indicatorProcess, mutate }: RelationsCalculationProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const isFieldDisabled = useIsFieldDisabled(indicatorProcess);

  const indicatorsDoneCount = (indicatorProcess.relations || []).filter((relation) =>
    'indicatorsStatus' in relation ? relation.indicatorsStatus === IndicatorRelationStatus.DONE : false,
  ).length;
  const indicatorsTotalCount =
    (indicatorProcess.relations || []).filter((relation) =>
      'indicatorsStatus' in relation ? relation.indicatorsStatus === IndicatorRelationStatus.TODO : false,
    ).length + indicatorsDoneCount;

  return [
    IndicatorProcessStatus.RELATIONS_GEOCODED,
    IndicatorProcessStatus.READY_FOR_CALCULATION,
    IndicatorProcessStatus.CALCULATION_DONE,
    IndicatorProcessStatus.INDICATORS_APPROVED,
  ].includes(indicatorProcess?.status as IndicatorProcessStatus) ? (
    <FormSection title="Berechnung">
      <Grid container alignItems="center" spacing={1}>
        {(['arrivalSpanFrom', 'arrivalSpanTo'] as const).map((field) => (
          <Grid item xs={6} key={field}>
            <Controller
              name={field}
              rules={{ required: true }}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <DateTimePicker
                  label={t(`IndicatorProcess.${field}`)}
                  ampm={false}
                  format="DD.MM.YYYY HH:mm"
                  disabled={
                    indicatorProcess?.status !== IndicatorProcessStatus.RELATIONS_GEOCODED || isFieldDisabled(field)
                  }
                  inputRef={ref}
                  slotProps={{ textField: { error: !!error || undefined } }}
                  {...rest}
                />
              )}
            />
          </Grid>
        ))}

        {(indicatorProcess?.status === IndicatorProcessStatus.RELATIONS_UPLOADED ||
          indicatorProcess?.status === IndicatorProcessStatus.RELATIONS_GEOCODED) && (
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={
                indicatorProcess?.status !== IndicatorProcessStatus.RELATIONS_GEOCODED || isFieldDisabled('status')
              }
              onClick={handleSubmit(async ({ arrivalSpanFrom, arrivalSpanTo }) =>
                mutate({
                  status: IndicatorProcessStatus.READY_FOR_CALCULATION,
                  arrivalSpanFrom:
                    typeof arrivalSpanFrom?.toISOString === 'function' ? arrivalSpanFrom.toISOString() : null,
                  arrivalSpanTo: typeof arrivalSpanTo?.toISOString === 'function' ? arrivalSpanTo.toISOString() : null,
                }),
              )}
            >
              Zur Berechnung freigeben
            </Button>
          </Grid>
        )}

        {indicatorProcess?.status === IndicatorProcessStatus.READY_FOR_CALCULATION && (
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => mutate({ status: IndicatorProcessStatus.RELATIONS_GEOCODED })}
              disabled={isFieldDisabled('status')}
            >
              Freigabe zur Berechnung widerrufen
            </Button>
          </Grid>
        )}

        <Grid item marginLeft="auto">
          <Typography fontWeight="bold">
            {indicatorsDoneCount}/{indicatorsTotalCount} berechnet
          </Typography>
        </Grid>
      </Grid>

      <LinearProgress
        sx={{ marginTop: 2 }}
        variant="determinate"
        value={(100 * indicatorsDoneCount) / indicatorsTotalCount}
      />

      {indicatorProcess.status === IndicatorProcessStatus.READY_FOR_CALCULATION && (
        <Box mt={2}>
          <QueueStatus />
        </Box>
      )}
    </FormSection>
  ) : null;
};
