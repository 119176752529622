import { forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Controller } from 'react-hook-form';

import { FormSection } from '@/components/form-elements/FormSection';
import { QueryKeys } from '@/services/QueryKeys';
import { AdminLevelsService, AdminLevelType, Location, UpdateLocationDto } from '@/services/api';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';

export type FormDataAdminLevelForm = Pick<UpdateLocationDto, 'adminLevelId'>;

type AdminLevelFormProps = {
  location?: Location;
  isLoading: boolean;
};

export const AdminLevelForm = forwardRef<HTMLElement, AdminLevelFormProps>(
  ({ location, isLoading }: AdminLevelFormProps, ref) => {
    const filter = {
      type: [AdminLevelType.MUNICIPALITY, AdminLevelType.CITY, AdminLevelType.MUNICIPALITY_FREE_AREA],
      depth: 0,
    };
    const { data = [] } = useQuery(QueryKeys.adminLevels.allFilter(filter), () => AdminLevelsService.findAll(filter));

    const isFieldDisabled = useIsFieldDisabled(location);

    return (
      <FormSection ref={ref} title="Räumliche Ebene">
        <TextFieldLoading isLoading={isLoading || !data}>
          <Controller
            name="adminLevelId"
            defaultValue={null as unknown as undefined}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={data}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return data.find(({ id }) => id === option)?.name || '';
                  }

                  return option.name;
                }}
                isOptionEqualToValue={(option, value) => option.id === value}
                onChange={(event, value) => {
                  field.onChange(value?.id || null);
                }}
                disabled={isFieldDisabled('adminLevelId')}
                renderInput={(params) => <TextField {...params} label="Räumliche Ebene" />}
              />
            )}
          />
        </TextFieldLoading>
      </FormSection>
    );
  },
);
