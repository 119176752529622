import { Button, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { UseMutateFunction } from '@tanstack/react-query';

import {
  CreateIndicatorRelationDto,
  IndicatorProcess,
  IndicatorProcessStatus,
  IndicatorRelation,
  IndicatorRelationStatus,
  UpdateIndicatorProcessDto,
} from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { useGlobalStore } from '@/stores/useGlobalStore';

type RelationsGeocodingProps = {
  indicatorProcess: IndicatorProcess;
  file?: File;
  fileRelations?: CreateIndicatorRelationDto[];
  onFileChange: (file: File | undefined) => void;
  onFileRelationsChange: (fileRelations: CreateIndicatorRelationDto[] | undefined) => void;
  mutate: UseMutateFunction<IndicatorProcess, unknown, UpdateIndicatorProcessDto>;
};

export const RelationsGeocoding = ({
  indicatorProcess,
  file,
  fileRelations,
  onFileChange,
  onFileRelationsChange,
  mutate,
}: RelationsGeocodingProps) => {
  const user = useGlobalStore((state) => state.user);
  const { handleSubmit } = useFormContext();

  const relations: (CreateIndicatorRelationDto | IndicatorRelation)[] =
    fileRelations || indicatorProcess?.relations || [];

  const geocodingFailedCount = relations.filter((relation) =>
    'geocodingStatus' in relation ? relation.geocodingStatus === IndicatorRelationStatus.ERROR : false,
  ).length;
  const geocodingCompletedCount = relations.filter((relation) =>
    'geocodingStatus' in relation
      ? relation.geocodingStatus !== IndicatorRelationStatus.TODO
      : relation.originLatitude &&
        relation.originLongitude &&
        relation.destinationLatitude &&
        relation.destinationLongitude,
  ).length;

  return fileRelations || indicatorProcess.status !== IndicatorProcessStatus.EMPTY ? (
    <FormSection title="Geocoding">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Controller
            name="dateOfRelations"
            rules={{ required: true }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <DatePicker
                label="Stand der Daten"
                format="DD.MM.YYYY"
                disabled={!file || !fileRelations}
                inputRef={ref}
                slotProps={{ textField: { error: !!error || undefined } }}
                {...rest}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Hochgeladen von" value={file ? user?.email : indicatorProcess?.uploader?.email} disabled />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={!file || !fileRelations}
            onClick={handleSubmit(async ({ dateOfRelations }) => {
              mutate(
                {
                  relations: fileRelations || [],
                  dateOfRelations:
                    typeof dateOfRelations?.format === 'function' ? dateOfRelations.format('YYYY-MM-DD') : null,
                },
                {
                  onSuccess: () => {
                    onFileChange(undefined);
                    onFileRelationsChange(undefined);
                  },
                },
              );
            })}
          >
            Hochladen und Geocoding durchführen
          </Button>
        </Grid>

        {relations.length > 0 && (
          <Grid item marginLeft="auto">
            <Typography fontWeight="bold">
              {geocodingCompletedCount}/{relations.length} geocodiert
              {geocodingFailedCount > 0 && <> ({geocodingFailedCount} fehlgeschlagen)</>}
            </Typography>
          </Grid>
        )}
      </Grid>

      {indicatorProcess?.relations && (
        <LinearProgress
          sx={{ marginTop: 2 }}
          variant="determinate"
          value={(100 * geocodingCompletedCount) / relations.length}
        />
      )}
    </FormSection>
  ) : null;
};
