import { FormControl, FormControlLabel, Radio, RadioGroup, Theme, Typography, useMediaQuery } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CommuteType } from '@/services/api';
import { RadioCommuteIcon } from '@/components/icons/RadioCommuteIcon';

type RadioButtonsCommuteProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  subLabel: string;
  control: Control<T>;
  commuteTypes: CommuteType[];
};

export const RadioButtonsCommute = <T extends FieldValues>({
  name,
  label,
  subLabel,
  control,
  commuteTypes,
}: RadioButtonsCommuteProps<T>) => {
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  let width = '120px';
  if (!matches) width = '100px';

  return (
    <FormControl component="fieldset">
      <Typography
        sx={{
          fontSize: 14,
          marginBottom: 3,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
      {subLabel && (
        <Typography sx={{ fontSize: 14, textAlign: 'center', marginTop: '10px', marginBottom: '25px' }}>
          {subLabel}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup row sx={{ justifyContent: 'space-evenly' }} value={value} onChange={onChange}>
            {commuteTypes.map((commuteType) => (
              <FormControlLabel
                sx={{
                  width,
                  textAlign: 'center',
                  display: 'inline-flex',
                }}
                key={commuteType}
                value={commuteType}
                labelPlacement="bottom"
                control={
                  <Radio
                    icon={<RadioCommuteIcon commuteType={commuteType} checked={false} />}
                    checkedIcon={<RadioCommuteIcon commuteType={commuteType} checked />}
                  />
                }
                label={<Typography sx={{ fontSize: 14 }}>{t(`CommuteType.${commuteType}`)}</Typography>}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
