import { Box } from '@mui/material';

import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';
import { Plot } from '@/monitoring/plots/Plot';

type CarbonDioxidePlotProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  showSubtitle: boolean;
};

export const CarbonDioxidePlot = ({ data, showIcons, showSubtitle }: CarbonDioxidePlotProps) => {
  const plotData = data.filter((obj) => obj.carbonDioxide !== 0);

  return (
    <Box sx={{ position: 'relative' }}>
      <Plot
        data={[
          {
            x: plotData.map((val) => val.labelShort),
            y: plotData.map((val) => Number(val.carbonDioxide.toFixed(1))),
            type: 'bar',
            text: plotData.map((val) => val.count.toString()),
            textposition: 'outside',
            hovertemplate: '',
            orientation: 'v',
            marker: {
              color: plotData.map((obj) => obj.color),
            },
          },
        ]}
        layout={{
          title: `CO<sub>2</sub>-basierend auf Personen${
            showSubtitle
              ? '<br><sub>Berechnung: zurückgelegte Strecken und CO<sub>2</sub>-Referenzwerte für Verkehrsmittel</sub>'
              : ''
          }`,
          yaxis: {
            fixedrange: true,
            title: 'kg CO2',
            range: [0, Math.max(...plotData.map((value) => Math.ceil(value.carbonDioxide))) + 1],
          },
          xaxis: { fixedrange: true, showticklabels: !showIcons },
          images: showIcons
            ? plotData.map((obj, index) => ({
                source: obj.icon,
                xref: 'paper',
                yref: 'paper',
                x: (index + 0.5) / plotData.length,
                y: -0.02,
                sizex: 0.15,
                sizey: 0.15,
                layer: 'above',
                sizing: 'contain',
                xanchor: 'center',
                yanchor: 'top',
              }))
            : [],
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
};
