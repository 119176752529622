import { Grid } from '@mui/material';

import { GenderDistributionDto } from '@/services/api';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';

type GenderDistributionProps = { isLoading: boolean; disabled?: boolean };

export const GenderDistribution = ({ isLoading, disabled }: GenderDistributionProps) => {
  const fields: { name: keyof GenderDistributionDto; label: string }[] = [
    { name: 'female', label: 'Weiblich' },
    { name: 'male', label: 'Männlich' },
    { name: 'nonBinary', label: 'Divers' },
  ];

  return (
    <FormSection title="Geschlechterverteilung der Beschäftigten (Prozent)">
      <Grid container spacing={1} className="form-section-item">
        {fields.map(({ name, label }) => (
          <Grid key={name} item xs={4}>
            <TextFieldNumber
              label={label}
              endAdornment="%"
              isLoading={isLoading}
              name={`genderDistribution.${name}`}
              rules={{ min: 0, max: 100 }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </FormSection>
  );
};
