/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RatingType {
    VERY_BAD = 'VERY_BAD',
    POOR = 'POOR',
    MEDIUM = 'MEDIUM',
    GOOD = 'GOOD',
    EXCELLENT = 'EXCELLENT',
}
