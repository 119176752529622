import { forwardRef, ReactNode } from 'react';
import { Box, css, styled } from '@mui/material';

import { FormHeading } from '@/components/form-elements/FormHeading';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    padding: 0;
    border: none;
    margin: 0 0 ${theme.spacing(4)} 0;
    position: relative;

    .MuiFormControl-root:not(:last-child),
    .MuiAutocomplete-root:not(:last-child),
    .form-section-item:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }

    &.focus-flash::before {
      animation: focus-flash 1s ease-out;
      content: '';
      z-index: -1;
      position: absolute;
      top: ${theme.spacing(-2)};
      right: ${theme.spacing(-2)};
      bottom: ${theme.spacing(-2)};
      left: ${theme.spacing(-2)};
    }

    @keyframes focus-flash {
      from {
        background-color: ${theme.palette.primary.light};
      }
      to {
        background-color: transparent;
      }
    }
  `,
) as typeof Box;

type FormSectionProps = {
  title: string;
  children?: ReactNode;
  large?: boolean;
  ml?: boolean;
  print?: boolean;
};

export const FormSection = forwardRef<HTMLElement, FormSectionProps>(
  ({ title, children, large, ml, print }: FormSectionProps, ref) => (
    <StyledBox ref={ref} component="fieldset">
      <FormHeading $large={large} $ml={ml} $mb $print={print}>
        {title}
      </FormHeading>
      {children}
    </StyledBox>
  ),
);
