import { Box, Slider, Theme, Typography, useMediaQuery } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

type SliderDistanceProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  subLabel: string;
  maxValue: number;
  control: Control<T>;
};

const scales = [
  {
    max: 100,
    step: 1,
    marks: [0, 20, 40, 60, 80, 100],
    marksMobile: [0, 25, 50, 75, 100],
  },
  {
    max: 250,
    step: 1,
    marks: [0, 25, 50, 75, 100, 150, 200, 250],
    marksMobile: [0, 50, 100, 150, 250],
  },
  {
    max: 500,
    step: 1,
    marks: [0, 50, 100, 150, 200, 300, 400, 500],
    marksMobile: [0, 125, 250, 375, 500],
  },
  {
    max: 1000,
    step: 1,
    marks: [0, 50, 100, 150, 200, 300, 400, 500, 750, 1000],
    marksMobile: [0, 250, 500, 750, 1000],
  },
];

export const SliderDistance = <T extends FieldValues>({
  name,
  label,
  subLabel,
  control,
  maxValue,
}: SliderDistanceProps<T>) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const scale = scales.find((val) => val.max === maxValue) || scales[0];

  const valueLabelFormat = (value: number) => {
    if (value === 0) return 'unter 1 km';
    if (value === scale.max) return `mehr als ${scale.max} km`;

    return `${value} km`;
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: 14,
          marginTop: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
      {subLabel && (
        <Typography sx={{ fontSize: 14, textAlign: 'center', marginTop: '10px', marginBottom: '25px' }}>
          {subLabel}
        </Typography>
      )}
      <Box sx={{ paddingTop: 2, paddingLeft: 5, paddingRight: 5 }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Slider
              size="medium"
              {...field}
              min={0}
              max={scale.max}
              step={scale.step}
              valueLabelDisplay="on"
              marks={(matches ? scale.marks : scale.marksMobile).map((val) => {
                if (val === 0) return { value: val, label: 'unter 1 km' };
                if (val === scale.max) return { value: val, label: `mehr als ${val} km` };

                return {
                  value: val,
                  label: `${val}`,
                };
              })}
              valueLabelFormat={valueLabelFormat}
            />
          )}
        />
      </Box>
    </>
  );
};
