import { forwardRef } from 'react';

import { FormSection } from '@/components/form-elements/FormSection';
import { ConsultingStatusRegister } from '@/components/form-elements/ConsultingStatusRegister';
import { Organization } from '@/services/api';

type ConsultingStatusFormProps = {
  organization?: Organization;
};

export const ConsultingStatusForm = forwardRef<HTMLElement, ConsultingStatusFormProps>(
  ({ organization }: ConsultingStatusFormProps, ref) => (
    <FormSection ref={ref} title="Beratungsprozess Status">
      <ConsultingStatusRegister organization={organization} />
    </FormSection>
  ),
);
