import { Button, Grid } from '@mui/material';

import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';

type FieldArrayFooterProps = {
  onClick: () => void;
  length: number;
  print?: boolean;
  disabled?: boolean;
};

export const FieldArrayFooter = ({ onClick, length, print, disabled }: FieldArrayFooterProps) =>
  length > 0 ? (
    <Grid container justifyContent="flex-end" alignItems="center" mb={2}>
      <Grid item>
        <Button
          sx={{ ...((print || disabled) && { display: 'none' }) }}
          startIcon={<Plus />}
          variant="contained"
          onClick={onClick}
        >
          Stellplatzanlage hinzufügen
        </Button>
      </Grid>
    </Grid>
  ) : null;
