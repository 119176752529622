/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateIndicatorProcessDto } from '../models/CreateIndicatorProcessDto';
import type { IndicatorProcess } from '../models/IndicatorProcess';
import type { IndicatorType } from '../models/IndicatorType';
import type { UpdateIndicatorProcessDto } from '../models/UpdateIndicatorProcessDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IndicatorProcessService {

    /**
     * @returns IndicatorProcess
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateIndicatorProcessDto,
    }): CancelablePromise<IndicatorProcess> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/indicator-processes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IndicatorProcess
     * @throws ApiError
     */
    public static findAll({
        search,
    }: {
        search?: string,
    }): CancelablePromise<Array<IndicatorProcess>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/indicator-processes',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @returns IndicatorProcess
     * @throws ApiError
     */
    public static findOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<IndicatorProcess> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/indicator-processes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns IndicatorProcess
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateIndicatorProcessDto,
    }): CancelablePromise<IndicatorProcess> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/indicator-processes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IndicatorProcess
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<IndicatorProcess> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/indicator-processes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static export({
        id,
        indicatorType,
    }: {
        id: string,
        indicatorType: IndicatorType,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/indicator-processes/{id}/export',
            path: {
                'id': id,
            },
            query: {
                'indicatorType': indicatorType,
            },
        });
    }

}
