import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ConsultingStatusChip } from '@/components/form-elements/ConsultingStatusChip';
import { ConsultingStatus, Organization, OrganizationsService } from '@/services/api';
import { QueryKeys } from '@/services/QueryKeys';

type ConsultingStatusMutateProps = {
  organization: Organization;
};

export const ConsultingStatusMutate = ({ organization }: ConsultingStatusMutateProps) => {
  const { id, status } = organization;

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (newStatus: ConsultingStatus) => OrganizationsService.update({ id, requestBody: { status: newStatus } }),
    {
      onSuccess: (updatedOrganization) => {
        queryClient.setQueryData<Organization>(
          QueryKeys.organizations.idIncludes(id, { includeLocations: true, includeOrganizationUsers: true }),
          (oldOrganization) => ({ ...oldOrganization, ...updatedOrganization }),
        );
        queryClient.setQueryData<Organization[]>(QueryKeys.organizations.all, (organizations = []) =>
          organizations.map((organizationItem) =>
            organizationItem.id === updatedOrganization.id ? updatedOrganization : organizationItem,
          ),
        );

        return queryClient.invalidateQueries(QueryKeys.organizations.allFilters());
      },
    },
  );

  return <ConsultingStatusChip organization={organization} value={status} onChange={mutate} />;
};
