/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationUserDto } from '../models/CreateOrganizationUserDto';
import type { OrganizationUser } from '../models/OrganizationUser';
import type { UpdateOrganizationUserDto } from '../models/UpdateOrganizationUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationUsersService {

    /**
     * @returns OrganizationUser
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateOrganizationUserDto,
    }): CancelablePromise<OrganizationUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization-users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OrganizationUser
     * @throws ApiError
     */
    public static findAll({
        organizationId,
        includeUsers,
        search,
    }: {
        organizationId?: string,
        includeUsers?: boolean,
        search?: string,
    }): CancelablePromise<Array<OrganizationUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organization-users',
            query: {
                'organizationId': organizationId,
                'includeUsers': includeUsers,
                'search': search,
            },
        });
    }

    /**
     * @returns OrganizationUser
     * @throws ApiError
     */
    public static update({
        organizationId,
        userId,
        requestBody,
    }: {
        organizationId: string,
        userId: string,
        requestBody: UpdateOrganizationUserDto,
    }): CancelablePromise<OrganizationUser> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organization-users/{organizationId}/{userId}',
            path: {
                'organizationId': organizationId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OrganizationUser
     * @throws ApiError
     */
    public static remove({
        organizationId,
        userId,
    }: {
        organizationId: string,
        userId: string,
    }): CancelablePromise<OrganizationUser> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/organization-users/{organizationId}/{userId}',
            path: {
                'organizationId': organizationId,
                'userId': userId,
            },
        });
    }

}
