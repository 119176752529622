/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Action {
    MANAGE = 'MANAGE',
    CREATE = 'CREATE',
    READ = 'READ',
    READ_ALL = 'READ_ALL',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}
