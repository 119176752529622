import { useEffect } from 'react';
import { Box, Button, CircularProgress, css, Grid, Link, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import shutterstock from '@/assets/images/shutterstock.jpg';
import ileLogo from '@/assets/images/ile-fsa-logo.png';
import landscape from '@/assets/images/landscape.jpg';
import tcGsBike from '@/assets/images/tc-gs-bike.png';
import { MonitoringLinkType, MonitoringService, MonitoringStyle } from '@/services/api';
import { CommuteBadge } from '@/components/icons/CommuteBadge';
import { CommuteBadgeTotal } from '@/components/icons/CommuteBadgeTotal';
import { ReactComponent as TrafficonLogo } from '@/assets/images/trafficon-logo.svg';
import { transientOptions } from '@/utils/transientOptions';

const MONITORING_STYLE = {
  [MonitoringStyle.TRAFFICON]: { bgImage: shutterstock, logo: undefined },
  [MonitoringStyle.RADGIPFEL]: { bgImage: tcGsBike, logo: undefined },
  [MonitoringStyle.ILE_FSA]: { bgImage: landscape, logo: ileLogo },
};

const BackgroundBox = styled(
  Box,
  transientOptions,
)<{ monitoringStyle: MonitoringStyle }>(
  ({ theme, monitoringStyle }) => css`
    min-height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.57), rgba(255, 255, 255, 0.57)),
      url(${MONITORING_STYLE[monitoringStyle].bgImage});
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
    }
  `,
);

const StyledLogo = styled(Box)(
  ({ theme }) => css`
    width: 150px;

    ${theme.breakpoints.up('md')} {
      position: absolute;
      top: 0;
      left: 0;
      // TODO: Broken CSS
      margin 10px;
    }
  `,
) as typeof Box;

const StyledContentBox = styled(Box)(
  ({ theme }) => css`
    min-width: 250px;
    max-width: 850px;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    text-align: center;
    position: absolute;
    max-height: 100vh;
    overflow: auto;

    ${theme.breakpoints.up('md')} {
      margin-top: 60px;
      border-radius: 10px;
      min-width: 650px;
      padding: 0 80px;
    }
  `,
) as typeof Box;

const delaySeconds = 10;

export const MonitoringDailyPage = () => {
  const navigate = useNavigate();
  const { monitoringId } = useParams();
  const navigateBack = () => navigate(`/monitoring/${monitoringId}`);

  const { data, isLoading } = useQuery(['daily'], () =>
    MonitoringService.daily({ monitoringId: monitoringId as string }),
  );

  const tablet = data?.linkType === MonitoringLinkType.TABLET;

  useEffect(() => {
    if (tablet) {
      const timer = setTimeout(() => {
        navigateBack();
      }, delaySeconds * 1000);

      return () => {
        clearTimeout(timer);
      };
    }

    return () => {};
  });

  return data && data.summary ? (
    <BackgroundBox monitoringStyle={data.monitoringStyle}>
      <StyledContentBox>
        {MONITORING_STYLE[data.monitoringStyle].logo !== undefined ? (
          <StyledLogo
            component="img"
            src={MONITORING_STYLE[data.monitoringStyle].logo}
            alt="ILE Fränkische Schweiz Logo"
          />
        ) : (
          ''
        )}

        {tablet && (
          <Button
            sx={{ position: 'absolute', top: 0, bottom: 100, left: 0, right: 0 }}
            onClick={() => navigateBack()}
          />
        )}
        <Box
          sx={{
            paddingTop: '45px',
            textAlign: 'center',
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 'bold', textTransform: 'uppercase' }}>
            {data.monitoringCredits}
          </Typography>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2} sx={{ textAlign: 'center', padding: 5 }} alignItems="stretch">
            <CommuteBadgeTotal
              count={data.summary.reduce((accumulator, value) => accumulator + value.count, 0)}
              size={12}
            />
            {data.summary.map((value) => (
              <CommuteBadge key={value.commuteType} commuteType={value.commuteType} count={value.count} size={6} />
            ))}
          </Grid>
        )}

        {tablet && (
          <>
            <Typography
              variant="button"
              display="block"
              textAlign="center"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: 14,
              }}
            >
              Berühren um sofort fortzufahren
            </Typography>
            <Typography
              variant="caption"
              display="block"
              textAlign="center"
              sx={{ color: 'text.secondary', fontSize: 12, marginBottom: 3 }}
            >
              Sie werden automatisch zur Startseite weitergeleitet.
            </Typography>
          </>
        )}

        {data.monitoringCreditsLink && (
          <Link href={data.monitoringCreditsLink} sx={{ marginBottom: 2 }}>
            {data.monitoringCreditsLink.indexOf('//') === -1
              ? data.monitoringCreditsLink
              : data.monitoringCreditsLink.slice(data.monitoringCreditsLink.indexOf('//') + 2)}
          </Link>
        )}
        <br />
        <br />

        <a href="https://www.trafficon.eu" target="_blank" rel="noreferrer">
          <TrafficonLogo width={100} />
        </a>
      </StyledContentBox>
    </BackgroundBox>
  ) : (
    <Typography>Error</Typography>
  );
};
