import { useTheme } from '@mui/material';

import { MonitoringAnalysisData } from '@/monitoring/utils/processMonitoringData';
import { Plot } from '@/monitoring/plots/Plot';

type ModalSplitProps = {
  data: MonitoringAnalysisData[];
  showSubtitle: boolean;
};

export const ModalSplitPlot = ({ data, showSubtitle }: ModalSplitProps) => {
  const plotData = data.filter((obj) => obj.count > 0);
  const theme = useTheme();

  return (
    <Plot
      data={[
        {
          values: plotData.map((obj) => obj.count),
          labels: plotData.map((obj) => obj.labelShort),
          type: 'pie',
          hole: 0.7,
          sort: false,
          texttemplate: '%{percent:.0%}',
          marker: {
            colors: plotData.map((obj) => obj.color),
            line: {
              color: theme.palette.tertiary.light,
              width: 3,
            },
          },
          hoverinfo: 'label+value+percent',
        },
      ]}
      layout={{
        title: `Verkehrsmittelnutzung${
          showSubtitle ? '<br><sub>Verkehrsmittelanteile auf Pendlerwegen (Anzahl zurückgelegte Wege)</sub>' : ''
        }`,
        showlegend: true,
        margin: { t: 120 },
      }}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
    />
  );
};
