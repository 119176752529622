/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConsultingStatus {
    CONTACT_REQUEST = 'CONTACT_REQUEST',
    LETTER_OF_INTENT = 'LETTER_OF_INTENT',
    CONSULTING = 'CONSULTING',
    MOBILITY_PLAN = 'MOBILITY_PLAN',
    AUDIT = 'AUDIT',
    RE_AUDIT = 'RE_AUDIT',
    COMPLETED = 'COMPLETED',
}
