/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SurveyImportance {
    VERY_IMPORTANT = 'VERY_IMPORTANT',
    FAIRLY_IMPORTANT = 'FAIRLY_IMPORTANT',
    LESS_IMPORTANT = 'LESS_IMPORTANT',
    NOT_IMPORTANT = 'NOT_IMPORTANT',
    NOT_SPECIFIED = 'NOT_SPECIFIED',
}
